import { format } from 'date-fns';
import _ from 'lodash';
import { Area, ComposedChart, ResponsiveContainer, Tooltip } from 'recharts';
import { calculateChartData } from './calculate-chart-data';
import { CustomTooltip } from './custom-tooltip';
import { DateRange, EventData } from '@/types';

type AreaChartProps = {
  data: EventData[];
  prevData?: EventData[];
  dateRange: DateRange;
  prevDateRange?: DateRange;
  primaryColor?: string;
  secondaryColor?: string;
};

export const AreaChart = ({
  data,
  prevData,
  dateRange,
  prevDateRange,
  primaryColor = '#057BFA' /* blue-primary */,
  secondaryColor = '#9ca3af' /* gray-400 */,
}: AreaChartProps) => {
  if (!data) {
    return null;
  }

  const mappedDates = calculateChartData(data, dateRange);

  const mappedPrevDates =
    prevData && prevDateRange
      ? calculateChartData(prevData, prevDateRange)
      : [];

  if (!mappedDates.length) {
    return null;
  }

  const zipped = _.zip(mappedDates, mappedPrevDates);

  const mapZipped = zipped.map((z) => {
    return {
      time: z[0]?.time,
      name: z[0]?.name,
      count: z[0]?.count,
      prevTime: z[1]?.time,
      prevName: z[1]?.name,
      prevCount: z[1]?.count,
    };
  });

  return (
    <div className="flex w-full flex-col">
      <ResponsiveContainer aspect={3.5} className="mt-5">
        <ComposedChart
          data={mapZipped}
          margin={{
            right: 0,
            left: 0,
          }}
        >
          <Area
            dataKey="prevCount"
            fill={secondaryColor}
            fillOpacity={0.2}
            opacity={0.4}
            stroke={secondaryColor}
            strokeWidth={1.5}
          />
          <Area
            dataKey="count"
            fill={primaryColor}
            fillOpacity={0.2}
            stroke={primaryColor}
            strokeWidth={1.5}
          />

          <Tooltip content={<CustomTooltip primaryColor={primaryColor} />} />
        </ComposedChart>
      </ResponsiveContainer>
      <div className="flex items-center justify-between p-2 text-gray-400">
        <span>{format(dateRange.startDate, 'MMM dd')}</span>
        <span>{format(dateRange.endDate, 'MMM dd')}</span>
      </div>
    </div>
  );
};
