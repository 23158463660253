import { LinkLogo } from '@/components/nav/link-logo';
import { useForm } from 'react-hook-form';
import { ProjectsService } from '@/api';
// import { logspot } from '@logspot/web'; TODO: add logspot project created event
import { useAccountDetails } from '@/hooks/use-account-details';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { createFileRoute, Navigate, useNavigate } from '@tanstack/react-router';

export const Route = createFileRoute('/_auth/_basic-layout/onboarding')({
  component: Onboarding,
});

type FormValues = {
  name: string;
};

function Onboarding() {
  const navigate = useNavigate();
  const { hasCreatedAnyProject, hasExceededPlan } = useAccountDetails();
  const {
    register,
    handleSubmit,
    // formState: { errors },
  } = useForm<FormValues>();
  const onSubmit = async ({ name }: FormValues) => {
    await ProjectsService.createProject({ name, silent: true });
    navigate({ to: '/start-tracking' });
  };

  if (hasExceededPlan) {
    return <Navigate to="/account" />;
  }

  if (hasCreatedAnyProject) {
    return <Navigate to="/" />;
  }

  return (
    <section className="py-12 sm:py-16 lg:py-20">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-sm">
          <div className="flex flex-col items-center">
            <LinkLogo />
            <h1 className="mt-12 text-3xl font-bold text-gray-900">
              Welcome Aboard!
            </h1>
            <p className="mt-4 font-medium text-gray-500">
              Let&rsquo;s create your first project.
            </p>
          </div>

          <form className="mt-10" onSubmit={handleSubmit(onSubmit)}>
            <div className="space-y-4">
              <div>
                <div className="mt-2">
                  <Input
                    type="text"
                    {...register('name', { required: true })}
                    id=""
                    placeholder="My First Project"
                  />
                </div>
              </div>

              <div>
                <Button
                  className="inline-flex w-full items-center justify-center "
                  type="submit"
                >
                  Create Project
                </Button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}
