import { MobileMenu } from './mobile-menu';
import { BreadcrumbSelector } from '@/components/nav/breadcrumb-selector';
import { TopbarUser } from '@/components/nav/topbar-user';

export const Topbar = ({ fullMenu }: { fullMenu: boolean }) => {
  return (
    <nav className="z-50 flex min-h-16 flex-col items-center justify-between">
      <div className="flex min-h-16 w-full items-center justify-between px-6">
        <div>
          <div className={fullMenu ? 'sm:hidden' : ''}>
            <MobileMenu fullMenu={fullMenu} />
          </div>
          {fullMenu && (
            <div className="hidden lg:mr-12 lg:block">
              <BreadcrumbSelector data-testid="breadcrumb-large" />
            </div>
          )}
        </div>
        <div className="flex items-center justify-end space-x-8">
          <div className="leading-normal">
            <TopbarUser fullMenu={fullMenu} />
          </div>
        </div>
      </div>
      {fullMenu && (
        <div className="flex min-h-16 w-full items-center border-t p-6 lg:hidden">
          <div>
            <BreadcrumbSelector data-testid="breadcrumb-small" />
          </div>
        </div>
      )}
    </nav>
  );
};
