import { useSuspenseQuery } from '@tanstack/react-query';
import { useFilters } from '@/hooks/use-filters';
import { FunnelsService } from '@/api';
import { useProjects } from '@/hooks/use-projects';
import { Widget } from './widget';
import { useChannels } from '@/hooks/use-channels';
import { FunnelWidget as FunnelWidgetProps, Step as StepProps } from '@/types';
import { useAtomValue } from 'jotai';
import { dateRangeAtom } from '@/state/atoms';
import { Funnel } from '@phosphor-icons/react';
import { Step } from '@/components/reports/step';

function getPercent(max: number, count: number, dropOff: number | null) {
  if (count > 0 && !dropOff) {
    return 1;
  }

  if (count > 0 && dropOff) {
    return count / max;
  }

  return 0;
}

export const FunnelWidget = ({
  internal,
  params,
  onDelete,
}: FunnelWidgetProps) => {
  const { channelId } = useChannels();
  const { projectId } = useProjects();
  if (!projectId) {
    return null;
  }
  const dateRange = useAtomValue(dateRangeAtom);
  const { filterGroups } = useFilters();
  const title = params?.title;
  const step1 = params?.step1;
  const step2 = params?.step2;
  const step3 = params?.step3;

  const { data: funnels } = useSuspenseQuery({
    queryKey: [
      'funnel',
      projectId,
      dateRange.startDate,
      dateRange.endDate,
      step1,
      step2,
      step3,
      JSON.stringify(filterGroups),
      channelId,
    ],
    queryFn: () =>
      FunnelsService.calculateFunnels({
        projectId,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        steps: [step1, step2, step3].filter((step): step is StepProps =>
          Boolean(step),
        ),
        filterGroups,
        channelId,
      }),
  });

  const max = funnels?.[0]?.count ?? 0;

  return (
    <Widget
      Header={() => (
        <span className="flex items-center gap-2 text-xl font-medium">
          <Funnel className="text-primary" />
          <span className="font-semibold">{title}</span>
        </span>
      )}
      onDelete={internal ? undefined : onDelete}
    >
      <div className="px-4 pb-4">
        <div className={`flex flex-1 items-center gap-3`}>
          {funnels?.map((step: StepProps) => (
            <span
              key={step.step}
              className="w-full flex-1 px-2 py-3 text-center font-bold"
            >
              {step.step}
            </span>
          ))}
        </div>
        <div className={`flex items-center gap-3`}>
          {funnels?.map((step: StepProps) => (
            <Step
              key={step.step}
              count={step.count}
              percent={getPercent(max, step.count, step.drop_off)}
            />
          ))}
        </div>
      </div>
    </Widget>
  );
};
