import axios, { AxiosError, AxiosResponse } from 'axios';

export const BASE_URL: string = import.meta.env.VITE_APP_API_URL;

export const axiosClient = axios.create({ baseURL: BASE_URL });

export const getLink = (link: string): string =>
  isDemo() ? `/demo${link}` : link;

export const isDemo = () => {
  return localStorage.getItem('ls-demo') === 'true';
};

export function optionalQuery(
  queryName: string,
  value?: string | number | null,
): string {
  return value ? `${queryName}=${value}` : '';
}

axiosClient.interceptors.request.use(
  async (config) => {
    if (isDemo()) {
      config.headers['x-logspot-demo'] =
        'skdlfnklj234@!#4213fdsafas2341325161236grdfsg';
      return config;
    }

    const token = localStorage.getItem('ls-token');
    if (token) {
      config.headers.Authorization = 'Bearer ' + token;
    }
    return config;
  },
  (error: AxiosError) => {
    if (isDemo()) {
      return;
    }
    return Promise.reject(error);
  },
);

axiosClient.interceptors.response.use(
  (resp: AxiosResponse) => resp,
  (error: AxiosError) => {
    if (isDemo()) {
      return;
    }
    if (error.response && error.response.status === 401) {
      localStorage.removeItem('ls-token');
      window.location.href = '/sign-in';
    }
    return Promise.reject(error);
  },
);
