import { endOfDay, sub } from 'date-fns';
import _ from 'lodash';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useProjects } from '@/hooks/use-projects';
import { AreaChart } from '@/components/charts/area-chart';
import { DailyActiveUsersService, EventsService } from '@/api';
import { EventData, EventDataMap, Project } from '@/types';
import { createFileRoute, Link, Navigate } from '@tanstack/react-router';
import { PageHeader } from '@/components/page-header';
import { Separator } from '@/components/ui/separator';
import { Label } from '@/components/ui/label';
import { Button } from '@/components/ui/button';

export const Route = createFileRoute('/_auth/_side-layout/projects')({
  component: Projects,
});

type ProjectBoxProps = {
  project: Project;
  setProjectId: (projectId: string) => void;
};

const now = new Date();

const dateRange = {
  interval: '1 days',
  startDate: sub(now, { days: 30 }),
  endDate: endOfDay(now),
};

// const ProjectBox = ({ project, now, setProject }) => {
const ProjectBox = ({ project, setProjectId }: ProjectBoxProps) => {
  const projectId = project.projectId;
  const { data: users } = useSuspenseQuery({
    queryKey: [`master-dau`, projectId, JSON.stringify(dateRange)],
    queryFn: () =>
      DailyActiveUsersService.getDau({
        projectId,
        interval: dateRange.interval,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
      }),
  });

  const { data: events } = useSuspenseQuery<EventDataMap>({
    queryKey: ['master-count', project.projectId, JSON.stringify(dateRange)],
    queryFn: () =>
      EventsService.getAllEventCounts({
        projectId,
        interval: dateRange.interval,
        startDate: dateRange.startDate.getTime(),
        endDate: dateRange.endDate.getTime(),
      }),
  });

  const summedEvents: EventData[] = Object.values(events)
    .flat()
    .reduce((acc: EventData[], event: EventData) => {
      const existingEventIndex = acc.findIndex((e) => e.date === event.date);
      if (existingEventIndex === -1) {
        acc.push({
          name: 'event',
          date: event.date,
          count: parseInt(event.count.toString()),
        });
      } else {
        acc[existingEventIndex].count =
          Number(acc[existingEventIndex].count) +
          parseInt(event.count.toString());
      }
      return acc;
    }, []);

  return (
    <div className="w-full ">
      <div className="rounded border">
        <div className="border-b border-border p-5">
          <Link to="/">
            <Button
              className="text-xl font-bold text-foreground"
              variant="link"
              onClick={() => {
                setProjectId(project.projectId);
              }}
            >
              {project.name}
            </Button>
          </Link>
        </div>
        <div className="p-5">
          <div className="flex flex-col gap-2">
            <Label>Users</Label>
            <AreaChart data={users?.perDay} dateRange={dateRange} />
            <Separator />
            <Label>Events</Label>
            <AreaChart data={summedEvents} dateRange={dateRange} />
          </div>
        </div>
      </div>
    </div>
  );
};

function Projects() {
  const { projects, setProjectId } = useProjects();

  if (!projects || projects.length === 0) {
    return <Navigate to="/onboarding" />;
  }

  const sorted = _.sortBy(projects, (p) => p.name);

  return (
    <div className="flex w-full flex-col gap-5">
      <PageHeader separator={false} title="Projects Dashboard" />
      <div className="grid w-full grid-cols-1 gap-5 sm:grid-cols-3 xl:grid-cols-4">
        {sorted.map((p) => (
          <ProjectBox
            key={p.projectId}
            project={p}
            setProjectId={setProjectId}
          />
        ))}
      </div>
    </div>
  );
}
