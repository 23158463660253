import { AxiosError } from 'axios';
import { axiosClient, isDemo } from './axios-client';
import { toast } from '@/components/ui/use-toast';
import { Auth, EmailPass } from '@/types';
import { logspot } from '@logspot/web';

export const AuthService: Auth = {
  isAuthenticated: () => {
    return !!(localStorage.getItem('ls-token') || isDemo());
  },

  oauth: async ({ projectId }: { projectId: string }) => {
    const {
      data: { data: oAuthToken },
    } = await axiosClient.post(`/oauth`, {
      projectId,
    });

    return oAuthToken;
  },

  loginSocial: async ({ accessToken }: { accessToken: string | null }) => {
    const {
      data: {
        data: { token, account },
      },
    } = await axiosClient.post(`/auth/social-login`, {
      token: accessToken,
    });

    localStorage.removeItem('ls-demo');
    localStorage.setItem('ls-token', token);

    return { token, account };
  },

  login: async ({
    email,
    password,
  }: EmailPass): Promise<{ token: string; accountId: string }> => {
    const {
      data: {
        data: { token, accountId },
      },
    } = await axiosClient.post(`/auth/login`, {
      email,
      password,
    });

    localStorage.removeItem('ls-demo');
    localStorage.setItem('ls-token', token);

    return { token, accountId };
  },

  logout: () => {
    localStorage.removeItem('ls-token');
    localStorage.removeItem('ls-demo');
    window.location.href = '/sign-in';
    logspot.reset();
  },

  register: async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }): Promise<{ token: string; accountId: string } | null> => {
    try {
      const {
        data: {
          data: { token, accountId },
        },
      } = await axiosClient.post(`/accounts`, {
        email,
        password,
      });

      localStorage.removeItem('ls-demo');
      localStorage.setItem('ls-token', token);

      return { token, accountId };
    } catch (err: unknown) {
      const axiosError = err as AxiosError;
      if (axiosError?.response?.status === 409) {
        toast({
          title: 'Account Already Exists',
          description: 'Try signing in instead.',
          variant: 'destructive',
        });
      } else {
        toast({
          title: 'Could Not Register',
          description: 'Please try again.',
          variant: 'destructive',
        });
      }
      return null;
    }
  },
};
