/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

// Import Routes

import { Route as rootRoute } from './routes/__root'
import { Route as SignUpImport } from './routes/sign-up'
import { Route as SignInImport } from './routes/sign-in'
import { Route as DemoImport } from './routes/demo'
import { Route as AuthImport } from './routes/_auth'
import { Route as SharesDashboardIdImport } from './routes/shares.$dashboardId'
import { Route as AuthCallbackImport } from './routes/auth.callback'
import { Route as AuthSideLayoutImport } from './routes/_auth/_side-layout'
import { Route as AuthBasicLayoutImport } from './routes/_auth/_basic-layout'
import { Route as AuthSideLayoutIndexImport } from './routes/_auth/_side-layout/index'
import { Route as AuthSideLayoutUsersImport } from './routes/_auth/_side-layout/users'
import { Route as AuthSideLayoutUpgradeImport } from './routes/_auth/_side-layout/upgrade'
import { Route as AuthSideLayoutSettingsImport } from './routes/_auth/_side-layout/settings'
import { Route as AuthSideLayoutSearchImport } from './routes/_auth/_side-layout/search'
import { Route as AuthSideLayoutReportsImport } from './routes/_auth/_side-layout/reports'
import { Route as AuthSideLayoutProjectsImport } from './routes/_auth/_side-layout/projects'
import { Route as AuthSideLayoutPlaygroundImport } from './routes/_auth/_side-layout/playground'
import { Route as AuthSideLayoutEventsImport } from './routes/_auth/_side-layout/events'
import { Route as AuthBasicLayoutStartTrackingImport } from './routes/_auth/_basic-layout/start-tracking'
import { Route as AuthBasicLayoutOnboardingImport } from './routes/_auth/_basic-layout/onboarding'
import { Route as AuthBasicLayoutAccountImport } from './routes/_auth/_basic-layout/account'
import { Route as AuthSideLayoutUsersUserIdImport } from './routes/_auth/_side-layout/users_.$userId'
import { Route as AuthSideLayoutReportsAddFunnelReportImport } from './routes/_auth/_side-layout/reports_.add-funnel-report'
import { Route as AuthSideLayoutProjectsDetailsImport } from './routes/_auth/_side-layout/projects_.details'
import { Route as AuthSideLayoutPaymentsSuccessImport } from './routes/_auth/_side-layout/payments.success'
import { Route as AuthSideLayoutPaymentsCancelImport } from './routes/_auth/_side-layout/payments.cancel'

// Create/Update Routes

const SignUpRoute = SignUpImport.update({
  id: '/sign-up',
  path: '/sign-up',
  getParentRoute: () => rootRoute,
} as any)

const SignInRoute = SignInImport.update({
  id: '/sign-in',
  path: '/sign-in',
  getParentRoute: () => rootRoute,
} as any)

const DemoRoute = DemoImport.update({
  id: '/demo',
  path: '/demo',
  getParentRoute: () => rootRoute,
} as any)

const AuthRoute = AuthImport.update({
  id: '/_auth',
  getParentRoute: () => rootRoute,
} as any)

const SharesDashboardIdRoute = SharesDashboardIdImport.update({
  id: '/shares/$dashboardId',
  path: '/shares/$dashboardId',
  getParentRoute: () => rootRoute,
} as any)

const AuthCallbackRoute = AuthCallbackImport.update({
  id: '/auth/callback',
  path: '/auth/callback',
  getParentRoute: () => rootRoute,
} as any)

const AuthSideLayoutRoute = AuthSideLayoutImport.update({
  id: '/_side-layout',
  getParentRoute: () => AuthRoute,
} as any)

const AuthBasicLayoutRoute = AuthBasicLayoutImport.update({
  id: '/_basic-layout',
  getParentRoute: () => AuthRoute,
} as any)

const AuthSideLayoutIndexRoute = AuthSideLayoutIndexImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => AuthSideLayoutRoute,
} as any)

const AuthSideLayoutUsersRoute = AuthSideLayoutUsersImport.update({
  id: '/users',
  path: '/users',
  getParentRoute: () => AuthSideLayoutRoute,
} as any)

const AuthSideLayoutUpgradeRoute = AuthSideLayoutUpgradeImport.update({
  id: '/upgrade',
  path: '/upgrade',
  getParentRoute: () => AuthSideLayoutRoute,
} as any)

const AuthSideLayoutSettingsRoute = AuthSideLayoutSettingsImport.update({
  id: '/settings',
  path: '/settings',
  getParentRoute: () => AuthSideLayoutRoute,
} as any)

const AuthSideLayoutSearchRoute = AuthSideLayoutSearchImport.update({
  id: '/search',
  path: '/search',
  getParentRoute: () => AuthSideLayoutRoute,
} as any)

const AuthSideLayoutReportsRoute = AuthSideLayoutReportsImport.update({
  id: '/reports',
  path: '/reports',
  getParentRoute: () => AuthSideLayoutRoute,
} as any)

const AuthSideLayoutProjectsRoute = AuthSideLayoutProjectsImport.update({
  id: '/projects',
  path: '/projects',
  getParentRoute: () => AuthSideLayoutRoute,
} as any)

const AuthSideLayoutPlaygroundRoute = AuthSideLayoutPlaygroundImport.update({
  id: '/playground',
  path: '/playground',
  getParentRoute: () => AuthSideLayoutRoute,
} as any)

const AuthSideLayoutEventsRoute = AuthSideLayoutEventsImport.update({
  id: '/events',
  path: '/events',
  getParentRoute: () => AuthSideLayoutRoute,
} as any)

const AuthBasicLayoutStartTrackingRoute =
  AuthBasicLayoutStartTrackingImport.update({
    id: '/start-tracking',
    path: '/start-tracking',
    getParentRoute: () => AuthBasicLayoutRoute,
  } as any)

const AuthBasicLayoutOnboardingRoute = AuthBasicLayoutOnboardingImport.update({
  id: '/onboarding',
  path: '/onboarding',
  getParentRoute: () => AuthBasicLayoutRoute,
} as any)

const AuthBasicLayoutAccountRoute = AuthBasicLayoutAccountImport.update({
  id: '/account',
  path: '/account',
  getParentRoute: () => AuthBasicLayoutRoute,
} as any)

const AuthSideLayoutUsersUserIdRoute = AuthSideLayoutUsersUserIdImport.update({
  id: '/users_/$userId',
  path: '/users/$userId',
  getParentRoute: () => AuthSideLayoutRoute,
} as any)

const AuthSideLayoutReportsAddFunnelReportRoute =
  AuthSideLayoutReportsAddFunnelReportImport.update({
    id: '/reports_/add-funnel-report',
    path: '/reports/add-funnel-report',
    getParentRoute: () => AuthSideLayoutRoute,
  } as any)

const AuthSideLayoutProjectsDetailsRoute =
  AuthSideLayoutProjectsDetailsImport.update({
    id: '/projects_/details',
    path: '/projects/details',
    getParentRoute: () => AuthSideLayoutRoute,
  } as any)

const AuthSideLayoutPaymentsSuccessRoute =
  AuthSideLayoutPaymentsSuccessImport.update({
    id: '/payments/success',
    path: '/payments/success',
    getParentRoute: () => AuthSideLayoutRoute,
  } as any)

const AuthSideLayoutPaymentsCancelRoute =
  AuthSideLayoutPaymentsCancelImport.update({
    id: '/payments/cancel',
    path: '/payments/cancel',
    getParentRoute: () => AuthSideLayoutRoute,
  } as any)

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_auth': {
      id: '/_auth'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthImport
      parentRoute: typeof rootRoute
    }
    '/demo': {
      id: '/demo'
      path: '/demo'
      fullPath: '/demo'
      preLoaderRoute: typeof DemoImport
      parentRoute: typeof rootRoute
    }
    '/sign-in': {
      id: '/sign-in'
      path: '/sign-in'
      fullPath: '/sign-in'
      preLoaderRoute: typeof SignInImport
      parentRoute: typeof rootRoute
    }
    '/sign-up': {
      id: '/sign-up'
      path: '/sign-up'
      fullPath: '/sign-up'
      preLoaderRoute: typeof SignUpImport
      parentRoute: typeof rootRoute
    }
    '/_auth/_basic-layout': {
      id: '/_auth/_basic-layout'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthBasicLayoutImport
      parentRoute: typeof AuthImport
    }
    '/_auth/_side-layout': {
      id: '/_auth/_side-layout'
      path: ''
      fullPath: ''
      preLoaderRoute: typeof AuthSideLayoutImport
      parentRoute: typeof AuthImport
    }
    '/auth/callback': {
      id: '/auth/callback'
      path: '/auth/callback'
      fullPath: '/auth/callback'
      preLoaderRoute: typeof AuthCallbackImport
      parentRoute: typeof rootRoute
    }
    '/shares/$dashboardId': {
      id: '/shares/$dashboardId'
      path: '/shares/$dashboardId'
      fullPath: '/shares/$dashboardId'
      preLoaderRoute: typeof SharesDashboardIdImport
      parentRoute: typeof rootRoute
    }
    '/_auth/_basic-layout/account': {
      id: '/_auth/_basic-layout/account'
      path: '/account'
      fullPath: '/account'
      preLoaderRoute: typeof AuthBasicLayoutAccountImport
      parentRoute: typeof AuthBasicLayoutImport
    }
    '/_auth/_basic-layout/onboarding': {
      id: '/_auth/_basic-layout/onboarding'
      path: '/onboarding'
      fullPath: '/onboarding'
      preLoaderRoute: typeof AuthBasicLayoutOnboardingImport
      parentRoute: typeof AuthBasicLayoutImport
    }
    '/_auth/_basic-layout/start-tracking': {
      id: '/_auth/_basic-layout/start-tracking'
      path: '/start-tracking'
      fullPath: '/start-tracking'
      preLoaderRoute: typeof AuthBasicLayoutStartTrackingImport
      parentRoute: typeof AuthBasicLayoutImport
    }
    '/_auth/_side-layout/events': {
      id: '/_auth/_side-layout/events'
      path: '/events'
      fullPath: '/events'
      preLoaderRoute: typeof AuthSideLayoutEventsImport
      parentRoute: typeof AuthSideLayoutImport
    }
    '/_auth/_side-layout/playground': {
      id: '/_auth/_side-layout/playground'
      path: '/playground'
      fullPath: '/playground'
      preLoaderRoute: typeof AuthSideLayoutPlaygroundImport
      parentRoute: typeof AuthSideLayoutImport
    }
    '/_auth/_side-layout/projects': {
      id: '/_auth/_side-layout/projects'
      path: '/projects'
      fullPath: '/projects'
      preLoaderRoute: typeof AuthSideLayoutProjectsImport
      parentRoute: typeof AuthSideLayoutImport
    }
    '/_auth/_side-layout/reports': {
      id: '/_auth/_side-layout/reports'
      path: '/reports'
      fullPath: '/reports'
      preLoaderRoute: typeof AuthSideLayoutReportsImport
      parentRoute: typeof AuthSideLayoutImport
    }
    '/_auth/_side-layout/search': {
      id: '/_auth/_side-layout/search'
      path: '/search'
      fullPath: '/search'
      preLoaderRoute: typeof AuthSideLayoutSearchImport
      parentRoute: typeof AuthSideLayoutImport
    }
    '/_auth/_side-layout/settings': {
      id: '/_auth/_side-layout/settings'
      path: '/settings'
      fullPath: '/settings'
      preLoaderRoute: typeof AuthSideLayoutSettingsImport
      parentRoute: typeof AuthSideLayoutImport
    }
    '/_auth/_side-layout/upgrade': {
      id: '/_auth/_side-layout/upgrade'
      path: '/upgrade'
      fullPath: '/upgrade'
      preLoaderRoute: typeof AuthSideLayoutUpgradeImport
      parentRoute: typeof AuthSideLayoutImport
    }
    '/_auth/_side-layout/users': {
      id: '/_auth/_side-layout/users'
      path: '/users'
      fullPath: '/users'
      preLoaderRoute: typeof AuthSideLayoutUsersImport
      parentRoute: typeof AuthSideLayoutImport
    }
    '/_auth/_side-layout/': {
      id: '/_auth/_side-layout/'
      path: '/'
      fullPath: '/'
      preLoaderRoute: typeof AuthSideLayoutIndexImport
      parentRoute: typeof AuthSideLayoutImport
    }
    '/_auth/_side-layout/payments/cancel': {
      id: '/_auth/_side-layout/payments/cancel'
      path: '/payments/cancel'
      fullPath: '/payments/cancel'
      preLoaderRoute: typeof AuthSideLayoutPaymentsCancelImport
      parentRoute: typeof AuthSideLayoutImport
    }
    '/_auth/_side-layout/payments/success': {
      id: '/_auth/_side-layout/payments/success'
      path: '/payments/success'
      fullPath: '/payments/success'
      preLoaderRoute: typeof AuthSideLayoutPaymentsSuccessImport
      parentRoute: typeof AuthSideLayoutImport
    }
    '/_auth/_side-layout/projects_/details': {
      id: '/_auth/_side-layout/projects_/details'
      path: '/projects/details'
      fullPath: '/projects/details'
      preLoaderRoute: typeof AuthSideLayoutProjectsDetailsImport
      parentRoute: typeof AuthSideLayoutImport
    }
    '/_auth/_side-layout/reports_/add-funnel-report': {
      id: '/_auth/_side-layout/reports_/add-funnel-report'
      path: '/reports/add-funnel-report'
      fullPath: '/reports/add-funnel-report'
      preLoaderRoute: typeof AuthSideLayoutReportsAddFunnelReportImport
      parentRoute: typeof AuthSideLayoutImport
    }
    '/_auth/_side-layout/users_/$userId': {
      id: '/_auth/_side-layout/users_/$userId'
      path: '/users/$userId'
      fullPath: '/users/$userId'
      preLoaderRoute: typeof AuthSideLayoutUsersUserIdImport
      parentRoute: typeof AuthSideLayoutImport
    }
  }
}

// Create and export the route tree

interface AuthBasicLayoutRouteChildren {
  AuthBasicLayoutAccountRoute: typeof AuthBasicLayoutAccountRoute
  AuthBasicLayoutOnboardingRoute: typeof AuthBasicLayoutOnboardingRoute
  AuthBasicLayoutStartTrackingRoute: typeof AuthBasicLayoutStartTrackingRoute
}

const AuthBasicLayoutRouteChildren: AuthBasicLayoutRouteChildren = {
  AuthBasicLayoutAccountRoute: AuthBasicLayoutAccountRoute,
  AuthBasicLayoutOnboardingRoute: AuthBasicLayoutOnboardingRoute,
  AuthBasicLayoutStartTrackingRoute: AuthBasicLayoutStartTrackingRoute,
}

const AuthBasicLayoutRouteWithChildren = AuthBasicLayoutRoute._addFileChildren(
  AuthBasicLayoutRouteChildren,
)

interface AuthSideLayoutRouteChildren {
  AuthSideLayoutEventsRoute: typeof AuthSideLayoutEventsRoute
  AuthSideLayoutPlaygroundRoute: typeof AuthSideLayoutPlaygroundRoute
  AuthSideLayoutProjectsRoute: typeof AuthSideLayoutProjectsRoute
  AuthSideLayoutReportsRoute: typeof AuthSideLayoutReportsRoute
  AuthSideLayoutSearchRoute: typeof AuthSideLayoutSearchRoute
  AuthSideLayoutSettingsRoute: typeof AuthSideLayoutSettingsRoute
  AuthSideLayoutUpgradeRoute: typeof AuthSideLayoutUpgradeRoute
  AuthSideLayoutUsersRoute: typeof AuthSideLayoutUsersRoute
  AuthSideLayoutIndexRoute: typeof AuthSideLayoutIndexRoute
  AuthSideLayoutPaymentsCancelRoute: typeof AuthSideLayoutPaymentsCancelRoute
  AuthSideLayoutPaymentsSuccessRoute: typeof AuthSideLayoutPaymentsSuccessRoute
  AuthSideLayoutProjectsDetailsRoute: typeof AuthSideLayoutProjectsDetailsRoute
  AuthSideLayoutReportsAddFunnelReportRoute: typeof AuthSideLayoutReportsAddFunnelReportRoute
  AuthSideLayoutUsersUserIdRoute: typeof AuthSideLayoutUsersUserIdRoute
}

const AuthSideLayoutRouteChildren: AuthSideLayoutRouteChildren = {
  AuthSideLayoutEventsRoute: AuthSideLayoutEventsRoute,
  AuthSideLayoutPlaygroundRoute: AuthSideLayoutPlaygroundRoute,
  AuthSideLayoutProjectsRoute: AuthSideLayoutProjectsRoute,
  AuthSideLayoutReportsRoute: AuthSideLayoutReportsRoute,
  AuthSideLayoutSearchRoute: AuthSideLayoutSearchRoute,
  AuthSideLayoutSettingsRoute: AuthSideLayoutSettingsRoute,
  AuthSideLayoutUpgradeRoute: AuthSideLayoutUpgradeRoute,
  AuthSideLayoutUsersRoute: AuthSideLayoutUsersRoute,
  AuthSideLayoutIndexRoute: AuthSideLayoutIndexRoute,
  AuthSideLayoutPaymentsCancelRoute: AuthSideLayoutPaymentsCancelRoute,
  AuthSideLayoutPaymentsSuccessRoute: AuthSideLayoutPaymentsSuccessRoute,
  AuthSideLayoutProjectsDetailsRoute: AuthSideLayoutProjectsDetailsRoute,
  AuthSideLayoutReportsAddFunnelReportRoute:
    AuthSideLayoutReportsAddFunnelReportRoute,
  AuthSideLayoutUsersUserIdRoute: AuthSideLayoutUsersUserIdRoute,
}

const AuthSideLayoutRouteWithChildren = AuthSideLayoutRoute._addFileChildren(
  AuthSideLayoutRouteChildren,
)

interface AuthRouteChildren {
  AuthBasicLayoutRoute: typeof AuthBasicLayoutRouteWithChildren
  AuthSideLayoutRoute: typeof AuthSideLayoutRouteWithChildren
}

const AuthRouteChildren: AuthRouteChildren = {
  AuthBasicLayoutRoute: AuthBasicLayoutRouteWithChildren,
  AuthSideLayoutRoute: AuthSideLayoutRouteWithChildren,
}

const AuthRouteWithChildren = AuthRoute._addFileChildren(AuthRouteChildren)

export interface FileRoutesByFullPath {
  '': typeof AuthSideLayoutRouteWithChildren
  '/demo': typeof DemoRoute
  '/sign-in': typeof SignInRoute
  '/sign-up': typeof SignUpRoute
  '/auth/callback': typeof AuthCallbackRoute
  '/shares/$dashboardId': typeof SharesDashboardIdRoute
  '/account': typeof AuthBasicLayoutAccountRoute
  '/onboarding': typeof AuthBasicLayoutOnboardingRoute
  '/start-tracking': typeof AuthBasicLayoutStartTrackingRoute
  '/events': typeof AuthSideLayoutEventsRoute
  '/playground': typeof AuthSideLayoutPlaygroundRoute
  '/projects': typeof AuthSideLayoutProjectsRoute
  '/reports': typeof AuthSideLayoutReportsRoute
  '/search': typeof AuthSideLayoutSearchRoute
  '/settings': typeof AuthSideLayoutSettingsRoute
  '/upgrade': typeof AuthSideLayoutUpgradeRoute
  '/users': typeof AuthSideLayoutUsersRoute
  '/': typeof AuthSideLayoutIndexRoute
  '/payments/cancel': typeof AuthSideLayoutPaymentsCancelRoute
  '/payments/success': typeof AuthSideLayoutPaymentsSuccessRoute
  '/projects/details': typeof AuthSideLayoutProjectsDetailsRoute
  '/reports/add-funnel-report': typeof AuthSideLayoutReportsAddFunnelReportRoute
  '/users/$userId': typeof AuthSideLayoutUsersUserIdRoute
}

export interface FileRoutesByTo {
  '': typeof AuthBasicLayoutRouteWithChildren
  '/demo': typeof DemoRoute
  '/sign-in': typeof SignInRoute
  '/sign-up': typeof SignUpRoute
  '/auth/callback': typeof AuthCallbackRoute
  '/shares/$dashboardId': typeof SharesDashboardIdRoute
  '/account': typeof AuthBasicLayoutAccountRoute
  '/onboarding': typeof AuthBasicLayoutOnboardingRoute
  '/start-tracking': typeof AuthBasicLayoutStartTrackingRoute
  '/events': typeof AuthSideLayoutEventsRoute
  '/playground': typeof AuthSideLayoutPlaygroundRoute
  '/projects': typeof AuthSideLayoutProjectsRoute
  '/reports': typeof AuthSideLayoutReportsRoute
  '/search': typeof AuthSideLayoutSearchRoute
  '/settings': typeof AuthSideLayoutSettingsRoute
  '/upgrade': typeof AuthSideLayoutUpgradeRoute
  '/users': typeof AuthSideLayoutUsersRoute
  '/': typeof AuthSideLayoutIndexRoute
  '/payments/cancel': typeof AuthSideLayoutPaymentsCancelRoute
  '/payments/success': typeof AuthSideLayoutPaymentsSuccessRoute
  '/projects/details': typeof AuthSideLayoutProjectsDetailsRoute
  '/reports/add-funnel-report': typeof AuthSideLayoutReportsAddFunnelReportRoute
  '/users/$userId': typeof AuthSideLayoutUsersUserIdRoute
}

export interface FileRoutesById {
  __root__: typeof rootRoute
  '/_auth': typeof AuthRouteWithChildren
  '/demo': typeof DemoRoute
  '/sign-in': typeof SignInRoute
  '/sign-up': typeof SignUpRoute
  '/_auth/_basic-layout': typeof AuthBasicLayoutRouteWithChildren
  '/_auth/_side-layout': typeof AuthSideLayoutRouteWithChildren
  '/auth/callback': typeof AuthCallbackRoute
  '/shares/$dashboardId': typeof SharesDashboardIdRoute
  '/_auth/_basic-layout/account': typeof AuthBasicLayoutAccountRoute
  '/_auth/_basic-layout/onboarding': typeof AuthBasicLayoutOnboardingRoute
  '/_auth/_basic-layout/start-tracking': typeof AuthBasicLayoutStartTrackingRoute
  '/_auth/_side-layout/events': typeof AuthSideLayoutEventsRoute
  '/_auth/_side-layout/playground': typeof AuthSideLayoutPlaygroundRoute
  '/_auth/_side-layout/projects': typeof AuthSideLayoutProjectsRoute
  '/_auth/_side-layout/reports': typeof AuthSideLayoutReportsRoute
  '/_auth/_side-layout/search': typeof AuthSideLayoutSearchRoute
  '/_auth/_side-layout/settings': typeof AuthSideLayoutSettingsRoute
  '/_auth/_side-layout/upgrade': typeof AuthSideLayoutUpgradeRoute
  '/_auth/_side-layout/users': typeof AuthSideLayoutUsersRoute
  '/_auth/_side-layout/': typeof AuthSideLayoutIndexRoute
  '/_auth/_side-layout/payments/cancel': typeof AuthSideLayoutPaymentsCancelRoute
  '/_auth/_side-layout/payments/success': typeof AuthSideLayoutPaymentsSuccessRoute
  '/_auth/_side-layout/projects_/details': typeof AuthSideLayoutProjectsDetailsRoute
  '/_auth/_side-layout/reports_/add-funnel-report': typeof AuthSideLayoutReportsAddFunnelReportRoute
  '/_auth/_side-layout/users_/$userId': typeof AuthSideLayoutUsersUserIdRoute
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath
  fullPaths:
    | ''
    | '/demo'
    | '/sign-in'
    | '/sign-up'
    | '/auth/callback'
    | '/shares/$dashboardId'
    | '/account'
    | '/onboarding'
    | '/start-tracking'
    | '/events'
    | '/playground'
    | '/projects'
    | '/reports'
    | '/search'
    | '/settings'
    | '/upgrade'
    | '/users'
    | '/'
    | '/payments/cancel'
    | '/payments/success'
    | '/projects/details'
    | '/reports/add-funnel-report'
    | '/users/$userId'
  fileRoutesByTo: FileRoutesByTo
  to:
    | ''
    | '/demo'
    | '/sign-in'
    | '/sign-up'
    | '/auth/callback'
    | '/shares/$dashboardId'
    | '/account'
    | '/onboarding'
    | '/start-tracking'
    | '/events'
    | '/playground'
    | '/projects'
    | '/reports'
    | '/search'
    | '/settings'
    | '/upgrade'
    | '/users'
    | '/'
    | '/payments/cancel'
    | '/payments/success'
    | '/projects/details'
    | '/reports/add-funnel-report'
    | '/users/$userId'
  id:
    | '__root__'
    | '/_auth'
    | '/demo'
    | '/sign-in'
    | '/sign-up'
    | '/_auth/_basic-layout'
    | '/_auth/_side-layout'
    | '/auth/callback'
    | '/shares/$dashboardId'
    | '/_auth/_basic-layout/account'
    | '/_auth/_basic-layout/onboarding'
    | '/_auth/_basic-layout/start-tracking'
    | '/_auth/_side-layout/events'
    | '/_auth/_side-layout/playground'
    | '/_auth/_side-layout/projects'
    | '/_auth/_side-layout/reports'
    | '/_auth/_side-layout/search'
    | '/_auth/_side-layout/settings'
    | '/_auth/_side-layout/upgrade'
    | '/_auth/_side-layout/users'
    | '/_auth/_side-layout/'
    | '/_auth/_side-layout/payments/cancel'
    | '/_auth/_side-layout/payments/success'
    | '/_auth/_side-layout/projects_/details'
    | '/_auth/_side-layout/reports_/add-funnel-report'
    | '/_auth/_side-layout/users_/$userId'
  fileRoutesById: FileRoutesById
}

export interface RootRouteChildren {
  AuthRoute: typeof AuthRouteWithChildren
  DemoRoute: typeof DemoRoute
  SignInRoute: typeof SignInRoute
  SignUpRoute: typeof SignUpRoute
  AuthCallbackRoute: typeof AuthCallbackRoute
  SharesDashboardIdRoute: typeof SharesDashboardIdRoute
}

const rootRouteChildren: RootRouteChildren = {
  AuthRoute: AuthRouteWithChildren,
  DemoRoute: DemoRoute,
  SignInRoute: SignInRoute,
  SignUpRoute: SignUpRoute,
  AuthCallbackRoute: AuthCallbackRoute,
  SharesDashboardIdRoute: SharesDashboardIdRoute,
}

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>()

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.tsx",
      "children": [
        "/_auth",
        "/demo",
        "/sign-in",
        "/sign-up",
        "/auth/callback",
        "/shares/$dashboardId"
      ]
    },
    "/_auth": {
      "filePath": "_auth.tsx",
      "children": [
        "/_auth/_basic-layout",
        "/_auth/_side-layout"
      ]
    },
    "/demo": {
      "filePath": "demo.tsx"
    },
    "/sign-in": {
      "filePath": "sign-in.tsx"
    },
    "/sign-up": {
      "filePath": "sign-up.tsx"
    },
    "/_auth/_basic-layout": {
      "filePath": "_auth/_basic-layout.tsx",
      "parent": "/_auth",
      "children": [
        "/_auth/_basic-layout/account",
        "/_auth/_basic-layout/onboarding",
        "/_auth/_basic-layout/start-tracking"
      ]
    },
    "/_auth/_side-layout": {
      "filePath": "_auth/_side-layout.tsx",
      "parent": "/_auth",
      "children": [
        "/_auth/_side-layout/events",
        "/_auth/_side-layout/playground",
        "/_auth/_side-layout/projects",
        "/_auth/_side-layout/reports",
        "/_auth/_side-layout/search",
        "/_auth/_side-layout/settings",
        "/_auth/_side-layout/upgrade",
        "/_auth/_side-layout/users",
        "/_auth/_side-layout/",
        "/_auth/_side-layout/payments/cancel",
        "/_auth/_side-layout/payments/success",
        "/_auth/_side-layout/projects_/details",
        "/_auth/_side-layout/reports_/add-funnel-report",
        "/_auth/_side-layout/users_/$userId"
      ]
    },
    "/auth/callback": {
      "filePath": "auth.callback.tsx"
    },
    "/shares/$dashboardId": {
      "filePath": "shares.$dashboardId.tsx"
    },
    "/_auth/_basic-layout/account": {
      "filePath": "_auth/_basic-layout/account.tsx",
      "parent": "/_auth/_basic-layout"
    },
    "/_auth/_basic-layout/onboarding": {
      "filePath": "_auth/_basic-layout/onboarding.tsx",
      "parent": "/_auth/_basic-layout"
    },
    "/_auth/_basic-layout/start-tracking": {
      "filePath": "_auth/_basic-layout/start-tracking.tsx",
      "parent": "/_auth/_basic-layout"
    },
    "/_auth/_side-layout/events": {
      "filePath": "_auth/_side-layout/events.tsx",
      "parent": "/_auth/_side-layout"
    },
    "/_auth/_side-layout/playground": {
      "filePath": "_auth/_side-layout/playground.tsx",
      "parent": "/_auth/_side-layout"
    },
    "/_auth/_side-layout/projects": {
      "filePath": "_auth/_side-layout/projects.tsx",
      "parent": "/_auth/_side-layout"
    },
    "/_auth/_side-layout/reports": {
      "filePath": "_auth/_side-layout/reports.tsx",
      "parent": "/_auth/_side-layout"
    },
    "/_auth/_side-layout/search": {
      "filePath": "_auth/_side-layout/search.tsx",
      "parent": "/_auth/_side-layout"
    },
    "/_auth/_side-layout/settings": {
      "filePath": "_auth/_side-layout/settings.tsx",
      "parent": "/_auth/_side-layout"
    },
    "/_auth/_side-layout/upgrade": {
      "filePath": "_auth/_side-layout/upgrade.tsx",
      "parent": "/_auth/_side-layout"
    },
    "/_auth/_side-layout/users": {
      "filePath": "_auth/_side-layout/users.tsx",
      "parent": "/_auth/_side-layout"
    },
    "/_auth/_side-layout/": {
      "filePath": "_auth/_side-layout/index.tsx",
      "parent": "/_auth/_side-layout"
    },
    "/_auth/_side-layout/payments/cancel": {
      "filePath": "_auth/_side-layout/payments.cancel.tsx",
      "parent": "/_auth/_side-layout"
    },
    "/_auth/_side-layout/payments/success": {
      "filePath": "_auth/_side-layout/payments.success.tsx",
      "parent": "/_auth/_side-layout"
    },
    "/_auth/_side-layout/projects_/details": {
      "filePath": "_auth/_side-layout/projects_.details.tsx",
      "parent": "/_auth/_side-layout"
    },
    "/_auth/_side-layout/reports_/add-funnel-report": {
      "filePath": "_auth/_side-layout/reports_.add-funnel-report.tsx",
      "parent": "/_auth/_side-layout"
    },
    "/_auth/_side-layout/users_/$userId": {
      "filePath": "_auth/_side-layout/users_.$userId.tsx",
      "parent": "/_auth/_side-layout"
    }
  }
}
ROUTE_MANIFEST_END */
