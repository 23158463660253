import { useForm } from 'react-hook-form';
import { AuthService, isDemo } from '@/api';
import { logspot } from '@logspot/web';
import { Input } from '@/components/ui/input';
import { LinkLogo } from '@/components/nav/link-logo';
import GoogleLogo from '@/images/google-logo.svg?react';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { createFileRoute, Link } from '@tanstack/react-router';
import { useNavigate } from '@tanstack/react-router';
import {
  Form,
  FormControl,
  FormField,
  FormLabel,
  FormItem,
  FormMessage,
} from '@/components/ui/form';
import { Button } from '@/components/ui/button';
import { EmailPass } from '@/types';

const signUpSchema = z.object({
  email: z
    .string({ required_error: 'Please enter your email address.' })
    .email({
      message: 'Please enter a valid email address.',
    }),
  password: z.string({ required_error: 'Please enter your password.' }).min(8, {
    message: 'Please enter a password that is at least eight characters.',
  }),
});

export const Route = createFileRoute('/sign-up')({
  component: SignUp,
});

function SignUp() {
  if (isDemo()) {
    localStorage.removeItem('ls-project-id');
    localStorage.removeItem('ls-demo');
    logspot.unregister('demo');
  }
  const navigate = useNavigate();

  const form = useForm<z.infer<typeof signUpSchema>>({
    resolver: zodResolver(signUpSchema),
    defaultValues: {
      email: '',
      password: '',
    },
  });

  const onSubmit = async ({ email, password }: EmailPass) => {
    const data = await AuthService.register({
      email,
      password,
    });
    if (!data) {
      return;
    }
    const { token, accountId } = data;
    logspot.register({ email });
    if (token) {
      logspot.register({ authed: true, accountId });
      logspot.unregister('demo');
      navigate({ to: '/' });
    }
  };

  return (
    <section className="py-12 sm:py-16 lg:py-20">
      <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto max-w-sm">
          <div className="flex flex-col items-center">
            <LinkLogo />
            {/* <img className="w-auto h-12 mx-auto" src="https://landingfoliocom.imgix.net/store/collection/clarity-dashboard/images/logo-symbol.svg" alt="" /> */}
            <h1 className="mt-12 text-3xl font-bold">Sign Up For Free</h1>
            <p className="mt-4 font-medium text-muted-foreground">
              Web and product analytics simplified.
            </p>
          </div>

          <div className="mt-12">
            <a href="https://login.logspot.io/auth/google">
              <Button
                className="w-full"
                size="lg"
                type="button"
                variant="outline"
                onClick={() => {
                  logspot.track({
                    event: 'Signed Up',
                    metadata: { method: 'google' },
                  });
                }}
              >
                <GoogleLogo className="mr-2 size-5" />
                Sign Up With Google
              </Button>
            </a>
          </div>

          <div className="relative mt-6">
            <div className="absolute inset-0 flex items-center">
              <div className="w-full border-t border-border"></div>
            </div>

            <div className="relative flex justify-center">
              <span className="px-2"> or </span>
            </div>
          </div>

          <Form {...form}>
            <form
              className="mt-4 space-y-5"
              onSubmit={form.handleSubmit(onSubmit)}
            >
              <FormField
                control={form.control}
                name="email"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Email</FormLabel>
                    <FormControl>
                      <Input
                        className="h-10"
                        id="email"
                        {...field}
                        autoComplete="username"
                        placeholder="Enter Your Email Address..."
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="password"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Password</FormLabel>
                    <FormControl>
                      <Input
                        className="h-10"
                        id="password"
                        type="password"
                        {...field}
                        autoComplete="current-password"
                        placeholder="Enter a Password..."
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <Button
                className="w-full"
                size="lg"
                type="submit"
                onClick={() =>
                  logspot.track({
                    event: 'Signed Up',
                    metadata: { method: 'email' },
                  })
                }
              >
                Sign Up With Email
              </Button>
            </form>
          </Form>

          <div className="mt-6 text-center">
            <p className="font-medium text-muted-foreground">
              Do you already have an account?{' '}
              <Link to="/sign-in">
                <Button className="px-1" variant="link">
                  {' '}
                  Sign In{' '}
                </Button>
              </Link>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
