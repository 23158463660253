import { ArrowLeft, Eye, HandPointing, Trash } from '@phosphor-icons/react';
import { DatePicker } from '@/components/date-picker';
import { Button } from '@/components/ui/button';
import { createFileRoute, Link } from '@tanstack/react-router';
import { FunnelReport } from '@/components/reports/funnel-report';
import { StepModal } from '@/components/reports/step-modal';
import { SaveReportModal } from '@/components/reports/save-report-modal';
import { useSteps } from '@/hooks/use-steps';

export const Route = createFileRoute(
  '/_auth/_side-layout/reports_/add-funnel-report',
)({
  component: ReportsFunnels,
});

function ReportsFunnels() {
  const { steps, removeStep, clearSteps } = useSteps();
  const config = { type: 'funnel', steps };

  return (
    <>
      <h1 className="text-2xl font-extrabold">Add Funnel Report</h1>
      <div className="mt-10 flex items-center gap-3">
        <Link to="/reports">
          <Button iconLeft={<ArrowLeft />} variant="outline">
            Back
          </Button>
        </Link>
        <DatePicker />
        {steps.length > 0 && (
          <Button variant="outline" onClick={() => clearSteps()}>
            Reset
          </Button>
        )}
        {steps.length > 0 && <SaveReportModal config={config} />}
      </div>

      <div className="mb-10 mt-5 flex w-full items-center gap-5 rounded border border-border p-5 transition-all">
        {steps.map((step) => (
          <div key={step.value}>
            <div className="flex items-center gap-10 rounded border border-border px-5 py-3 hover:border-primary hover:text-primary">
              <div className="flex items-center gap-2">
                {step.type === 'Pageview' ? (
                  <Eye className="text-primary" />
                ) : (
                  <HandPointing className="text-primary" />
                )}
                <span className="font-bold">{step.value}</span>
              </div>
              <span className="cursor-pointer" onClick={() => removeStep(step)}>
                <Trash />
              </span>
            </div>
          </div>
        ))}
        {steps?.length <= 2 && <StepModal />}
      </div>
      <FunnelReport config={config} />
    </>
  );
}
