import { useState, useEffect } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { EmojiAvatar } from '@/components/emoji-avatar';
import { EventFeed } from '@/components/event-feed';
import { useProjects } from '@/hooks/use-projects';
import { createFileRoute } from '@tanstack/react-router';
import {
  userEventsOptions,
  infiniteEventsOptions,
} from '@/hooks/query-options';
import { PageHeader } from '@/components/page-header';

export const Route = createFileRoute('/_auth/_side-layout/users_/$userId')({
  component: UserDetails,
  loader: async ({ context, params }) => {
    const projectId = context.projectId;
    const channelId = context.channelId;
    const userId = params.userId;
    const filterGroups = [
      {
        filters: [
          {
            fieldName: 'user_id',
            operator: '=',
            value: userId,
          },
        ],
      },
    ];
    const selectedEvent = null;
    if (!projectId || !userId) {
      return;
    }
    await context.queryClient.prefetchQuery(
      userEventsOptions({ projectId, userId }),
    );
    context.queryClient.prefetchInfiniteQuery(
      infiniteEventsOptions({
        projectId,
        channelId,
        filterGroups,
        selectedEvent,
      }),
    );
  },
});

function UserDetails() {
  const [eventSummary, setEventSummary] = useState<
    { name: string; count: number }[]
  >([]);
  const { userId } = Route.useParams();
  const { projectId } = useProjects();
  if (!projectId) {
    return null;
  }

  const { data: events } = useSuspenseQuery(
    userEventsOptions({ projectId, userId }),
  );

  useEffect(() => {
    if (events && Object.keys(events).length > 0) {
      const summary = Object.entries(events).map((next) => {
        const [name, counts] = next as [string, { count: number }[]];
        const sumCount = counts.reduce((acc, count) => {
          return acc + parseInt(count.count.toString());
        }, 0);
        return { name, count: sumCount };
      });

      setEventSummary(summary);
    }
  }, [events]);

  return (
    <>
      <div className="flex items-center gap-4">
        <EmojiAvatar id={userId} />
        <PageHeader separator={false} title={userId} />
      </div>

      <div className="mt-5 flex flex-wrap items-center gap-3">
        {eventSummary.map((summary) => (
          <div
            key={summary.name}
            className="flex flex-col gap-2 rounded border p-5"
          >
            <span className="text-accent-foreground">{summary.name}s</span>
            <span className="text-4xl font-extrabold">{summary.count}</span>
            <span className="text-accent-foreground">In the last 30 days</span>
          </div>
        ))}
      </div>
      <div>
        <EventFeed
          {...{
            projectId,
            hideUserAvatar: true,
            filterGroups: [
              {
                filters: [
                  {
                    fieldName: 'user_id',
                    operator: '=',
                    value: userId,
                  },
                ],
              },
            ],
          }}
        />
      </div>
    </>
  );
}
