type Data = {
  [key: string]: string | null;
};

export function removeEmptyFields(data: Data): void {
  Object.keys(data).forEach((key) => {
    if (data[key] === '' || data[key] === null) {
      delete data[key];
    }
  });
}
