import { createFileRoute } from '@tanstack/react-router';
// import { useCallback } from 'react';
// // import { ThreeTierPricing } from '@/components/three-tier-pricing';
// import { PaymentService } from '@/api';

export const Route = createFileRoute('/_auth/_side-layout/upgrade')({
  component: Upgrade,
});

function Upgrade() {
  // const upgrade = useCallback(async (plan: string) => {
  //   const url = await PaymentService.getCheckoutUrl({ plan });
  //   window.location.href = url;
  // }, []);

  return null;
  // <ThreeTierPricing
  //   onBasicClick={() => upgrade('BASIC')}
  //   onProClick={() => upgrade('PRO')}
  // />
}
