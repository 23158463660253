import { useProjects } from '@/hooks/use-projects';
import { Robots } from '@/components/robots/robots';
import { createFileRoute } from '@tanstack/react-router';
import { General } from '@/components/general-settings';
import { Channels } from '@/components/channels';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/ui/tabs';
import { PageHeader } from '@/components/page-header';
import { Integrations } from '@/components/integrations';
import { ApiKeys } from '@/components/api-keys';
import { automationsOptions, eventNamesOptions } from '@/hooks/query-options';

export const Route = createFileRoute('/_auth/_side-layout/settings')({
  component: Settings,
  beforeLoad: async ({ context }) => {
    const projectId = context.projectId;
    if (!projectId) {
      return;
    }
    context.queryClient.prefetchQuery(eventNamesOptions({ projectId }));
    context.queryClient.prefetchQuery(automationsOptions({ projectId }));
  },
});

const TABS = [
  {
    label: 'General',
    value: 'general',
    content: <General />,
  },
  {
    label: 'Integrations',
    value: 'integrations',
    content: <Integrations />,
  },
  {
    label: 'Robots',
    value: 'robots',
    content: <Robots />,
  },
  {
    label: 'Channels',
    value: 'channels',
    content: <Channels />,
  },
  {
    label: 'API Keys',
    value: 'api-keys',
    content: <ApiKeys />,
  },
];

function Settings() {
  const { projectName } = useProjects();
  if (!projectName) {
    return null;
  }

  return (
    <>
      <PageHeader separator={false} title="Settings" />
      <Tabs className="mt-6" defaultValue="general">
        <TabsList>
          {TABS.map(({ label, value }) => (
            <TabsTrigger key={value} value={value}>
              {label}
            </TabsTrigger>
          ))}
        </TabsList>
        {TABS.map(({ value, content }) => (
          <TabsContent key={value} value={value}>
            {content}
          </TabsContent>
        ))}
      </Tabs>
    </>
  );
}
