import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Button } from '@/components/ui/button';
import { DashboardService } from '@/api';
import { toast } from '@/components/ui/use-toast';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { DashboardConfig, OpenShare } from '@/types';
import { Input } from './ui/input';
import { Label } from './ui/label';
import { Switch } from './ui/switch';

type FormValues = Omit<OpenShare, 'id' | 'whitelist'> & { whitelist: string };

type DashboardShareProps = {
  projectId: string;
  data: DashboardConfig;
};

export const DashboardShare = ({ projectId, data }: DashboardShareProps) => {
  const queryClient = useQueryClient();
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    watch,
    control,
  } = useForm({
    defaultValues: {
      name: '',
      visible: false,
      description: '',
      primaryColor: '',
      textColor: '',
      whitelist: '',
    },
  });

  const { mutate } = useMutation<void, unknown, FormValues>({
    mutationFn: async (values: FormValues) => {
      return DashboardService.updateOpenDashboard({
        projectId,
        dashboard: {
          ...values,
          whitelist: values.whitelist.split('|'),
        },
      });
    },
    onSuccess: () => {
      toast({ title: 'Share Updated' });
      queryClient.invalidateQueries({
        queryKey: ['dashboard-config', projectId],
      });
    },
    onError: () => {
      toast({
        title: 'Error While Updating Share',
        description: 'Please try again.',
        variant: 'destructive',
      });
    },
  });

  useEffect(() => {
    if (data) {
      reset({
        name: data?.open?.name,
        visible: data?.open?.visible,
        description: data?.open?.description,
        primaryColor: data?.open?.primaryColor,
        textColor: data?.open?.textColor,
        whitelist: data?.open?.whitelist?.join('|'),
      });
    }
  }, [data, reset]);

  const watchPrimaryColor = watch('primaryColor');
  const watchTextColor = watch('textColor');

  const updateSettings = (values: FormValues) => {
    mutate(values);
  };

  return (
    <div className="items-center rounded-lg p-10 shadow-sm">
      <h2 className="text-xl font-bold leading-6">Public Dashboard Settings</h2>
      {!data?.open && (
        <div className="mt-2">
          <Button
            onClick={() =>
              DashboardService.generateLink({ projectId })
                .then(() =>
                  queryClient.invalidateQueries({
                    queryKey: ['dashboard-config'],
                  }),
                )
                .catch(console.error)
            }
          >
            Generate Public Link
          </Button>
        </div>
      )}
      {data?.open && (
        <form onSubmit={handleSubmit(updateSettings)}>
          <div className="mt-2 flex max-w-md flex-col gap-3">
            <div className="my-3 flex flex-col gap-2">
              <a
                className="text-primary font-bold"
                href={`/shares/${data?.open?.id}`}
                rel="noopener noreferrer"
                target="_blank"
              >{`https://app.logspot.io/shares/${data?.open?.id}`}</a>
            </div>
            <div className="flex flex-col gap-2">
              <div className="flex items-center gap-2">
                <Controller
                  control={control}
                  name="visible"
                  render={({ field: { onChange, value } }) => (
                    <>
                      <Switch
                        checked={value}
                        onCheckedChange={(value: boolean) =>
                          onChange({ target: { value: value } })
                        }
                      />
                      <span>{value ? 'Visible' : 'Hidden'}</span>
                    </>
                  )}
                ></Controller>
              </div>
            </div>
            <div className="flex flex-col gap-2">
              <Label>Header</Label>
              <Input
                placeholder="MyDomain Metrics"
                {...register('name', { required: true })}
              />
              {errors.name && <span>This field is required</span>}
            </div>
            <div className="flex flex-col gap-2">
              <Label>Subheader</Label>
              <Input placeholder="Last 30 days" {...register('description')} />
            </div>
            <div className="flex flex-col gap-2">
              <Label>Background Color</Label>
              <Input
                placeholder="#030712"
                {...register('primaryColor', { required: true })}
              />
              {errors.primaryColor && <span>This field is required</span>}
            </div>
            <div className="flex flex-col gap-2">
              <Label>Header & Chart Color</Label>
              <Input
                placeholder="#057BFA"
                {...register('textColor', { required: true })}
              />
              {errors.textColor && <span>This field is required</span>}
            </div>
            <div
              className="w-full rounded border p-3 text-center font-bold shadow-sm"
              style={{
                backgroundColor: watchPrimaryColor,
                color: watchTextColor,
              }}
            >
              Example
            </div>
            <div className="flex flex-col gap-2">
              <Label>Displayed Events</Label>
              <Input
                placeholder="Pageview|Subscription Purchased|etc."
                {...register('whitelist', { required: true })}
              />
              {errors.whitelist && <span>This field is required</span>}
              <p className="text-muted-foreground text-[0.8rem]">
                Separate events with a | (pipe) character
              </p>
            </div>
            <Button type="submit">Save</Button>
          </div>
        </form>
      )}
    </div>
  );
};
