import {
  AccountDetailsService,
  ProjectsService,
  ChannelsService,
  AnalyticsService,
  UsersService,
  EventsService,
  DashboardService,
  ReportsService,
  AutomationsService,
} from '@/api';
import {
  GetRetention,
  GetUsers,
  GetInfiniteEvents,
  GetAllEventsCount,
  UserEvents,
} from '@/types';
import { queryOptions, infiniteQueryOptions } from '@tanstack/react-query';
import { endOfDay, endOfHour, sub, subDays } from 'date-fns';

export const DATE_RANGE = 30;

export const accountDetailsOptions = () =>
  queryOptions({
    queryKey: ['account-details'],
    queryFn: async () => await AccountDetailsService.getAccountDetails(),
    // Set a stale time of 1 hour
    staleTime: 1000 * 60 * 60,
  });

export const infiniteEventsOptions = ({
  projectId,
  channelId,
  filterGroups,
  selectedEvent,
}: GetInfiniteEvents) =>
  infiniteQueryOptions({
    queryKey: [
      'events_search',
      { projectId, selectedEvent, filterGroups, channelId },
    ],
    queryFn: async ({ pageParam }) => {
      return await EventsService.searchEvents({
        projectId,
        eventName: selectedEvent,
        filterGroups: filterGroups,
        cursor: pageParam ? pageParam : undefined,
        channelId,
      });
    },
    getNextPageParam: (lastPage) => lastPage?.cursor,
    refetchInterval: 60000,
    initialPageParam: null,
    // Set a stale time of 1 minute
    staleTime: 1000 * 60,
  });

export const projectsOptions = () =>
  queryOptions({
    queryKey: ['projects'],
    queryFn: async () => await ProjectsService.getProjects(),
    // Set a stale time of 1 hour
    staleTime: 1000 * 60 * 60,
  });

export const channelsOptions = ({ projectId }: { projectId: string }) =>
  queryOptions({
    queryKey: ['channels', projectId],
    queryFn: async () => await ChannelsService.getChannels({ projectId }),
    // Set a stale time of 1 hour
    staleTime: 1000 * 60 * 60,
  });

export const retentionOptions = ({
  projectId,
  days,
  channelId,
  filterGroups,
}: GetRetention) =>
  queryOptions({
    queryKey: ['retention', projectId, days, channelId, filterGroups],
    queryFn: async () =>
      await AnalyticsService.getRetention({
        projectId,
        startDate: sub(new Date(), { days: days }),
        channelId,
        filterGroups,
      }),
    // Set a stale time of 1 minute
    staleTime: 1000 * 60,
  });

export const allEventsOptions = ({
  projectId,
  dateRange,
  filterGroups,
  channelId,
}: GetAllEventsCount) =>
  queryOptions({
    queryKey: [
      'events-all:count',
      projectId,
      dateRange,
      filterGroups,
      channelId,
    ],
    queryFn: () =>
      EventsService.getAllEventCounts({
        projectId,
        interval: dateRange.interval,
        startDate: dateRange.startDate.getTime(),
        endDate: dateRange.endDate.getTime(),
        filterGroups,
        channelId,
        userId: null,
      }),
    // Set a stale time of 1 minute
    staleTime: 1000 * 60,
  });

export const allEventsPrevOptions = ({
  projectId,
  dateRange,
  filterGroups,
  channelId,
}: GetAllEventsCount) => {
  const prevDateRange = {
    interval: dateRange.interval,
    startDate: sub(dateRange.startDate, dateRange.period || { days: 1 }),
    endDate: dateRange.interval.includes('days')
      ? endOfDay(dateRange.startDate)
      : endOfHour(dateRange.startDate),
  };

  return queryOptions({
    queryKey: [
      'events-all:count:previous',
      projectId,
      dateRange,
      filterGroups,
      channelId,
    ],
    queryFn: () =>
      EventsService.getAllEventCounts({
        projectId,
        interval: prevDateRange.interval,
        startDate: prevDateRange.startDate.getTime(),
        endDate: prevDateRange.endDate.getTime(),
        filterGroups,
        channelId,
        userId: null,
      }),
    // Set a stale time of 1 minute
    staleTime: 1000 * 60,
  });
};

export const dashboardConfigOptions = ({ projectId }: { projectId: string }) =>
  queryOptions({
    queryKey: ['dashboard-config', projectId],
    queryFn: () => DashboardService.getDashboardConfig({ projectId }),
    // Set a stale time of 1 minute
    staleTime: 1000 * 60,
  });

export const usersOptions = ({ projectId, page, channelId }: GetUsers) =>
  queryOptions({
    queryKey: ['users', projectId, page, channelId],
    queryFn: async () =>
      await UsersService.getUsers({ projectId, page, channelId }),
    // Set a stale time of 1 minute
    staleTime: 1000 * 60,
  });

export const userEventsOptions = ({ projectId, userId }: UserEvents) =>
  queryOptions({
    queryKey: ['user:events-all', projectId, DATE_RANGE, userId],
    queryFn: () =>
      EventsService.getAllEventCounts({
        projectId,
        startDate: subDays(new Date(), DATE_RANGE).getTime(),
        endDate: new Date().getTime(),
        interval: '1 days',
        userId,
      }),
    // Set a stale time of 1 minute
    staleTime: 1000 * 60,
  });

export const reportsOptions = ({ projectId }: { projectId: string }) =>
  queryOptions({
    queryKey: ['reports', projectId],
    queryFn: () => ReportsService.getReports({ projectId }),
    // Set a stale time of 1 minute
    staleTime: 1000 * 60,
  });

export const eventNamesOptions = ({
  projectId,
  channelId,
}: {
  projectId: string;
  channelId?: string | null;
}) =>
  queryOptions({
    queryKey: ['event-names', projectId, channelId],
    queryFn: async () => EventsService.getEventNames({ projectId, channelId }),
    // Set a stale time of 1 hour
    staleTime: 1000 * 60 * 60,
  });

export const automationsOptions = ({ projectId }: { projectId: string }) =>
  queryOptions({
    queryKey: ['automations', projectId],
    queryFn: async () => AutomationsService.getAutomations({ projectId }),
    // Set a stale time of 1 hour
    staleTime: 1000 * 60 * 60,
  });
