import * as React from 'react';
import { cva, type VariantProps } from 'class-variance-authority';

import { cn } from '@/utils/cn';

const alertVariants = cva('flex w-full flex-col rounded border p-4', {
  variants: {
    variant: {
      default: [
        // text color
        'text-blue-900 dark:text-blue-500',
        // background color
        'bg-blue-50 dark:bg-blue-950/70 ',
        // border color
        'border-blue-900 dark:border-blue-500',
      ],
      success: [
        // text color
        'text-emerald-900 dark:text-emerald-500',
        // background color
        'bg-emerald-50 dark:bg-emerald-950/70 ',
        // border color
        'border-emerald-900 dark:border-emerald-500',
      ],
      error: [
        // text color
        ' text-destructive',
        // background color
        'bg-destructive/10',
        // border color
        'border-destructive',
      ],
      warning: [
        // text color
        ' text-yellow-900 dark:text-yellow-500',
        // background color
        'bg-yellow-50 dark:bg-yellow-950/70',
        // border color
        'border-yellow-900 dark:border-yellow-500',
      ],
      neutral: [
        // text color
        'text-gray-900 dark:text-gray-400',
        // background color
        'bg-gray-100 dark:bg-gray-800/70',
        // border color
        'border-gray-900 dark:border-gray-400',
      ],
    },
  },
  defaultVariants: {
    variant: 'default',
  },
});

type AlertProps = {
  title: string;
  subTitle?: string;
  icon?: React.ElementType | React.ReactElement;
};

export const Alert = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> &
    VariantProps<typeof alertVariants> &
    AlertProps
>(({ title, subTitle, className, variant, icon: Icon, ...props }, ref) => (
  <div
    ref={ref}
    className={cn(alertVariants({ variant }), className)}
    role="alert"
    {...props}
  >
    <div className="flex items-center gap-1">
      {Icon && typeof Icon === 'function' ? <Icon /> : Icon}
      <span className="text-lg font-bold tracking-tight sm:text-xl">
        {title}
      </span>
    </div>
    {subTitle && <div className="mt-2 overflow-y-auto">{subTitle}</div>}
  </div>
));
Alert.displayName = 'Alert';
