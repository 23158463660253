import { createFileRoute } from '@tanstack/react-router';
import { useAccountDetails } from '@/hooks/use-account-details';
import { Billing } from '@/components/billing';

export const Route = createFileRoute('/_auth/_basic-layout/account')({
  component: Account,
});

function Account() {
  const {
    account: { email },
    usage,
    // isOnTrial,
    usedEvents,
    hasActiveSubscription,
    hasSubscription,
    totalUsedEvents,
    isOnFreePlan,
    usageLimit,
    currentPlan,
    subExpiresAt,
    nextBillingAt,
    hasExceededPlan,
  } = useAccountDetails();

  // const activeSub = data?.subscription?.active;
  return (
    <div className="mx-auto flex w-full max-w-2xl flex-col gap-3">
      <Billing
        {...{
          usage,
          usedEvents,
          hasActiveSubscription,
          hasSubscription,
          totalUsedEvents,
          isOnFreePlan,
          usageLimit,
          currentPlan,
          subExpiresAt,
          nextBillingAt,
          email,
          hasExceededPlan,
        }}
      />
    </div>
  );
}
