import { createFileRoute, Outlet } from '@tanstack/react-router';
import { Topbar } from '@/components/nav/topbar';
import { useProjects } from '@/hooks/use-projects';

export const Route = createFileRoute('/_auth/_basic-layout')({
  component: Layout,
});

function Layout() {
  const { projectId } = useProjects();
  const fullMenu = projectId ? true : false;

  return (
    <div className="flex h-dvh text-clip">
      <div
        className="flex size-full flex-col justify-between text-clip"
        id="content-container"
      >
        <div className="min-h-16 shrink-0 border-b">
          <Topbar fullMenu={fullMenu} />
        </div>
        <div className="min-h-0 grow overflow-auto p-4 md:p-5">
          <Outlet />
        </div>
        <div className="flex h-9 shrink-0 grow-0 items-center justify-center border-t p-4 text-xs">
          © {new Date().getFullYear()} Logspot | All Rights Reserved
        </div>
      </div>
    </div>
  );
}
