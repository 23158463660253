import { sortBy } from 'lodash';
import { useQuery } from '@tanstack/react-query';
import { useProjects } from '@/hooks/use-projects';
import { useChannels } from '@/hooks/use-channels';
import { AnalyticsService } from '@/api';
import { ReportConfig } from '@/types';
import { dateRangeAtom } from '@/state/atoms';
import { useAtomValue } from 'jotai';
import { Step } from './step';

type GerPercentProps = {
  max: number;
  count: number;
  dropOff: boolean;
};

function getPercent({ max, count, dropOff }: GerPercentProps) {
  if (count > 0 && !dropOff) {
    return 1;
  }

  if (count > 0 && dropOff) {
    return count / max;
  }

  return 0;
}

export const FunnelReport = ({ config }: { config: ReportConfig }) => {
  const { projectId } = useProjects();
  if (!projectId) {
    return null;
  }
  const { channelId } = useChannels();
  const dateRange = useAtomValue(dateRangeAtom);
  const { steps } = config;

  const query = useQuery({
    queryKey: ['steps', projectId, JSON.stringify(steps), dateRange, channelId],
    queryFn: () =>
      AnalyticsService.getFunnels({
        projectId,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        steps,
        channelId,
      }),
    enabled: !!steps?.length,
  });

  const computedSteps = sortBy(query.data, (step) => step.order) ?? [];

  const max = computedSteps?.[0]?.count ?? 0;

  return (
    <div>
      {!!steps?.length && (
        <div className="flex w-full items-center gap-5 rounded-lg border border-border p-5 transition-all">
          {computedSteps.map((step) => (
            <div key={step.step.value} className="flex w-full flex-col">
              <label className="mb-4">
                <span className="font-semibold">{step.step.type}</span> (
                {step.step.value})
              </label>
              <Step
                count={step.count}
                percent={getPercent({
                  max,
                  count: step.count,
                  dropOff: step.drop_off,
                })}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};
