import { useState } from 'react';
import { Code } from './code';

export const HiddenKey = ({ code }: { code: string }) => {
  const [hidden, setHidden] = useState(true);

  return (
    <div className="relative" onClick={() => setHidden(false)}>
      <button
        className={`${
          hidden ? '' : 'hidden'
        } absolute left-1/2 top-1/2 z-20 -translate-x-1/2 -translate-y-1/2 font-bold text-white`}
      >
        Reveal
      </button>

      <Code
        className={`p-1 ${hidden ? 'cursor-pointer blur-sm' : 'blur-none'}`}
        clipboard={false}
        code={code}
      />
    </div>
  );
};
