import {
  ChevronDownIcon,
  ChevronUpIcon,
  TrashIcon,
} from '@heroicons/react/24/outline';
import { Button } from '@/components/ui/button';
import { PageHeader } from '@/components/page-header';
import { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { Filter } from '@/types';

type SavedQueriesProps = {
  queries: { id: string; name: string; filters: Filter[] }[];
  onSelect: (query: { id: string; name: string; filters: Filter[] }) => void;
  onDelete: (query: { id: string; name: string; filters: Filter[] }) => void;
};

export const SavedQueries = ({
  queries,
  onSelect,
  onDelete,
}: SavedQueriesProps) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <div className="flex items-center gap-2">
        <PageHeader separator={false} title="Saved Queries" />
        <Button
          className="md:hidden"
          iconLeft={open ? <ChevronUpIcon /> : <ChevronDownIcon />}
          variant="ghost"
          onClick={() => setOpen(!open)}
        />
      </div>
      <div className={`${open ? 'flex flex-col' : 'hidden'} md:flex`}>
        {!queries.length && <p>You don&apos;t have any saved queries</p>}
        <div className="mt-2 flex flex-col gap-2">
          {queries.map((query) => (
            <div key={query.id} className="flex items-center gap-2">
              <span
                className="cursor-pointer hover:text-primary hover:underline focus:text-primary focus:underline"
                onClick={() => {
                  onSelect(query);
                }}
              >
                {query.name}
              </span>
              <Button
                iconLeft={<TrashIcon />}
                variant="ghost"
                onClick={() => onDelete(query)}
              ></Button>
            </div>
          ))}
        </div>
      </div>
    </>
  );
};
