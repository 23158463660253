import { Eye, HandPointing, Lightning, Link } from '@phosphor-icons/react';
import { PAGEVIEW, CLICK } from './event-types';

type EventIconProps = {
  name: string;
  metadata: {
    href?: string;
  };
};

export const EventIcon = ({ name, metadata }: EventIconProps) => {
  if (name === CLICK && metadata.href?.length) {
    return <Link className="text-primary" size={15} />;
  } else if (name === CLICK) {
    return <HandPointing className="text-primary" size={15} />;
  } else if (name === PAGEVIEW) {
    return <Eye className="text-primary" size={15} />;
  }
  return <Lightning className="text-primary" size={15} />;
};
