import _ from 'lodash';

export const Table = ({ prev, next, keys, data, onNext, onPrev }) => {
  return (
    <div className="rounded-lg border border-gray-200 shadow-sm">
      {/* <header className="px-4 py-5">
        <h2 className="font-semibold">
          Total <span className="font-medium text-gray-400">239</span>
        </h2>
      </header> */}
      <div className="overflow-auto">
        <table className="w-full table-auto">
          <thead className="border-y border-gray-200 bg-gray-50 text-xs font-semibold text-gray-500">
            <tr>
              {keys.map((key) => (
                <th
                  key={key.label}
                  className="px-2 py-3 text-xs uppercase leading-normal text-gray-500"
                >
                  <div className="text-left">{key.label}</div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody className="">
            {data.map((item) => (
              <tr key={item.id}>
                {keys.map((key) => (
                  <td key={key.label} className="whitespace-nowrap px-2 py-3">
                    <div>
                      {key.stringify
                        ? JSON.stringify(_.get(item, key.id, '{}'))
                        : _.get(item, key.id, '')}
                    </div>
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="my-4 flex w-full justify-center">
        <div className="flex gap-2">
          <button
            className={`${
              prev ? '' : 'cursor-not-allowed text-gray-200 hover:text-gray-200'
            } rounded-sm border border-border bg-background px-3 py-2 font-medium text-gray-500 shadow-sm hover:border-gray-200 hover:text-gray-600`}
            type="text"
            onClick={() => prev && onPrev()}
          >
            Prev page
          </button>
          <button
            className={`${
              next ? '' : 'cursor-not-allowed text-gray-200 hover:text-gray-200'
            } rounded-sm border border-border bg-background px-3 py-2 font-medium text-gray-500 shadow-sm hover:border-gray-200 hover:text-gray-600`}
            type="text"
            onClick={() => next && onNext()}
          >
            Next page
          </button>
        </div>
      </div>
    </div>
  );
};
