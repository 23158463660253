import { sortBy } from 'lodash';
import { fromUrl, parseDomain } from 'parse-domain';
import { useQuery } from '@tanstack/react-query';
import { useFilters } from '@/hooks/use-filters';
import { EventsService } from '@/api';
import { useProjects } from '@/hooks/use-projects';
import { AggregationWidget } from './aggregation-widget';
import { useChannels } from '@/hooks/use-channels';
import _ from 'lodash';
import { Widget, EventData } from '@/types';

const PAGEVIEW = 'Pageview';

function isValidHttpUrl(string: string) {
  let url;

  try {
    url = new URL(string);
  } catch {
    return false;
  }

  return url.protocol === 'http:' || url.protocol === 'https:';
}

function getDomain(url: string) {
  const parseResult = parseDomain(fromUrl(url));
  if (parseResult.type === 'LISTED') {
    const { domain, topLevelDomains } = parseResult;
    return `${domain}.${topLevelDomains.join('.')}`;
  }

  return false;
}

function getDisplayedUrl(url: string) {
  return url
    .replace(/(^\w+:|^)\/\//, '')
    .replaceAll('www.', '')
    .replace(/\/+$/, '');
}

function filterData(data: EventData[], totalPageViews: number) {
  const sum = _.reduce(data, (a, n) => a + parseInt(n.count.toString()), 0);
  const directVisits = totalPageViews - sum;
  const filtered = _.filter(
    [{ property: '(None)', count: directVisits } as EventData, ...(data ?? [])],
    (d) =>
      (d.property && isValidHttpUrl(d.property)) ||
      (d.property === '(None)' && Number(d.count) > 0),
  );

  return sortBy(filtered, (d) => -d.count);
}

function renderProperty(url: string) {
  if (url === '(None)') {
    return (
      <div className="relative z-10 flex items-center gap-2 pl-2 font-semibold">
        Direct / None
      </div>
    );
  }
  const domainUrl = getDomain(url);

  return (
    <div className="relative z-10 flex items-center gap-2 pl-2 font-semibold">
      {domainUrl && (
        <img
          alt="favicon"
          className="size-4"
          src={`http://www.google.com/s2/favicons?domain=${domainUrl}`}
        />
      )}
      <a
        className="cursor-pointer truncate hover:underline"
        href={url}
        rel="noreferrer"
        target="_blank"
      >
        {getDisplayedUrl(url)}
      </a>
    </div>
  );
}

// const PAGE_SIZE = 5;

export const ReferrerWidget = ({
  internal,
  dateRange,
  onDelete,
  params,
}: Widget) => {
  const { channelId } = useChannels();
  const { projectId } = useProjects();
  if (!projectId) {
    return null;
  }
  const { filterGroups } = useFilters();
  const { data: eventsData } = useQuery({
    queryKey: [
      'events-all:count',
      projectId,
      dateRange,
      filterGroups,
      channelId,
    ],
    queryFn: () =>
      EventsService.getAllEventCounts({
        projectId,
        interval: dateRange.interval,
        startDate: dateRange.startDate.getTime(),
        endDate: dateRange.endDate.getTime(),
        filterGroups,
        channelId,
      }),
  });
  const title = params.title;

  const totalPageViews =
    eventsData?.[PAGEVIEW]?.reduce(
      (a: number, n: EventData) => a + parseInt(n.count.toString()),
      0,
    ) ?? 0;

  return (
    <AggregationWidget
      dateRange={dateRange}
      filterData={(data) => filterData(data, totalPageViews)}
      internal={internal}
      params={{
        eventName: PAGEVIEW,
        property: '_referrer',
        title,
      }}
      renderProperty={renderProperty}
      onDelete={onDelete}
    />
  );
};
