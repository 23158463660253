import axios from 'axios';
import { endOfDay, startOfDay, subDays } from 'date-fns';
import { useEffect, useState } from 'react';
import { createFileRoute } from '@tanstack/react-router';
import LogoBlueBlack from '@/images/logspot-blue-black.svg?react';
import LogoBlueWhite from '@/images/logspot-blue-white.svg?react';
import { EventWidget } from '@/components/widgets/event-widget';
import { isReadable } from '@ctrl/tinycolor';

type DashboardData = {
  name: string;
  config: {
    whitelist: string[];
    description: string;
    primaryColor: string;
    textColor: string;
  };
  eventCounts: Record<string, { name: string; date: string; count: number }[]>;
};

export const Route = createFileRoute('/shares/$dashboardId')({
  component: OpenDashboard,
});

function OpenDashboard() {
  const { dashboardId } = Route.useParams();
  const [data, setData] = useState<DashboardData | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const now = endOfDay(new Date());
  const dateRange = {
    interval: '1 days',
    startDate: startOfDay(subDays(now, 30)),
    endDate: now,
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(
          `https://api.logspot.io/open-dashboard/${dashboardId}`,
        );

        // @ts-ignore
        const reduced = response.data.data.eventCounts.reduce((acc, entry) => {
          const dayMetrics = {
            name: entry.name,
            date: entry.date,
            count: entry.count,
          };

          if (acc[entry.name]) {
            acc[entry.name] = [...acc[entry.name], dayMetrics];
          } else {
            acc[entry.name] = [dayMetrics];
          }

          return acc;
        }, {});

        setData({
          name: response.data.data.name,
          config: response.data.data.config,
          eventCounts: reduced,
        });
      } catch (err) {
        setError(err as Error);
      }
    };

    fetchData();
  }, [dashboardId]);

  if (error) {
    return <div>Error loading data</div>;
  }

  if (!data) {
    return <div>Loading...</div>;
  }

  const {
    eventCounts,
    name,
    config: { whitelist, description, primaryColor, textColor },
  } = data;

  const darkMode = isReadable('#fff', primaryColor, {
    level: 'AA',
    size: 'large',
  });
  const root = window.document.documentElement;
  if (darkMode) {
    root.classList.add('dark');
  } else {
    root.classList.remove('dark');
  }

  return (
    <div
      className="text-foreground flex min-h-screen flex-col items-center antialiased"
      style={{ background: primaryColor }}
    >
      <div className="container mt-5 flex flex-col items-center px-4 text-center md:mt-10">
        <h1
          className="text-4xl font-bold sm:text-3xl md:text-5xl"
          style={{ color: textColor }}
        >
          {name}
        </h1>
        <h2 className="text-xl">{description}</h2>
      </div>
      <section className="container my-5 max-w-5xl grow px-4 md:mt-10">
        <div className="container grid grid-cols-1 flex-wrap gap-4 md:grid-cols-2 md:gap-6">
          {whitelist.map((name: string) => (
            <div key={name} className="items-center rounded-lg shadow-sm">
              <EventWidget
                backgroundColor={primaryColor}
                data={eventCounts[name]}
                dateRange={dateRange}
                name={name}
                // TODO: Change the color setup for the shareable dashboards.
                primaryColor={textColor}
              />
            </div>
          ))}
        </div>
      </section>
      <footer className="mt-5 flex w-full justify-center gap-2 py-5">
        <span className="font-medium">Powered by</span>
        <a
          className="flex w-24 text-2xl"
          href="https://logspot.io"
          rel="noopener noreferrer"
          target="_blank"
        >
          {darkMode ? (
            <LogoBlueWhite className="h-7 w-auto" />
          ) : (
            <LogoBlueBlack className="h-7 w-auto" />
          )}
        </a>
      </footer>
    </div>
  );
}
