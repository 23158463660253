type StepProps = {
  percent: number;
  count: number;
};

export const Step = ({ percent, count }: StepProps) => {
  const formatted = (Math.floor(percent * 100) / 100) * 100;

  return (
    <div className="flex h-full flex-1 flex-col items-center gap-3">
      <div className="flex h-52 w-full flex-col rounded shadow-sm">
        <div style={{ flex: `${1 - percent} 1 0%` }}></div>
        <div
          className="w-full rounded-lg border border-primary bg-primary/10"
          style={{ flex: `${percent} 1 0%` }}
        ></div>
      </div>
      <span className="flex w-full flex-col rounded-lg border border-border px-1 py-2 text-center font-bold shadow-sm">
        <span>{count || 0} users</span>
        <span className="text-muted-foreground">{formatted.toFixed(0)}%</span>
      </span>
    </div>
  );
};
