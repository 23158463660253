import { axiosClient, isDemo } from './axios-client';
import { ACCOUNT_DETAILS } from './demo-data';

export const AccountDetailsService = {
  getAccountDetails: async () => {
    if (isDemo()) {
      return ACCOUNT_DETAILS;
    }
    const {
      data: { data: details },
    } = await axiosClient.get(`/account-details`);

    return {
      ...details,
    };
  },
};
