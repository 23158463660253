import { scaleQuantile } from 'd3-scale';
import { max } from 'lodash';
import { memo, useState } from 'react';
import {
  ComposableMap,
  Geographies,
  Geography,
  ZoomableGroup,
} from 'react-simple-maps';
import features from './features.json';
import { Tooltip } from 'react-tooltip';
import { getCountry } from '@/utils/countries';

type Country = {
  id: string;
  iso2: string;
  count: number;
};

type WorldMapProps = {
  data: Country[];
  onSelect: (iso2: string) => void;
};

const COLORS = [
  '#9BCAFD',
  '#69B0FC',
  '#3796FB',
  '#057BFA',
  '#0463C8',
  '#034A96',
  '#023164',
];

const Map = ({ data, onSelect }: WorldMapProps) => {
  const [tooltip, setTooltip] = useState<string | undefined>();
  const colorScale = scaleQuantile()
    .domain([0, max(data.map((d) => Number(d.count)))])
    // @ts-ignore
    .range(COLORS);

  function handleHover(current: Country | undefined) {
    if (!current) return;
    setTooltip(`${getCountry(current.iso2)}: ${current?.count || 0} Pageviews`);
  }

  return (
    <div id="map-tooltip">
      <ComposableMap
        projection="geoMercator"
        projectionConfig={{
          rotate: [-10, -3, 0],
          scale: 110,
        }}
      >
        <ZoomableGroup center={[10, 40]} minZoom={0.7} zoom={1}>
          <Geographies geography={features}>
            {({ geographies }) =>
              geographies.map((geo) => {
                const cur = data.find((s) => s.id === geo.id);
                return (
                  <Geography
                    key={geo.rsmKey}
                    fill={cur ? colorScale(cur.count).toString() : '#cde5fe'}
                    geography={geo}
                    stroke={'#057BFA'}
                    style={{
                      default: { outline: 'none' },
                      hover: {
                        outline: 'none',
                        fill: '##057BFA',
                        cursor: 'pointer',
                      },
                      pressed: { outline: 'none' },
                    }}
                    onClick={() => {
                      if (cur) onSelect(cur.iso2);
                    }}
                    onMouseOut={() => setTooltip('')}
                    onMouseOver={() => handleHover(cur)}
                  />
                );
              })
            }
          </Geographies>
        </ZoomableGroup>
      </ComposableMap>
      <Tooltip
        float
        anchorSelect="#map-tooltip"
        className="z-50 bg-black"
        content={tooltip}
        style={{ backgroundColor: '#000000', color: '#fff', fontSize: '14px' }}
      />
    </div>
  );
};

export const WorldMap = memo(Map);
