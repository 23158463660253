import { CircleNotch } from '@phosphor-icons/react';

export function Loader() {
  return (
    <div
      className="flex min-h-full grow items-center justify-center"
      data-testid="loader"
    >
      <CircleNotch className="size-12 animate-spin" />
    </div>
  );
}
