import { useForm } from 'react-hook-form';
import { useProjects } from '@/hooks/use-projects';
import { ReportsService } from '@/api';
import { toast } from '@/components/ui/use-toast';
import { ReportConfig } from '@/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useSteps } from '@/hooks/use-steps';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormLabel,
  FormItem,
  FormMessage,
} from '@/components/ui/form';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import { useNavigate } from '@tanstack/react-router';

type SaveReportModalProps = {
  config: ReportConfig;
};

const reportSchema = z.object({
  name: z
    .string({ required_error: 'Please enter a name for your funnel.' })
    .min(2, {
      message:
        'Please enter a name for your funnel that is at least two characters.',
    }),
});

export const SaveReportModal = ({ config }: SaveReportModalProps) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { clearSteps } = useSteps();
  const { projectId } = useProjects();
  if (!projectId) {
    return null;
  }

  const form = useForm<z.infer<typeof reportSchema>>({
    resolver: zodResolver(reportSchema),
    defaultValues: {
      name: '',
    },
  });

  const { mutate } = useMutation({
    mutationFn: async (values: { name: string }) => {
      return ReportsService.saveReport({
        projectId,
        name: values.name,
        config,
      });
    },
    onSuccess: () => {
      toast({ title: 'Report Saved' });
      queryClient.invalidateQueries({
        queryKey: ['reports', projectId],
      });
      clearSteps();
      form.reset();
    },
    onError: () => {
      toast({
        title: 'Failed to Save Report',
        description: 'Please try again.',
        variant: 'destructive',
      });
    },
  });

  const onSubmit = (values: z.infer<typeof reportSchema>) => {
    mutate(values);
    navigate({ to: '/reports' });
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button>Save Report</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Save Report</DialogTitle>
          <DialogDescription>
            Ready to save your new report? Give it a name and click save.
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form className="space-y-5" onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Report Name</FormLabel>
                  <FormControl>
                    <Input id="name" {...field} placeholder="Enter a Name..." />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <DialogFooter>
              <DialogClose asChild>
                <Button type="button" variant="outline">
                  Close
                </Button>
              </DialogClose>
              <DialogClose asChild>
                <Button type="submit">Add Report</Button>
              </DialogClose>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
