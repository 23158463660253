// import React, { useState } from "react";
import { useFieldArray } from 'react-hook-form';
import { Button } from '@/components/ui/button';
import { FilterGroup } from './filters-group';
import { defaultValues } from '@/routes/_auth/_side-layout/search';

export const Filters = ({ control, register, watch, reset }) => {
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'filterGroups',
  });
  //   const [groups, setGroups] = useState([[{}]]);
  return (
    <div className="flex flex-col items-start gap-3">
      <div className="flex w-full flex-col items-start">
        {fields.map((item, index) => (
          <FilterGroup
            key={index}
            nestIndex={index}
            watch={watch}
            {...{ control, register }}
            onRemove={() => remove(index)}
          />
        ))}
      </div>
      <div className="flex gap-2">
        <Button
          type="button"
          onClick={() =>
            append({
              nestedArray: [{}],
            })
          }
        >
          Add Filter Group
        </Button>
        <Button type="button" onClick={() => reset(defaultValues)}>
          Reset
        </Button>
      </div>
    </div>
  );
};
