import {
  ChartLine,
  Gauge,
  Gear,
  Lightning,
  Rocket,
  Users,
} from '@phosphor-icons/react';

export const navLinks = [
  {
    title: 'Dashboard',
    icon: Gauge,
    to: '/',
  },
  {
    title: 'Events',
    icon: Lightning,
    to: '/events',
  },
  {
    title: 'Users',
    icon: Users,
    to: '/users',
  },
  {
    title: 'Reports',
    icon: ChartLine,
    to: '/reports',
  },
  {
    title: 'Settings',
    icon: Gear,
    to: '/settings',
  },
];

export const basicNavLinks = [
  {
    title: 'Onboarding',
    icon: Rocket,
    to: '/onboarding',
  },
  {
    title: 'Account',
    icon: Gear,
    to: '/account',
  },
];
