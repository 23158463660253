import { useAccountDetails } from '@/hooks/use-account-details';
import { Avatar, AvatarFallback } from '@/components/ui/avatar';
import { ComputerTower, Moon, Sun, User } from '@phosphor-icons/react';
import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuPortal,
  DropdownMenuSeparator,
  DropdownMenuSub,
  DropdownMenuSubContent,
  DropdownMenuSubTrigger,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Link } from '@tanstack/react-router';
import { AuthService } from '@/api';
import { useQueryClient } from '@tanstack/react-query';
import { themeAtom } from '@/state/atoms';
import { useAtom } from 'jotai';

const fullNavLinks = [
  {
    to: '/playground',
    text: 'Playground',
  },
  {
    to: '/account',
    text: 'Account',
  },
];

const basicNavLinks = [
  {
    to: '/account',
    text: 'Account',
  },
];

export function TopbarUser({ fullMenu = true }) {
  const queryClient = useQueryClient();
  const { account } = useAccountDetails();
  const avatarItem = () => {
    const emailInitial = account?.email?.substring(0, 1)?.toUpperCase();
    return emailInitial ? emailInitial : <User className="size-6" />;
  };
  const navLinks = fullMenu ? fullNavLinks : basicNavLinks;
  const [theme, setTheme] = useAtom(themeAtom);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <Avatar>
          <AvatarFallback>{avatarItem()}</AvatarFallback>
        </Avatar>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end" sideOffset={24}>
        <DropdownMenuLabel>My Account</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {navLinks.map((link, index) => (
          <DropdownMenuItem key={index}>
            <Link to={link.to}>{link.text}</Link>
          </DropdownMenuItem>
        ))}
        <DropdownMenuItem>
          <a
            href="https://developers.logspot.io"
            rel="noopener noreferrer"
            target="_blank"
          >
            Docs
          </a>
        </DropdownMenuItem>
        <DropdownMenuSub>
          <DropdownMenuSubTrigger>Theme</DropdownMenuSubTrigger>
          <DropdownMenuPortal>
            <DropdownMenuSubContent>
              <DropdownMenuCheckboxItem
                checked={theme === 'system'}
                onSelect={() => setTheme('system')}
              >
                <div className="flex items-center gap-2">
                  <ComputerTower />
                  System
                </div>
              </DropdownMenuCheckboxItem>
              <DropdownMenuCheckboxItem
                checked={theme === 'dark'}
                onSelect={() => setTheme('dark')}
              >
                <div className="flex items-center gap-2">
                  <Moon />
                  Dark
                </div>
              </DropdownMenuCheckboxItem>
              <DropdownMenuCheckboxItem
                checked={theme === 'light'}
                onSelect={() => setTheme('light')}
              >
                <div className="flex items-center gap-2">
                  <Sun />
                  Light
                </div>
              </DropdownMenuCheckboxItem>
            </DropdownMenuSubContent>
          </DropdownMenuPortal>
        </DropdownMenuSub>
        <DropdownMenuItem
          onClick={() => {
            AuthService.logout();
            queryClient.clear();
          }}
        >
          Logout
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
