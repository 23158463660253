type DisplayTextProps = {
  name: string;
  metadata: {
    href?: string;
    text?: string;
    tag?: string;
    className?: string;
  };
};

function truncate(input: string) {
  if (input.length > 30) {
    return input.substring(0, 30) + '...';
  }
  return input;
}

const PAGEVIEW = 'Pageview';
const CLICK = 'Click';

export const DisplayText = ({ name, metadata }: DisplayTextProps) => {
  if (name === CLICK && metadata.href?.length) {
    return (
      <a
        className="flex-1 truncate font-bold hover:underline"
        href={metadata.href}
        rel="noreferrer"
        target="_blank"
      >
        {metadata.text
          ? truncate(metadata.text)
          : truncate(`${metadata.tag?.toLowerCase()}.${metadata.className}`)}
      </a>
    );
  } else if (name === CLICK) {
    return (
      <span className="flex-1 truncate font-bold">
        {metadata.text
          ? truncate(metadata.text)
          : truncate(`${metadata.tag?.toLowerCase()}.${metadata.className}`)}
      </span>
    );
  } else if (name === PAGEVIEW) {
    return null;
  }
  return <span className="font-bold">{name}</span>;
};
