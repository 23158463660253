import { useSuspenseQuery } from '@tanstack/react-query';
import { Input } from '@/components/ui/input';
import { useProjects } from '@/hooks/use-projects';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';
import { useForm } from 'react-hook-form';
import { eventNamesOptions } from '@/hooks/query-options';

type FormValues = {
  widgetType: string;
  title: string;
  step1: string;
  step2: string;
  step3: string;
};

export const FunnelForm = ({
  form,
}: {
  form: ReturnType<typeof useForm<FormValues>>;
}) => {
  const { projectId } = useProjects();
  if (!projectId) {
    return null;
  }

  const { data } = useSuspenseQuery(eventNamesOptions({ projectId }));

  return (
    <div className="space-y-5">
      <FormField
        control={form.control}
        name="title"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Title</FormLabel>
            <FormControl>
              <Input id="title" {...field} placeholder="Enter a Title..." />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="step1"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Step 1</FormLabel>
            <Select defaultValue={field.value} onValueChange={field.onChange}>
              <FormControl>
                <SelectTrigger>
                  <SelectValue placeholder="Select Step 1" />
                </SelectTrigger>
              </FormControl>
              <SelectContent>
                {data?.map((event: { name: string }) => (
                  <SelectItem key={event.name} value={event.name}>
                    {event.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="step2"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Step 2</FormLabel>
            <Select defaultValue={field.value} onValueChange={field.onChange}>
              <FormControl>
                <SelectTrigger>
                  <SelectValue placeholder="Select Step 2" />
                </SelectTrigger>
              </FormControl>
              <SelectContent>
                {data
                  ?.filter(
                    (event: { name: string }) => event.name !== 'Pageview',
                  )
                  .map((event: { name: string }) => (
                    <SelectItem key={event.name} value={event.name}>
                      {event.name}
                    </SelectItem>
                  ))}
              </SelectContent>
            </Select>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="step3"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Step 3</FormLabel>
            <Select defaultValue={field.value} onValueChange={field.onChange}>
              <FormControl>
                <SelectTrigger>
                  <SelectValue placeholder="Select Step 3" />
                </SelectTrigger>
              </FormControl>
              <SelectContent>
                {data
                  ?.filter(
                    (event: { name: string }) => event.name !== 'Pageview',
                  )
                  .map((event: { name: string }) => (
                    <SelectItem key={event.name} value={event.name}>
                      {event.name}
                    </SelectItem>
                  ))}
              </SelectContent>
            </Select>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
};
