import { filterDialogAtom } from '@/state/atoms';
import { Field } from '@/types';
import { useAtom } from 'jotai';
import { useCallback } from 'react';

export const FIELDS_DATA: Field[] = [
  {
    field: 'location_country',
    label: 'Country',
    description: "Filter by the user's country",
  },
  {
    field: 'location_city',
    label: 'City',
    description: "Filter by the user's city",
  },
  {
    field: 'location_region',
    label: 'Region',
    description: "Filter by the user's region",
  },
  {
    field: 'location_lon',
    label: 'Longitude',
    description: "Filter by the user's longitude",
  },
  {
    field: 'location_lat',
    label: 'Latitude',
    description: "Filter by the user's latitude",
  },
  {
    field: 'device',
    label: 'Device',
    description: "Filter by the user's device",
  },
  {
    field: 'os',
    label: 'Operating System',
    description: "Filter by the user's operating system",
  },
  {
    field: 'browser',
    label: 'Browser',
    description: "Filter by the user's browser",
  },
  {
    field: 'screen',
    label: 'Screen',
    description: "Filter by the user's screen resolution",
  },
  {
    field: 'language',
    label: 'Language',
    description: "Filter by the user's language",
  },
  {
    field: 'referrer',
    label: 'Referrer',
    description: "Filter by the user's referrer URL",
  },
  {
    field: 'url',
    label: 'URL',
    description: "Filter by the user's visited URL",
  },
  {
    field: 'hostname',
    label: 'Hostname',
    description: "Filter by the user's visited hostname",
  },
  {
    field: 'user_id',
    label: 'User ID',
    description: "Filter by the user's ID",
  },
];

export const useFilterDialog = () => {
  const [dialog, setDialog] = useAtom(filterDialogAtom);

  const setSearch = useCallback(
    (search: string) => {
      setDialog((prev) => ({ ...prev, search }));
    },
    [setDialog],
  );

  const setSearchValue = useCallback(
    (searchValue: string) => {
      setDialog((prev) => ({ ...prev, searchValue }));
    },
    [setDialog],
  );

  const setCurrentTab = useCallback(
    (currentTab: string) => {
      setDialog((prev) => ({ ...prev, currentTab }));
    },
    [setDialog],
  );

  const setFieldsData = useCallback(
    (fieldsData: Field[]) => {
      setDialog((prev) => ({ ...prev, fieldsData }));
    },
    [setDialog],
  );

  const setFilteredItems = useCallback(
    (filteredItems: Field[]) => {
      setDialog((prev) => ({ ...prev, filteredItems }));
    },
    [setDialog],
  );

  const setSelectedField = useCallback(
    (selectedField: Field) => {
      setDialog((prev) => ({ ...prev, selectedField }));
    },
    [setDialog],
  );

  const setSelectedOperator = useCallback(
    (selectedOperator: string) => {
      setDialog((prev) => ({ ...prev, selectedOperator }));
    },
    [setDialog],
  );

  const setSelectedValue = useCallback(
    (selectedValue: string) => {
      setDialog((prev) => ({ ...prev, selectedValue }));
    },
    [setDialog],
  );

  const reset = useCallback(() => {
    setDialog((prev) => ({
      ...prev,
      currentTab: 'fields',
      search: '',
      searchValue: '',
      selectedField: null,
      selectedOperator: null,
      selectedValue: null,
    }));
  }, [setDialog]);

  return {
    ...dialog,
    setSearch,
    setSearchValue,
    setCurrentTab,
    setFieldsData,
    setFilteredItems,
    setSelectedField,
    setSelectedOperator,
    setSelectedValue,
    reset,
  };
};
