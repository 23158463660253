import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
} from '@/components/ui/dropdown-menu';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormLabel,
  FormItem,
  FormMessage,
} from '@/components/ui/form';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { CaretDown, Plus } from '@phosphor-icons/react';
import { Channel } from '@/types';
import { useForm } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { toast } from '@/components/ui/use-toast';
import { useProjects } from '@/hooks/use-projects';
import { ChannelsService } from '@/api';
import { useChannels } from '@/hooks/use-channels';

const channelSchema = z.object({
  name: z
    .string({ required_error: 'Project name is required.' })
    .min(1, 'Project name is required.'),
});

export function ChannelDropdown({ type = 'text' }) {
  const queryClient = useQueryClient();
  const { projectId } = useProjects();
  const { channel, channels, setChannelId } = useChannels();

  const form = useForm<z.infer<typeof channelSchema>>({
    resolver: zodResolver(channelSchema),
    defaultValues: {
      name: '',
    },
  });

  if (!projectId || !channel || !channels) {
    return null;
  }

  const onSubmit = async (values: z.infer<typeof channelSchema>) => {
    try {
      const { channelId } = await ChannelsService.createChannel({
        projectId,
        channelName: values?.name,
      });

      if (channelId) {
        queryClient.invalidateQueries({
          queryKey: ['channels', projectId],
        });
        setChannelId(channelId);
        form.reset();
      }
    } catch {
      toast({
        title: 'Could Not Add New Channel',
        description: 'Please try again.',
        variant: 'destructive',
      });
    }
  };

  return (
    <Dialog>
      <DropdownMenu>
        {type === 'button' ? (
          <DropdownMenuTrigger
            asChild
            className="flex items-center gap-1 [&[data-state=open]>svg]:rotate-180"
          >
            <Button
              iconRight={
                <CaretDown className="transition-transform duration-200" />
              }
              variant="outline"
            >
              {channel.name}
            </Button>
          </DropdownMenuTrigger>
        ) : (
          <DropdownMenuTrigger className="flex items-center gap-1 hover:text-primary [&[data-state=open]>svg]:rotate-180">
            <>
              {channel.name}
              {<CaretDown className="transition-transform duration-200" />}
            </>
          </DropdownMenuTrigger>
        )}
        <DropdownMenuContent align="start">
          <DropdownMenuLabel>Channels</DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuItem key="view-all-projects">
            <div onClick={() => setChannelId(null)}>All Traffic</div>
          </DropdownMenuItem>
          {channels.map((channel: Channel) => (
            <DropdownMenuItem key={channel.channelId}>
              <div onClick={() => setChannelId(channel.channelId)}>
                {channel.name}
              </div>
            </DropdownMenuItem>
          ))}
          <DropdownMenuItem key="create-project">
            <DialogTrigger asChild>
              <div>
                <Plus className="mr-2 inline-block" />
                Add New Channel
              </div>
            </DialogTrigger>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <DialogContent className="sm:max-w-[480px]">
        <DialogHeader>
          <DialogTitle>Add New Channel</DialogTitle>
          <DialogDescription>
            Add your new channel here. Channels are used to group events
            together.
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form className="space-y-5" onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Channel Name</FormLabel>
                  <FormControl>
                    <Input id="name" {...field} placeholder="Enter a Name..." />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <DialogFooter>
              <DialogClose asChild>
                <Button type="submit">Add Channel</Button>
              </DialogClose>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
