import { formatDistanceStrict } from 'date-fns';
import _ from 'lodash';
import { ArrowDown, ArrowUp, FolderOpen } from '@phosphor-icons/react';
import { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useSuspenseInfiniteQuery } from '@tanstack/react-query';
import { DatePicker } from '@/components/date-picker';
import { Filters } from '@/components/filters';
import { useEventNames } from '@/hooks/use-event-names';
import { useChannels } from '@/hooks/use-channels';
import { FilterDialogButton } from '@/components/filter-dialog-button';
import { Button } from '@/components//ui/button';
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from '@/components/ui/select';
import { Event } from './event';
import { infiniteEventsOptions } from '@/hooks/query-options';
import { FilterGroup } from '@/types';

type EventFeedProps = {
  projectId: string;
  hideUserAvatar?: boolean;
  filterGroups?: FilterGroup[];
};

export const EventFeed = ({
  projectId,
  hideUserAvatar,
  filterGroups,
}: EventFeedProps) => {
  const { channelId } = useChannels();
  const [selectedEvent, setSelectedEvent] = useState<string | null>(null);
  const { data, fetchNextPage, hasNextPage, refetch, isFetchingNextPage } =
    useSuspenseInfiniteQuery(
      infiniteEventsOptions({
        projectId,
        channelId,
        filterGroups,
        selectedEvent,
      }),
    );

  const [displayAllMetadata, setDisplayAllMetadata] = useState(false);
  const names = useEventNames();

  useEffect(() => {
    setSelectedEvent(null);
    refetch();
  }, [projectId]);

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const events = data?.pages?.map((p: any) => p.events)?.flat() ?? [];

  return (
    <div>
      <div className="mt-5 flex flex-col items-start gap-2 md:flex-row md:items-center md:justify-between">
        <div className="flex items-center gap-2 md:w-auto">
          <div className="flex-1 md:flex-auto">
            <Select
              defaultValue={selectedEvent ?? 'All Events'}
              onValueChange={(value) =>
                setSelectedEvent(value === 'All Events' ? null : value)
              }
            >
              <SelectTrigger className="font-semibold">
                <SelectValue placeholder="All Events">
                  {selectedEvent ? selectedEvent : 'All Events'}
                </SelectValue>
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="All Events">All Events</SelectItem>
                {_.uniq(names).map((name) => (
                  <SelectItem key={name} value={name}>
                    {name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
          </div>
          <DatePicker />
          <FilterDialogButton />

          <div className="flex md:hidden">
            <Button
              variant="outline"
              onClick={() => setDisplayAllMetadata(!displayAllMetadata)}
            >
              {displayAllMetadata ? <ArrowUp /> : <ArrowDown />}
              {displayAllMetadata ? 'Collapse All' : 'Expand All'}
            </Button>
          </div>
        </div>

        <div className="hidden md:flex">
          <Button
            iconLeft={displayAllMetadata ? <ArrowUp /> : <ArrowDown />}
            variant="outline"
            onClick={() => setDisplayAllMetadata(!displayAllMetadata)}
          >
            {displayAllMetadata ? 'Collapse All' : 'Expand All'}
          </Button>
        </div>
      </div>
      <div className="mt-5">
        <Filters />
      </div>
      <div className="mt-5 flex flex-col gap-2">
        {events.map((e, index) => (
          <div key={index} className="relative">
            {index > 0 && (
              <span className="absolute left-1/2 top-[-5px] z-20 -translate-x-1/2 -translate-y-1/2 rounded-full border border-border bg-background px-2 py-1 text-center text-xs text-accent-foreground">
                {formatDistanceStrict(
                  new Date(events[index - 1].createdAt),
                  new Date(events[index].createdAt),
                )}
              </span>
            )}
            <Event
              key={e.id}
              open={displayAllMetadata}
              showUserAvatar={!hideUserAvatar}
              {...e}
            />
          </div>
        ))}
        {
          // eslint-disable-next-line @typescript-eslint/no-explicit-any
          !data?.pages?.some((page: any) => page.events.length > 0) && (
            <div className="flex flex-col items-center rounded bg-background p-10">
              <FolderOpen size={48} />
              <h2 className="mt-5 text-2xl font-bold">
                No Matching Events Found.
              </h2>
            </div>
          )
        }
      </div>
      {hasNextPage && (
        <div className="mt-5 flex w-full justify-center">
          <Button
            disabled={isFetchingNextPage}
            loading={isFetchingNextPage}
            variant="outline"
            onClick={() => fetchNextPage()}
          >
            Load More
          </Button>
        </div>
      )}
    </div>
  );
};
