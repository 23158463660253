import { Automation } from '@/types';
import { Robot } from './robot';
import { Code } from '../code';

const NOTIFICATION_EXAMPLE = `{
  "event": "New subscription",
  "message": "john@doe.com bought a $99 subscription",
  "metadata": {
    "userId": 123
  }
}`;

const NOTIFICATION_CLIENT_SIDE_EXAMPLE = `{
  "event": "New subscription",
  "message": "john@doe.com bought a $99 subscription",
  "notify": true,
  "metadata": {
    "userId": 123
  }
}`;

export const TelegramRobot = ({
  index,
  robot,
}: {
  index: number;
  robot: Automation;
}) => {
  const { eventName } = robot;
  return (
    <Robot
      Description={() => (
        <p className="leading-7">
          Sending Telegram messages on{' '}
          {!eventName ? (
            <span>
              all events with a{' '}
              <span className="rounded bg-code px-2 py-1 text-code-foreground dark:bg-gray-500">
                notify
              </span>{' '}
              flag
            </span>
          ) : (
            `${eventName} event`
          )}
        </p>
      )}
      id={index}
      robot={robot}
      RobotDetails={() => (
        <div className="mt-5 flex flex-col gap-3">
          <p>This robot sends Telegram messages.</p>

          {!eventName && (
            <p className="leading-7">
              Notifications are triggered on the client side by sending the
              event with a{' '}
              <span className="rounded bg-code px-2 py-1 text-code-foreground dark:bg-gray-500">
                notify
              </span>{' '}
              flag.
            </p>
          )}
          {eventName && (
            <p className="leading-7">
              Notifications are triggered via Logspot. We will send
              notifications for all{' '}
              <span className="rounded bg-code px-2 py-1 text-code-foreground dark:bg-gray-500">
                {eventName}
              </span>{' '}
              events.
            </p>
          )}
          <Code
            clipboard={false}
            code={
              !eventName
                ? NOTIFICATION_CLIENT_SIDE_EXAMPLE
                : NOTIFICATION_EXAMPLE
            }
          />
        </div>
      )}
      robotType="Telegram"
    />
  );
};
