import { createFileRoute, Link } from '@tanstack/react-router';

export const Route = createFileRoute('/_auth/_side-layout/payments/success')({
  component: PaymentsSuccess,
});

function PaymentsSuccess() {
  return (
    <>
      <h2 className="text-2xl font-bold">Payment Succeeded 🥳</h2>
      <Link to="/">Go Back Home</Link>
    </>
  );
}
