import LogoBlueBlack from '@/images/logspot-blue-black.svg?react';
import LogoBlueWhite from '@/images/logspot-blue-white.svg?react';
import Icon from '@/images/logspot-icon-blue.svg?react';
import { Link } from '@tanstack/react-router';
import { useAtomValue } from 'jotai';
import { themeAtom } from '@/state/atoms';

export function LinkLogo({
  isCollapsed = false,
  to = '/',
}: {
  isCollapsed?: boolean;
  to?: string;
}) {
  const theme = useAtomValue(themeAtom);
  return (
    <div className="text-xl font-bold">
      {isCollapsed ? (
        <Link to={to}>
          <Icon className="h-5 w-auto" />
        </Link>
      ) : (
        <Link to={to}>
          {theme === 'dark' ? (
            <LogoBlueWhite className="h-7 w-auto" />
          ) : (
            <LogoBlueBlack className="h-7 w-auto" />
          )}
        </Link>
      )}
    </div>
  );
}
