import type { Automation } from '@/types';
import { Robot } from './robot';
import { Label } from '../ui/label';
import { Code } from '../code';

export const SlackRobot = ({
  index,
  robot,
}: {
  index: number;
  robot: Automation;
}) => {
  const { eventName, metadata } = robot;
  return (
    <Robot
      Description={() => (
        <p>Sending Slack messages on each {eventName} event</p>
      )}
      id={index}
      robot={robot}
      RobotDetails={() => (
        <div className="mt-5 flex flex-col items-start gap-3">
          <p className="leading-7">
            This robot sends a Slack message on each {eventName} event
          </p>
          <div className="flex flex-col gap-3">
            <Label>Slack Webhook Url</Label>
            <Code clipboard={false} code={metadata.slackWebhook || ''} />
          </div>
          <div className="flex w-full flex-col gap-3">
            <Label>Message</Label>
            <Code clipboard={false} code={metadata.slackMessage || ''} />
          </div>
        </div>
      )}
      robotType="Slack"
    />
  );
};
