import { useState } from 'react';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormLabel,
  FormItem,
  FormMessage,
  FormDescription,
} from '@/components/ui/form';
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from '@/components/ui/select';
import { DialogClose, DialogFooter } from '@/components/ui/dialog';
import { Checkbox } from '@/components/ui/checkbox';
import { Textarea } from '@/components/ui/textarea';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { AutomationsService } from '@/api';
import { removeEmptyFields } from '@/utils/remove-empty-fields';
import { useQueryClient } from '@tanstack/react-query';

export const telegramSchema = z.object({
  automationType: z.string({ required_error: 'Type is required' }),
  chatId: z
    .string({ required_error: 'Chat ID is required' })
    .min(1, 'Chat ID is required'),
  eventName: z.string().optional(),
  message: z.string().optional(),
});

type TelegramFormProps = {
  projectId: string;
  events: { name: string }[];
};

export const TelegramForm = ({ projectId, events }: TelegramFormProps) => {
  const [allEvents, setAllEvents] = useState(true);
  const [clientMessage, setClientMessage] = useState(true);
  const queryClient = useQueryClient();

  const form = useForm<z.infer<typeof telegramSchema>>({
    resolver: zodResolver(telegramSchema),
    defaultValues: {
      automationType: 'telegram',
      chatId: '',
      eventName: '',
      message: '',
    },
  });

  const onSubmit = async (data: z.infer<typeof telegramSchema>) => {
    removeEmptyFields(data);
    try {
      const { chatId, eventName, message } = data;
      await AutomationsService.createTelegramAutomation({
        projectId,
        eventName,
        chatId: parseInt(chatId),
        message,
      });
      queryClient.invalidateQueries({
        queryKey: ['automations', projectId],
      });
      form.reset();
    } catch (err) {
      console.error(err);
      form.reset();
    }
  };

  return (
    <Form {...form}>
      <form className="space-y-5" onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="chatId"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Chat ID</FormLabel>
              <FormControl>
                <Input
                  id="chatId"
                  placeholder="4444"
                  {...field}
                  value={field.value || ''}
                />
              </FormControl>
              <FormDescription>
                Write to{' '}
                <a className="link " href="https://t.me/LogspotBot">
                  @LogspotBot
                </a>{' '}
                or add it to a Telegram group to get chat id
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="eventName"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="flex items-center gap-1">
                <Checkbox
                  checked={allEvents}
                  onCheckedChange={() => setAllEvents(!allEvents)}
                />
                Trigger via Notify Field
              </FormLabel>
              {!allEvents && (
                <Select
                  defaultValue={field.value}
                  onValueChange={field.onChange}
                >
                  <FormControl>
                    <SelectTrigger>
                      <SelectValue placeholder="Select an Event" />
                    </SelectTrigger>
                  </FormControl>
                  <SelectContent>
                    {events?.map((event: { name: string }) => (
                      <SelectItem key={event.name} value={event.name}>
                        {event.name}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              )}
              <FormDescription>
                {allEvents ? (
                  <span>
                    This robots sends message when the{' '}
                    <span className="bg-orange-100 font-bold">notify</span>{' '}
                    field is set to{' '}
                    <span className="bg-orange-100 font-bold">true</span>.
                  </span>
                ) : (
                  <span>This robots sends message on the above event</span>
                )}
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="eventName"
          render={({ field }) => (
            <FormItem>
              <FormLabel className="flex items-center gap-1">
                <Checkbox
                  checked={clientMessage}
                  onCheckedChange={() => setClientMessage(!clientMessage)}
                />
                <span className="font-bold">
                  Message Defined in the Event Body
                </span>
              </FormLabel>
              <FormControl>
                {!clientMessage && (
                  <Textarea
                    id="clientMessage"
                    {...field}
                    className="w-full"
                    placeholder="User subscribed to the X plan."
                    value={field.value || ''}
                  />
                )}
              </FormControl>
              <FormDescription>
                {clientMessage ? (
                  <span>
                    This robots sends message taken from the{' '}
                    <span className="bg-orange-100 font-bold">message</span>{' '}
                    field on each event
                  </span>
                ) : (
                  <span>This robots sends following message on each event</span>
                )}
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <DialogFooter>
          <DialogClose asChild>
            <Button type="submit">Add</Button>
          </DialogClose>
        </DialogFooter>
      </form>
    </Form>
  );
};
