import React, { useState } from 'react';
import axios from 'axios';
import { useForm } from 'react-hook-form';
import { useSuspenseQuery } from '@tanstack/react-query';
import { toast } from '@/components/ui/use-toast';
import { useProjects } from '@/hooks/use-projects';
import { ProjectSelect } from '@/components/project-select';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Button } from '@/components/ui/button';
import { Switch } from '@/components/ui/switch';
import {
  Form,
  FormControl,
  FormField,
  FormLabel,
  FormItem,
  FormMessage,
} from '@/components/ui/form';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { createFileRoute } from '@tanstack/react-router';
import { channelsOptions } from '@/hooks/query-options';

export const Route = createFileRoute('/_auth/_side-layout/playground')({
  component: Playground,
});

const TRACK_URL: string = import.meta.env.VITE_APP_TRACK_URL;

const playgroundSchema = z.object({
  name: z
    .string({ required_error: 'Event name is required.' })
    .min(1, 'Event name is required.'),
  channel: z.string().optional(),
  user_id: z
    .string({ required_error: 'User email is required' })
    .email()
    .min(1, 'User email is required'),
  message: z
    .string({ required_error: 'Message is required' })
    .min(1, 'Message is required'),
  metadata: z.string().optional(),
  notify: z.boolean().optional(),
});

export function Playground() {
  const { projectId, project, projects } = useProjects();
  if (!projectId || !project || !projects) {
    return null;
  }
  const { data: channels } = useSuspenseQuery(channelsOptions({ projectId }));

  const secretKey = project?.secretKey;

  const form = useForm<z.infer<typeof playgroundSchema>>({
    resolver: zodResolver(playgroundSchema),
    defaultValues: {
      name: 'User Subscribed',
      user_id: 'john@doe.com',
      message: 'User john@doe.com signed up 🥳',
      metadata: `{ "plan": "$99.99" }`,
      notify: false,
    },
  });

  const onSubmit = async (values: z.infer<typeof playgroundSchema>) => {
    try {
      await axios.post(
        TRACK_URL,
        { ...values, metadata: JSON.parse(values?.metadata || '{}') },
        {
          headers: { 'x-logspot-sk': `sk_${secretKey}` },
        },
      );
      toast({ title: 'Event Sent 🥳' });
    } catch (err) {
      console.log(err);
      toast({
        title: 'Could Not Send the Event',
        description: 'Please try again.',
        variant: 'destructive',
      });
    }
  };

  return (
    <div className="flex w-full max-w-xl flex-col gap-5 p-8 ">
      <h1 className="text-center text-4xl font-extrabold">Playground</h1>
      {projectId && projects.length > 1 && (
        <div className="flex flex-col">
          <Label className="mb-3">Select Project</Label>
          <ProjectSelect />
        </div>
      )}
      <Form {...form}>
        <form className="space-y-5" onSubmit={form.handleSubmit(onSubmit)}>
          <FormField
            control={form.control}
            name="name"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Event Name</FormLabel>
                <FormControl>
                  <Input
                    id="name"
                    {...field}
                    placeholder="Enter an Event Name"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="channel"
            render={({ field }) => {
              const [isCustom, setIsCustom] = useState(false);
              const [customValue, setCustomValue] = useState('');

              const handleSelectChange = (value: string) => {
                setIsCustom(value === 'custom');
                if (value !== 'custom') {
                  field.onChange(value);
                }
              };

              const handleCustomInputChange = (
                event: React.ChangeEvent<HTMLInputElement>,
              ) => {
                setCustomValue(event.target.value);
                field.onChange(event.target.value);
              };
              return (
                <FormItem>
                  <FormLabel>Channel</FormLabel>
                  <Select
                    defaultValue={isCustom ? 'custom' : field.value}
                    onValueChange={handleSelectChange}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Enter a Channel" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {channels?.map((channel: { name: string }) => (
                        <SelectItem key={channel.name} value={channel.name}>
                          {channel.name}
                        </SelectItem>
                      ))}
                      <SelectItem value="custom">Custom</SelectItem>
                    </SelectContent>
                  </Select>
                  {isCustom && (
                    <Input
                      id="customProperty"
                      value={customValue}
                      onChange={handleCustomInputChange}
                    />
                  )}
                  <FormMessage />
                </FormItem>
              );
            }}
          />
          <FormField
            control={form.control}
            name="user_id"
            render={({ field }) => (
              <FormItem>
                <FormLabel>User ID</FormLabel>
                <FormControl>
                  <Input
                    id="user_id"
                    {...field}
                    placeholder="test@logspot.io"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="message"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Message</FormLabel>
                <FormControl>
                  <Input
                    id="message"
                    {...field}
                    placeholder="User test@logspot.io subscribed"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="metadata"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Metadata</FormLabel>
                <FormControl>
                  <Input
                    id="metadata"
                    {...field}
                    placeholder='{"plan": "$99.99"}'
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <FormField
            control={form.control}
            name="notify"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Notify</FormLabel>
                <FormControl>
                  <Switch
                    aria-readonly
                    checked={field.value}
                    className="block"
                    name="notify"
                    onCheckedChange={field.onChange}
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button className="w-full" type="submit">
            Send Event
          </Button>
        </form>
      </Form>
    </div>
  );
}
