import { useSuspenseQuery } from '@tanstack/react-query';
import { useProjects } from '@/hooks/use-projects';
import { useChannels } from './use-channels';
import { eventNamesOptions } from './query-options';

type EventName = {
  name: string;
};

export const useEventNames = () => {
  const { channelId } = useChannels();
  const { projectId } = useProjects();
  if (!projectId) {
    return null;
  }

  // const { data, isLoading } = useQuery(
  const { data } = useSuspenseQuery(
    eventNamesOptions({ projectId, channelId }),
  );

  return data?.map((d: EventName) => d.name) ?? [];
};
