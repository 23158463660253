import _ from 'lodash';
import { UserSwitch } from '@phosphor-icons/react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useChannels } from '@/hooks/use-channels';
import { useFilters } from '@/hooks/use-filters';
import { useProjects } from '@/hooks/use-projects';
import { LineChart } from '@/components/charts/line-chart';
import { Widget } from './widget';
import { LineChartData, Widget as WidgetProps } from '@/types';
import { retentionOptions } from '@/hooks/query-options';

// export const RetentionWidget = ({ internal, onDelete }) => {
export const RetentionWidget = ({ onDelete }: WidgetProps) => {
  const { channelId } = useChannels();
  const { filterGroups, filters } = useFilters();
  const days = 30;
  const { projectId } = useProjects();
  if (!projectId) {
    return null;
  }

  // const { isLoading, error, data } = useQuery(
  const { data } = useSuspenseQuery(
    retentionOptions({ projectId, days, channelId, filterGroups }),
  );

  if (filters.length) {
    // TODO: support filtered retention
    return null;
  }

  const grouped = _.groupBy(data, (d) => d.period);
  const pairs = _.toPairs(grouped);

  const mapped = pairs.map((p) => {
    const retained =
      p[1]?.reduce((acc, next) => acc + next.retained_users, 0) / p[1].length;
    const newUsers =
      p[1]?.reduce((acc, next) => acc + next.new_users, 0) / p[1].length;
    return {
      day: parseInt(p[0]),
      retained,
      newUsers,
      retention: retained / newUsers,
    };
  });

  const rangeArray = _.range(1, parseInt(days.toString()) + 1);

  const mappedRangeArray = rangeArray.map((i) => {
    const found = _.find(mapped, (d) => d.day === i);
    return {
      day: `Day ${found?.day ?? i}`,
      retained: found?.retained ?? 0,
      newUsers: found?.newUsers ?? 0,
      retention:
        found && found.newUsers > 0 ? found.retained / found.newUsers : 0,
    };
  });

  return (
    <Widget
      Header={() => (
        <div className="flex items-center justify-between">
          <span className="flex items-center gap-2 text-xl font-medium">
            <UserSwitch className="fill-primary" />
            <span className="font-semibold">User Retention</span>
          </span>
        </div>
      )}
      onDelete={() => onDelete && onDelete()}
    >
      <LineChart
        data={[
          { day: null, retention: 1, retained: null, newUsers: null },
          ...mappedRangeArray,
        ].map((d) => ({
          x: d.day,
          y: d.retention,
          newUsers: d.newUsers,
          retained: d.retained,
        }))}
        labelLeft={'Day 1'}
        labelRight={`Day ${days}`}
        renderTooltip={({ x, y, newUsers, retained }: LineChartData) => {
          if (!x) {
            return <span>Day 0 - 100%</span>;
          }
          return (
            <span>
              {x} - {(y * 100).toFixed(1)}% - {retained?.toFixed(1)}/
              {newUsers?.toFixed(1)}
            </span>
          );
        }}
      />
    </Widget>
  );
};
