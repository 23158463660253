import { Button } from '@/components/ui/button';
import { ErrorComponentProps, Link, useRouter } from '@tanstack/react-router';

export default function ErrorPage({ error, reset }: ErrorComponentProps) {
  const router = useRouter();
  console.error(error);
  return (
    <>
      <main className="grid min-h-full place-items-center px-6 py-24 sm:py-32 lg:px-8">
        <div className="text-center">
          <p className="text-base font-semibold text-primary">Error</p>
          <h1 className="mt-4 text-3xl font-bold tracking-tight sm:text-5xl">
            Something Went Wrong
          </h1>
          <p className="mt-6 text-base leading-7 text-muted-foreground">
            Sorry, we encountered an unexpected problem.
          </p>
          <div className="mt-10 flex items-center justify-center gap-x-6">
            <Link to="/">
              <Button
                onClick={() => {
                  // Reset the router error boundary
                  reset();
                  // Invalidate the route to reload the loader
                  router.invalidate();
                }}
              >
                Reload
              </Button>
            </Link>
            <a className="font-semibold" href="mailto:support@logspot.io">
              Contact Support <span aria-hidden="true">&rarr;</span>
            </a>
          </div>
        </div>
      </main>
    </>
  );
}
