import { Automation } from '@/types';
import { Robot } from './robot';
import { Label } from '../ui/label';
import { Code } from '../code';

export const WebhookRobot = ({
  index,
  robot,
}: {
  index: number;
  robot: Automation;
}) => {
  const { metadata, eventName } = robot;
  return (
    <Robot
      Description={() => (
        <p>Sending webhook notifications on {eventName} event</p>
      )}
      id={index}
      robot={robot}
      RobotDetails={() => (
        <div className="mt-5 flex w-full flex-col gap-3">
          <p className="leading-7">
            This robot sends webhook notifications on {eventName} event
          </p>
          <div className="flex w-full flex-col gap-2">
            <Label>Webhook Url</Label>
            <Code clipboard={false} code={metadata.webhookUrl || ''} />
          </div>
          <div className="flex flex-col gap-2">
            <Label>Body</Label>
            <Code clipboard={false} code={metadata.webhookBody || ''} />
          </div>
          <div className="flex flex-col gap-2">
            <Label>Headers</Label>
            <Code clipboard={false} code={metadata.webhookHeaders || ''} />
          </div>
        </div>
      )}
      robotType="Webhook"
    />
  );
};
