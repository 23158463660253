import { ReportConfig } from '@/types';
import { axiosClient, isDemo } from './axios-client';

type GetReportProps = {
  projectId: string;
};

type SaveReportProps = {
  projectId: string;
  name: string;
  description?: string;
  config: ReportConfig;
};

type DeleteReportProps = {
  reportId: string;
};

export const ReportsService = {
  getReports: async ({ projectId }: GetReportProps) => {
    if (isDemo()) {
      return [];
    }
    const {
      data: { data },
    } = await axiosClient.get(`/reports?projectId=${projectId}`);

    return data;
  },

  saveReport: async ({
    projectId,
    name,
    description,
    config,
  }: SaveReportProps) => {
    await axiosClient.post(`/reports`, {
      projectId,
      name,
      description,
      config,
    });
  },

  deleteReport: async ({ reportId }: DeleteReportProps) => {
    await axiosClient.delete(`/reports/${reportId}`);
  },
};
