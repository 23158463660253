import { useCallback } from 'react';
// import { useMutation, useQuery } from "react-query";
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useProjects } from '@/hooks/use-projects';
import { ChannelsService } from '@/api/channels-service';
import { Trash } from '@phosphor-icons/react';
import { Channel } from '@/types';
import { SectionHeader } from './section-header';
import { Card } from './ui/card';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormLabel,
  FormItem,
  FormMessage,
} from '@/components/ui/form';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Plus } from '@phosphor-icons/react';
import { toast } from '@/components/ui/use-toast';

const channelSchema = z.object({
  name: z
    .string({ required_error: 'Project name is required.' })
    .min(1, 'Project name is required.'),
});

export const Channels = () => {
  const queryClient = useQueryClient();
  const { projectId } = useProjects();
  if (!projectId) {
    return null;
  }

  const form = useForm<z.infer<typeof channelSchema>>({
    resolver: zodResolver(channelSchema),
    defaultValues: {
      name: '',
    },
  });

  const onSubmit = async (values: z.infer<typeof channelSchema>) => {
    try {
      const { channelId } = await ChannelsService.createChannel({
        projectId,
        channelName: values?.name,
      });

      if (channelId) {
        queryClient.invalidateQueries({
          queryKey: ['channels', projectId],
        });
        form.reset();
      }
    } catch {
      toast({
        title: 'Could Not Add New Channel',
        description: 'Please try again.',
        variant: 'destructive',
      });
    }
  };

  const channels = useQuery({
    queryKey: ['channels', projectId],
    queryFn: () => ChannelsService.getChannels({ projectId }),
  });
  const deleteChannel = useCallback(
    (channelId: string) =>
      ChannelsService.deleteChannel({ projectId, channelId }).then(() =>
        queryClient.invalidateQueries({
          queryKey: ['channels', projectId],
        }),
      ),
    [],
  );
  return (
    <div className="mt-6 space-y-6">
      <SectionHeader
        subTitle="Channels are used to group and filter events."
        title="Channels"
      />
      <Dialog>
        <DialogTrigger asChild>
          <Button iconLeft={<Plus />}>Add Channel</Button>
        </DialogTrigger>
        <DialogContent className="sm:max-w-[480px]">
          <DialogHeader>
            <DialogTitle>Add New Channel</DialogTitle>
            <DialogDescription>
              Add your new channel here. Channels are used to group events
              together.
            </DialogDescription>
          </DialogHeader>
          <Form {...form}>
            <form className="space-y-5" onSubmit={form.handleSubmit(onSubmit)}>
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Channel Name</FormLabel>
                    <FormControl>
                      <Input
                        id="name"
                        {...field}
                        placeholder="Enter a Name..."
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <DialogFooter>
                <DialogClose asChild>
                  <Button type="submit">Add Channel</Button>
                </DialogClose>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>
      <div className="flex max-w-fit flex-col items-stretch gap-3">
        {channels?.data?.map((channel: Channel) => (
          <Card
            key={channel.channelId}
            className="flex items-center justify-between gap-2 px-3 py-2"
          >
            <span>{channel.name}</span>{' '}
            <Trash
              className="cursor-pointer text-destructive"
              weight="bold"
              onClick={() =>
                channel.channelId && deleteChannel(channel.channelId)
              }
            />
          </Card>
        ))}
      </div>
    </div>
  );
};
