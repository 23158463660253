import * as React from 'react';
import { cn } from '@/utils/cn';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Building } from '@phosphor-icons/react';

interface OrgSwitcherProps {
  isCollapsed: boolean;
  organizations: {
    label: string;
    email: string;
    icon?: React.ReactNode;
  }[];
}

export function OrgSwitcher({ isCollapsed, organizations }: OrgSwitcherProps) {
  const [selectedOrganization, setSelectedOrganization] =
    React.useState<string>(organizations[0].email);

  return (
    <Select
      defaultValue={selectedOrganization}
      onValueChange={setSelectedOrganization}
    >
      <SelectTrigger
        aria-label="Select Account"
        className={cn(
          'flex items-center gap-2 text-base [&>span]:line-clamp-1 [&>span]:flex [&>span]:w-full [&>span]:items-center [&>span]:gap-1 [&>span]:truncate [&_svg]:h-4 [&_svg]:w-4 [&_svg]:shrink-0',
          isCollapsed &&
            'flex h-9 w-9 shrink-0 items-center justify-center p-0 [&>span]:w-auto [&>svg]:hidden',
        )}
      >
        <SelectValue placeholder="Select an Account">
          {organizations.find(
            (organization) => organization.email === selectedOrganization,
          )?.icon || <Building />}
          <span className={cn('ml-2 truncate', isCollapsed && 'hidden')}>
            {
              organizations.find(
                (organization) => organization.email === selectedOrganization,
              )?.label
            }
          </span>
        </SelectValue>
      </SelectTrigger>
      <SelectContent>
        {organizations.map((organization) => (
          <SelectItem key={organization.email} value={organization.email}>
            <div className="flex items-center gap-3 text-base [&_svg]:size-5 [&_svg]:shrink-0 [&_svg]:text-foreground">
              {organization.icon || <Building />}
              {organization.email}
            </div>
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}
