import { useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useProjects } from '@/hooks/use-projects';
import { useChannels } from '@/hooks/use-channels';
import { EmptyState } from '@/components/empty-state';
import { createFileRoute } from '@tanstack/react-router';
import { usersOptions } from '@/hooks/query-options';
import { UserItem } from '@/components/user-item';
import { Pagination } from '@/components/pagination';
import { PageHeader } from '@/components/page-header';

export const Route = createFileRoute('/_auth/_side-layout/users')({
  component: Users,
  loader: ({ context }) => {
    const projectId = context.projectId;
    const channelId = context.channelId;
    if (!projectId) {
      return;
    }
    context.queryClient.prefetchQuery(
      usersOptions({ projectId, page: 1, channelId }),
    );
  },
});

function Users() {
  const { channelId } = useChannels();
  const { projectId } = useProjects();
  if (!projectId) {
    return null;
  }
  const [page, setPage] = useState<number>(1);
  const { data } = useSuspenseQuery(
    usersOptions({ projectId, page, channelId }),
  );

  const users = data?.users ?? [];

  if (!users.length) {
    return (
      <div className="flex h-full items-center justify-center">
        <EmptyState
          launchState
          headText="We Haven't Seen Any User Activity Yet"
          subText="Add our code to your website to quickly start sending user events."
        />
      </div>
    );
  }

  return (
    <div className="mx-auto flex flex-col">
      <div className="mt-5 flex items-center justify-between md:mt-0">
        <PageHeader separator={false} title="Users" />
        <div>
          <h2 className="text-xl font-extrabold text-primary md:text-3xl">
            {data?.count}
          </h2>
        </div>
      </div>
      <div className="mt-10 flex flex-col gap-2">
        {users.map((user, index) => (
          <UserItem key={index} {...user} />
        ))}
      </div>
      <div className="mt-5">
        <Pagination {...{ page, setPage, count: data?.count ?? 0 }} />
      </div>
    </div>
  );
}
