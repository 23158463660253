import { Funnel, Trash } from '@phosphor-icons/react';
import { useProjects } from '@/hooks/use-projects';
import { ReportsService } from '@/api';
import { useQuery } from '@tanstack/react-query';
import { FunnelReport } from '@/components/reports/funnel-report';
import { useCallback } from 'react';
import { DatePicker } from '@/components/date-picker';
import { createFileRoute, Link } from '@tanstack/react-router';
import { reportsOptions } from '@/hooks/query-options';
import { ReportConfig } from '@/types';
import { PageHeader } from '@/components/page-header';
import { toast } from '@/components/ui/use-toast';

export const Route = createFileRoute('/_auth/_side-layout/reports')({
  component: Reports,
  beforeLoad: async ({ context }) => {
    const projectId = context.projectId;
    if (!projectId) {
      return;
    }
    context.queryClient.prefetchQuery(reportsOptions({ projectId }));
  },
});

type Report = {
  id: string;
  name: string;
  config: ReportConfig;
};

const FunnelCard = () => (
  <Link to="/reports/add-funnel-report">
    <div className="group flex w-full cursor-pointer items-center gap-5 rounded-lg border border-border p-5 transition-all hover:scale-105 hover:border-primary">
      <div className="rounded border p-5 group-hover:border-primary">
        <Funnel className="text-primary" size={30} />
      </div>
      <div className="flex flex-col gap-1">
        <h2 className="text-xl font-bold group-hover:text-primary">
          Add Funnel Report
        </h2>
        <p>Create a funnel to see how many users complete a series of steps.</p>
      </div>
    </div>
  </Link>
);

const CardGrid = ({ children }: { children?: React.ReactNode }) => (
  <div className="grid grid-cols-1 gap-5 md:grid-cols-3">{children}</div>
);

function Reports() {
  const { projectId } = useProjects();
  if (!projectId) {
    return null;
  }
  const reportsQuery = useQuery(reportsOptions({ projectId }));

  const deleteReport = useCallback(
    (reportId: string) => {
      return ReportsService.deleteReport({ reportId })
        .then(() => reportsQuery.refetch())
        .then(() => toast({ title: 'Report Deleted' }))
        .catch(() =>
          toast({
            title: 'Failed to Delete Report',
            description: 'Please try again.',
          }),
        );
    },
    [projectId],
  );

  return (
    <>
      <PageHeader separator={false} title="Reports" />
      <div className="mt-10">
        <CardGrid>
          <FunnelCard />
        </CardGrid>
      </div>
      <div className="mt-10">
        <DatePicker />
        {/* <h2 className="text-2xl font-bold">Saved reports</h2> */}
        <div className="mt-10 flex flex-col gap-10">
          {reportsQuery.data?.length === 0 && (
            <div>
              <p className="w-full rounded border border-border p-10 text-center text-2xl text-gray-500">
                No Reports Found.
              </p>
            </div>
          )}
          {reportsQuery.data?.map((report: Report) => (
            <div key={report.id} className="flex flex-col gap-2">
              <h2 className="flex items-center gap-2 text-xl font-semibold leading-none">
                <span>{report.name}</span>
                <Trash
                  className="cursor-pointer"
                  onClick={() => deleteReport(report.id)}
                />
              </h2>
              <div className="mt-5">
                {report.config.type === 'funnel' && (
                  <FunnelReport config={report.config} />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}
