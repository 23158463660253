import { toast } from '@/components/ui/use-toast';
import { useEventNames } from '@/hooks/use-event-names';
import { DauWidget } from '@/components/widgets/dau-widget';
import { EventWidget } from '@/components/widgets/event-widget';
import { WidgetWrapper } from '@/components/widgets/widget-wrapper';
import { DashboardService } from '@/api';
import _ from 'lodash';
import { useQueryClient } from '@tanstack/react-query';
import { Widget, DateRange, WidgetType, EventDataMap } from '@/types';

type WidgetGridProps = {
  dateRange: DateRange;
  eventData: EventDataMap;
  eventNames: string[];
  prevData: EventDataMap;
  prevDateRange: DateRange;
  projectId: string;
  channelId?: string | null;
  dashData: { widgets: Widget[] };
};

type PageviewWidget = {
  internal: boolean;
  type: WidgetType;
  params: {
    title: string;
    eventName?: string;
    property?: string;
  };
  channel: null;
};

const PAGEVIEW = 'Pageview';

const PAGEVIEW_WIDGETS: PageviewWidget[] = [
  {
    internal: true,
    type: 'AGGREGATION_BY_PROPERTY',
    params: {
      title: 'Top Pages',
      eventName: PAGEVIEW,
      property: '_url',
    },
    channel: null,
  },
  {
    internal: true,
    type: 'REFERRER',
    params: {
      title: 'Top Sources',
    },
    channel: null,
  },
  {
    internal: true,
    type: 'COUNTRY',
    params: {
      title: 'Countries',
    },
    channel: null,
  },
  {
    internal: true,
    type: 'AGGREGATION_BY_PROPERTY',
    params: {
      title: 'Cities',
      property: '_location_city',
      eventName: PAGEVIEW,
    },
    channel: null,
  },
  {
    internal: true,
    type: 'AGGREGATION_BY_PROPERTY',
    params: {
      title: 'Screens',
      property: '_screen',
      eventName: PAGEVIEW,
    },
    channel: null,
  },
  {
    internal: true,
    type: 'AGGREGATION_BY_PROPERTY',
    params: {
      title: 'Devices',
      property: '_device',
      eventName: PAGEVIEW,
    },
    channel: null,
  },
  {
    internal: true,
    type: 'AGGREGATION_BY_PROPERTY',
    params: {
      title: 'Browsers',
      property: '_browser',
      eventName: PAGEVIEW,
    },
    channel: null,
  },
  {
    internal: true,
    type: 'AGGREGATION_BY_PROPERTY',
    params: {
      title: 'Operating Systems',
      property: '_os',
      eventName: PAGEVIEW,
    },
    channel: null,
  },
  {
    internal: true,
    type: 'CAMPAIGNS',
    params: {
      title: 'Campaigns',
    },
    channel: null,
  },
];

export const WidgetGrid = ({
  dateRange,
  eventData,
  eventNames,
  prevData,
  prevDateRange,
  projectId,
  channelId,
  dashData,
}: WidgetGridProps) => {
  const queryClient = useQueryClient();
  const names = useEventNames();
  const hasPageview = _.includes(names, PAGEVIEW);
  const shouldIncludePageviewWidgets = hasPageview;
  const widgets = dashData?.widgets;

  return (
    <div className="grid grid-cols-1 flex-wrap gap-4 xl:grid-cols-2 xl:gap-6 2xl:grid-cols-3">
      {hasPageview ? (
        <>
          <DauWidget dateRange={dateRange} />
          <EventWidget
            data={eventData[PAGEVIEW]}
            dateRange={dateRange}
            hide={!eventData[PAGEVIEW]}
            iconType="view"
            name="Pageviews"
            prevData={prevData[PAGEVIEW]}
          />
          {PAGEVIEW_WIDGETS.map((widget: PageviewWidget, index) => (
            <WidgetWrapper
              key={`${widget.type}_${index}`}
              dateRange={dateRange}
              internal={widget?.internal}
              params={widget?.params}
              prevDateRange={prevDateRange}
              type={widget?.type}
            />
          ))}
          {dashData?.widgets
            ?.filter(
              (w: Widget) => channelId == null || w.channel === channelId,
            )
            .map((widget: Widget, index: number) => (
              <WidgetWrapper
                key={`${widget.type}_${index}`}
                dateRange={dateRange}
                internal={widget?.internal}
                params={widget.params}
                prevDateRange={prevDateRange}
                type={widget.type}
                onDelete={() => {
                  if (widgets) {
                    delete widgets[index];
                    return DashboardService.updateWidgets({
                      projectId,
                      widgets: widgets.filter((widget: Widget) => !!widget),
                    })
                      .then(() =>
                        queryClient.invalidateQueries({
                          queryKey: ['dashboard-config'],
                        }),
                      )
                      .catch(() =>
                        toast({
                          title: 'Could Not Delete Widget',
                          description: 'Please try again.',
                          variant: 'destructive',
                        }),
                      );
                  }
                }}
              />
            ))}
          {eventNames
            .filter((n) => n !== PAGEVIEW)
            .map((name) => (
              <EventWidget
                key={name}
                data={eventData[name]}
                dateRange={dateRange}
                name={name}
                prevData={prevData[name]}
              />
            ))}
        </>
      ) : (
        <>
          <DauWidget dateRange={dateRange} />
          {[
            ...(dashData?.widgets ?? []),
            ...(shouldIncludePageviewWidgets ? PAGEVIEW_WIDGETS : []),
          ]
            ?.filter(
              (w: Widget | PageviewWidget) =>
                channelId === null || w.channel === channelId,
            )
            ?.map((widget, index) => (
              <WidgetWrapper
                key={index}
                dateRange={dateRange}
                internal={widget?.internal}
                params={widget.params}
                prevDateRange={prevDateRange}
                type={widget.type}
                onDelete={() => {
                  if (widget.internal) {
                    return;
                  }
                  if (widgets) {
                    delete widgets[index];
                    return DashboardService.updateWidgets({
                      projectId,
                      widgets: widgets.filter((widget: Widget) => !!widget),
                    })
                      .then(() =>
                        queryClient.invalidateQueries({
                          queryKey: ['dashboard-config'],
                        }),
                      )
                      .catch(() =>
                        toast({
                          title: 'Could Not Delete Widget',
                          description: 'Please try again.',
                          variant: 'destructive',
                        }),
                      );
                  }
                }}
              />
            ))}
          {eventNames.map((name) => (
            <EventWidget
              key={`${projectId}-${name}`}
              data={eventData[name]}
              dateRange={dateRange}
              name={name}
              prevData={prevData[name]}
            />
          ))}
        </>
      )}
    </div>
  );
};
