import { createFileRoute } from '@tanstack/react-router';
import logspot from '@logspot/web';
import { endOfDay, endOfHour, sub } from 'date-fns';
import { ArrowLeft, PlusCircle, Share } from '@phosphor-icons/react';
import { useState } from 'react';
import { useSuspenseQueries } from '@tanstack/react-query';
import { AddWidgetModal } from '@/components/widgets/add-widget-modal';
import { Button } from '@/components/ui/button';
import { DatePicker } from '@/components/date-picker';
import { Filters } from '@/components/filters';
import { useProjects } from '@/hooks/use-projects';
import { FilterDialogButton } from '@/components/filter-dialog-button';
import { useAccountDetails } from '@/hooks/use-account-details';
import { useFilters } from '@/hooks/use-filters';
import { useChannels } from '@/hooks/use-channels';
import { DashboardShare } from '@/components/dashboard-share';
import { DashboardNoEvents } from '@/components/dashboard-no-events';
import { WidgetGrid } from '@/components/widgets/widget-grid';
import { useAtomValue } from 'jotai';
import { dateRangeAtom } from '@/state/atoms';
import {
  allEventsOptions,
  allEventsPrevOptions,
  dashboardConfigOptions,
} from '@/hooks/query-options';

export const Route = createFileRoute('/_auth/_side-layout/')({
  component: Dashboard,
});

function Dashboard() {
  const { channelId } = useChannels();
  const { projectId, projectName } = useProjects();

  if (!projectId) {
    return null;
  }
  const { filterGroups } = useFilters();
  const { totalUsedEvents } = useAccountDetails();
  const dateRange = useAtomValue(dateRangeAtom);
  const [shareOpen, setShareOpen] = useState(false);
  const [addWidgetOpen, setAddWidgetOpen] = useState(false);
  const prevDateRange = {
    interval: dateRange.interval,
    startDate: sub(dateRange.startDate, dateRange.period || { days: 1 }),
    endDate: dateRange.interval.includes('days')
      ? endOfDay(dateRange.startDate)
      : endOfHour(dateRange.startDate),
  };

  const { data } = useSuspenseQueries({
    queries: [
      allEventsOptions({ projectId, dateRange, filterGroups, channelId }),
      allEventsPrevOptions({ projectId, dateRange, filterGroups, channelId }),
      dashboardConfigOptions({ projectId }),
    ],
    combine: (results) => {
      return {
        data: results.map((result) => result.data),
      };
    },
  });

  const [events, prevEvents, dashConfig] = data;

  const eventNames = events ? Object.keys(events) : [];

  if (totalUsedEvents === 0) {
    return <DashboardNoEvents projectName={projectName || 'Your Project.'} />;
  }
  return (
    <div className="mx-auto">
      <AddWidgetModal
        isOpen={addWidgetOpen}
        onClose={() => setAddWidgetOpen(false)}
      />
      <div className="flex justify-between">
        <div className="flex w-full flex-col items-start gap-2 md:w-auto md:flex-row md:items-center">
          <DatePicker />
          <div>
            <FilterDialogButton />
          </div>
        </div>
        <div className="flex flex-col items-end gap-2 md:flex-row md:items-center">
          <Button
            iconLeft={<PlusCircle />}
            variant="outline"
            onClick={() => {
              logspot.track({ event: 'Add Widget' });
              return setAddWidgetOpen(true);
            }}
          >
            Add Widget
          </Button>
          <Button
            iconLeft={shareOpen ? <ArrowLeft /> : <Share />}
            variant="outline"
            onClick={() => {
              logspot.track({ event: 'Dashboard Share Open' });
              return setShareOpen(!shareOpen);
            }}
          >
            {shareOpen ? 'Back' : 'Share'}
          </Button>
        </div>
      </div>
      <div className="mt-2 md:mt-5">
        <Filters />
      </div>
      <div className="mb-20 mt-5">
        {shareOpen ? (
          <DashboardShare data={dashConfig} projectId={projectId} />
        ) : (
          <WidgetGrid
            channelId={channelId}
            dashData={dashConfig}
            dateRange={dateRange}
            eventData={events}
            eventNames={eventNames}
            prevData={prevEvents}
            prevDateRange={prevDateRange}
            projectId={projectId}
          />
        )}
      </div>
    </div>
  );
}
