import { useFilters } from '@/hooks/use-filters';
import { toTitleCase } from '@/utils/to-title-case';
import { MagnifyingGlass } from '@phosphor-icons/react';

type MetadataPillProps = {
  label?: string;
  p: string;
  value: string;
};

export const MetadataPill = ({ label, p, value }: MetadataPillProps) => {
  const { addFilter } = useFilters();
  return (
    <div className="flex items-center gap-2">
      <div>{toTitleCase(label ?? p)}: </div>
      <div className="group relative flex items-center justify-center">
        <div
          className="absolute flex size-full items-center justify-center rounded bg-border px-3 py-2 text-xs font-bold text-white opacity-0 group-hover:opacity-75"
          onClick={(e) => {
            e.stopPropagation();
            addFilter({ field: p, value: value, operator: '=' });
          }}
        >
          <MagnifyingGlass />
        </div>
        <div className="w-full rounded border bg-code px-3 py-2 text-sm font-bold text-code-foreground">
          {value}
        </div>
      </div>
    </div>
  );
};
