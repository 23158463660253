import { useState } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { Button } from '@/components/ui/button';
import { useProjects } from '@/hooks/use-projects';
import { automationsOptions, eventNamesOptions } from '@/hooks/query-options';
import { SectionHeader } from '@/components/section-header';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogTrigger,
  DialogClose,
} from '@/components/ui/dialog';
import { Label } from '@/components/ui/label';
import { TelegramForm } from './telegram-form';
import { SlackForm } from './slack-form';
import { WebhookForm } from './webhook-form';
// import { PushNotificationRobot } from './push-notification-robot';
import { SlackRobot } from './slack-robot';
import { TelegramRobot } from './telegram-robot';
import { WebhookRobot } from './webhook-robot';
// import { NotificationForm } from './notification-form';
import type { Automation, RobotType } from '@/types';
import { Plus } from '@phosphor-icons/react';

type AutomationFormProps = {
  projectId: string;
  events: { name: string }[] | undefined;
};

const AutomationForm = ({ projectId, events }: AutomationFormProps) => {
  const [type, setType] = useState<RobotType>('slack');

  if (!events?.length) {
    return (
      <Dialog>
        <DialogTrigger asChild>
          <Button>Add Robot</Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Add Robot</DialogTitle>
          </DialogHeader>
          <form>
            <p>
              Unfortunately, you haven&apos;t sent any events for this project.
            </p>
            <DialogFooter>
              <DialogClose asChild>
                <Button disabled type="submit">
                  Add
                </Button>
              </DialogClose>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    );
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button iconLeft={<Plus />}>Add Robot</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add New Robot</DialogTitle>
        </DialogHeader>
        <div className="space-y-5">
          <div className="space-y-2">
            <Label>Robot Type</Label>
            <Select
              defaultValue={type}
              onValueChange={(value: RobotType) => setType(value)}
            >
              <SelectTrigger>
                <SelectValue placeholder="Select a Robot" />
              </SelectTrigger>
              <SelectContent>
                <SelectItem value="slack">Slack Message</SelectItem>
                <SelectItem value="telegram">Telegram Message</SelectItem>
                {/* Hiding mobile notifications for now
                            <SelectItem value="notification">Mobile Notification</SelectItem>*/}
                <SelectItem value="webhook">Webhook Notification</SelectItem>
              </SelectContent>
            </Select>
          </div>

          {/* Hiding mobile notifications for now
                {type === 'notification' && (
                  <NotificationForm events={events} form={form} />
                )}
                */}
          {type === 'slack' && (
            <SlackForm events={events} projectId={projectId} />
          )}
          {type === 'telegram' && (
            <TelegramForm events={events} projectId={projectId} />
          )}
          {type === 'webhook' && (
            <WebhookForm events={events} projectId={projectId} />
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};

export const Robots = () => {
  const { projectId } = useProjects();
  if (!projectId) {
    return null;
  }

  const eventNamesQuery = useSuspenseQuery(eventNamesOptions({ projectId }));
  const automationsQuery: {
    data: Automation[];
  } = useSuspenseQuery(automationsOptions({ projectId }));

  return (
    <div className="mt-6 space-y-6">
      <SectionHeader
        subTitle="Robots make it easy to send notifications to Slack, Telegram, or any other service that supports Webhooks."
        title="Robots"
      />
      <AutomationForm events={eventNamesQuery.data} projectId={projectId} />
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2 xl:grid-cols-4">
        {automationsQuery.data.map((automation, idx) => {
          if (automation?.type === 'slack') {
            return <SlackRobot key={idx} index={idx} robot={automation} />;
          } else if (automation?.type === 'webhook') {
            return <WebhookRobot key={idx} index={idx} robot={automation} />;
          } else if (automation?.type === 'telegram') {
            return <TelegramRobot key={idx} index={idx} robot={automation} />;
          }
          return null;
        })}
      </div>
    </div>
  );
};
