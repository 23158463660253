import { axiosClient, isDemo } from './axios-client';

export const ChannelsService = {
  getChannels: async ({ projectId }: { projectId: string }) => {
    if (isDemo()) {
      return [];
    }
    const res = await axiosClient.get(`/channels/${projectId}`);

    if (res.status === 200) {
      return res.data.data;
    }
    throw new Error('Failed to get channels');
  },

  createChannel: async ({
    projectId,
    channelName,
  }: {
    projectId: string;
    channelName: string;
  }) => {
    const res = await axiosClient.post(`/channels/${projectId}`, {
      name: channelName,
    });

    if (res.status === 200) {
      return res.data.data;
    }
    throw new Error('Failed to create channel');
  },

  deleteChannel: async ({
    projectId,
    channelId,
  }: {
    projectId: string;
    channelId: string;
  }) => {
    const res = await axiosClient.delete(`/channels/${projectId}/${channelId}`);

    if (res.status === 200) {
      return res.data.data;
    }
    throw new Error('Failed to delete channel');
  },
};
