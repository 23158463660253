import { useEffect, useMemo } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { projectsOptions } from './query-options';
import { projectIdAtom } from '@/state/atoms';
import { useAtom } from 'jotai';

export const useProjects = () => {
  const [localProjectId, setProjectId] = useAtom(projectIdAtom);
  const { data: projects } = useSuspenseQuery({ ...projectsOptions() });

  // Validate projectId on account change or projects update
  useEffect(() => {
    const validProjectId = projects?.find((p) => p.projectId === localProjectId)
      ? localProjectId
      : projects?.[0]?.projectId || null;
    if (validProjectId !== localProjectId) {
      setProjectId(validProjectId);
    }
  }, [projects, localProjectId, setProjectId]);

  const projectId = useMemo(() => localProjectId, [localProjectId]);
  const project = useMemo(
    () => projects?.find((p) => p.projectId === projectId) || null,
    [projectId, projects],
  );
  const sortedProjects = useMemo(
    () => projects?.sort((a, b) => a.name.localeCompare(b.name)),
    [projects],
  );

  return {
    projectId,
    setProjectId,
    project,
    projectName: project?.name,
    projects,
    sortedProjects,
  };
};
