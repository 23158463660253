import {
  ArrowDown,
  ArrowUp,
  Eye,
  Lightning,
  Minus,
} from '@phosphor-icons/react';
import { AreaChart } from '@/components/charts/area-chart';
import { Widget } from './widget';
import { numberWithCommas } from '@/utils/number-with-commas';
import { EventWidget as EventWidgetProps } from '@/types';
import { endOfDay, endOfHour, sub } from 'date-fns';
import { Badge } from '../ui/badge';
import { EmptyState } from '../empty-state';

function getVariant(diff: number) {
  if (diff < 0) {
    return 'error';
  } else if (diff > 0) {
    return 'success';
  } else {
    return 'neutral';
  }
}

export const EventWidget = ({
  name,
  hide = false,
  data,
  dateRange,
  prevData,
  iconType = 'event',
  primaryColor,
  backgroundColor,
}: EventWidgetProps) => {
  const currentTotal =
    data?.reduce((a, b) => a + parseInt(b.count.toString()), 0) ?? 0;
  const prevTotal =
    prevData?.reduce((a, b) => a + parseInt(b.count.toString()), 0) ?? 0;
  const diff =
    prevTotal === 0 ? 100 : ((currentTotal - prevTotal) * 100) / prevTotal;
  const formattedDiff = Math.abs(diff).toFixed(0);
  const prevDateRange = {
    interval: dateRange.interval,
    startDate: sub(dateRange.startDate, dateRange.period || { days: 1 }),
    endDate: dateRange.interval.includes('days')
      ? endOfDay(dateRange.startDate)
      : endOfHour(dateRange.startDate),
  };

  if (hide) {
    return null;
  }

  return (
    <Widget
      backgroundColor={backgroundColor}
      // onDelete={!internal && onDelete}
      Header={() => (
        <div className="flex items-center justify-between">
          <span className="flex items-center gap-2 text-xl font-medium">
            {iconType === 'view' ? (
              <Eye className="text-primary" style={{ color: primaryColor }} />
            ) : (
              <Lightning
                className="text-primary"
                style={{ color: primaryColor }}
              />
            )}
            <span className="font-semibold">{name}</span>
          </span>
          <span className="flex items-center gap-2">
            {!!data && (
              <>
                <span className="text-3xl font-black leading-none">
                  {numberWithCommas(currentTotal)}
                </span>
                <Badge variant={getVariant(diff)}>
                  {diff < 0 && <ArrowDown weight="bold" />}
                  {diff === 0 && <Minus weight="bold" />}
                  {diff > 0 && <ArrowUp weight="bold" />}
                  <span className="text-muted-foreground">
                    {formattedDiff}%
                  </span>
                </Badge>
              </>
            )}
          </span>
        </div>
      )}
    >
      <>
        {!data && <EmptyState headText="No Results Found" />}
        <AreaChart
          data={data}
          dateRange={dateRange}
          prevData={prevData}
          prevDateRange={prevDateRange}
          primaryColor={primaryColor}
        />
      </>
    </Widget>
  );
};
