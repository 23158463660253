import { LineChartData } from '@/types';
import { TooltipProps } from 'recharts';

type CustomTooltipProps = TooltipProps<number, string> & {
  renderTooltip?: (data: LineChartData) => JSX.Element;
  primaryColor?: string;
};

// const CustomTooltip = ({ active, payload, label, renderTooltip }) => {
export const CustomTooltip = ({
  active,
  payload,
  renderTooltip,
  primaryColor,
}: CustomTooltipProps) => {
  if (active && payload && payload.length) {
    if (renderTooltip) {
      return (
        <div className="border-border bg-background flex items-center gap-2 rounded border p-4">
          {renderTooltip(payload[0].payload)}
        </div>
      );
    }
    return (
      <div
        className="border-border bg-background text-primary flex items-center gap-2 rounded border p-4"
        style={{ color: primaryColor }}
      >
        <div className="flex flex-col">
          <span className="">{`${payload[0].payload.name}`}</span>
          <span className="text-2xl font-extrabold">
            {payload[0].payload.count}
          </span>
        </div>
        {payload[0].payload.prevName && (
          <div className="flex flex-col text-gray-400">
            <span className="">{`${payload[0].payload.prevName}`}</span>
            <span className="text-2xl font-extrabold">
              {payload[0].payload.prevCount}
            </span>
          </div>
        )}
      </div>
    );
  }

  return null;
};
