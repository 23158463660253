import { Link, useMatchRoute } from '@tanstack/react-router';
import { ArrowFatLinesUp, PhosphorLogo, Rocket } from '@phosphor-icons/react';
import { cn } from '@/utils/cn';
import { Button, buttonVariants } from '@/components/ui/button';
import {
  Tooltip,
  TooltipContent,
  TooltipTrigger,
  TooltipProvider,
} from '@/components/ui/tooltip';
import { OrgSwitcher } from './org-switcher';
import { LinkLogo } from '@/components/nav/link-logo';

type NavMenuProps = {
  isCollapsed: boolean;
  links: {
    title: string;
    icon: typeof PhosphorLogo;
    to: string;
    onClick?: () => void;
  }[];
  organizations: {
    label: string;
    email: string;
    icon?: React.ReactNode;
  }[];
  isDemo: boolean;
  isOnFreePlan?: boolean;
  onClick?: () => void;
};

export function NavMenu({
  links,
  organizations,
  isDemo,
  isOnFreePlan,
  isCollapsed,
  onClick,
}: NavMenuProps) {
  const matchRoute = useMatchRoute();
  return (
    <div>
      <div className="flex h-16 flex-col items-center justify-center">
        <LinkLogo isCollapsed={isCollapsed} />
      </div>
      <div
        className={cn(
          'flex h-[52px] items-center justify-center',
          !isCollapsed && 'px-2',
        )}
      >
        <OrgSwitcher isCollapsed={isCollapsed} organizations={organizations} />
      </div>
      <div
        className="group flex flex-col gap-4 py-2 data-[collapsed=true]:py-2"
        data-collapsed={isCollapsed}
      >
        <nav className="grid gap-2 px-2 group-[[data-collapsed=true]]:justify-center group-[[data-collapsed=true]]:px-2">
          {isDemo && !isCollapsed && (
            <Link to="/sign-up">
              <Button
                className="w-full"
                iconLeft={<Rocket />}
                variant="primary"
              >
                Start For Free
              </Button>
            </Link>
          )}
          {links.map((link, index) => {
            const match = matchRoute({ to: link.to });
            const activeClass = match ? 'text-primary font-semibold' : '';
            return isCollapsed ? (
              <TooltipProvider key={index}>
                <Tooltip delayDuration={0}>
                  <TooltipTrigger asChild>
                    <Link
                      className={cn(
                        buttonVariants({ variant: 'nav', size: 'icon' }),
                        'h-9 w-9',
                        activeClass,
                      )}
                      to={link?.to || '#'}
                      onClick={link?.onClick || (() => {})}
                    >
                      <link.icon className="size-5" />
                      <span className="sr-only">{link.title}</span>
                    </Link>
                  </TooltipTrigger>
                  <TooltipContent
                    className="flex items-center gap-4"
                    side="right"
                  >
                    {link.title}
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            ) : (
              <Link
                key={index}
                className={cn(
                  buttonVariants({ variant: 'nav' }),
                  'justify-start',
                  activeClass,
                )}
                to={link?.to || '#'}
                onClick={link?.onClick || (() => {})}
              >
                <link.icon className="mr-2 size-5" />
                {link.title}
              </Link>
            );
          })}
          {isOnFreePlan && !isCollapsed && (
            <Link to="/account">
              <Button
                className="w-full"
                iconLeft={<ArrowFatLinesUp />}
                variant="primaryTag"
                onClick={onClick || (() => {})}
              >
                Unlock Features
              </Button>
            </Link>
          )}
        </nav>
      </div>
    </div>
  );
}
