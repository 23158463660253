import { Suspense } from 'react';
import { AggregationWidget } from './aggregation-widget';
import { CampaignsWidget } from './campaigns-widget';
import { CountryWidget } from './country-widget';
import { DauWidget } from './dau-widget';
import { EventWidget } from './event-widget';
import { FunnelWidget } from './funnel-widget';
import { ReferrerWidget } from './referrer-widget';
import { RetentionWidget } from './retention-widget';
import {
  Widget as WidgetProps,
  FunnelWidget as FunnelWidgetProps,
  AggregationWidget as AggregationWidgetProps,
  EventWidget as EventWidgetProps,
} from '@/types';
import { Widget } from './widget';

export const WidgetWrapper = (
  props:
    | WidgetProps
    | EventWidgetProps
    | AggregationWidgetProps
    | FunnelWidgetProps,
) => {
  const { type } = props;

  if (type) {
    return (
      <Suspense fallback={<Widget hideMenu loading />}>
        {(() => {
          switch (type) {
            case 'AGGREGATION_BY_PROPERTY':
              return (
                <AggregationWidget {...(props as AggregationWidgetProps)} />
              );
            case 'COUNTRY':
              return <CountryWidget {...(props as WidgetProps)} />;
            case 'CAMPAIGNS':
              return <CampaignsWidget {...(props as WidgetProps)} />;
            case 'DAU':
              return <DauWidget {...(props as WidgetProps)} />;
            case 'EVENT':
              return <EventWidget {...(props as EventWidgetProps)} />;
            case 'FUNNEL':
              return <FunnelWidget {...(props as FunnelWidgetProps)} />;
            case 'REFERRER':
              return <ReferrerWidget {...(props as WidgetProps)} />;
            case 'RETENTION':
              return <RetentionWidget {...(props as WidgetProps)} />;
            default:
              return null;
          }
        })()}
      </Suspense>
    );
  } else {
    console.error('Invalid widget type');
    return null;
  }
};
