import { DateRange, EventData } from '@/types';
import { addDays, addHours, addMinutes } from 'date-fns';

function getXAxis(startDate: Date, stopDate: Date, interval: string) {
  const dateArray = [];
  let currentDate = startDate;
  while (currentDate <= stopDate) {
    dateArray.push(new Date(currentDate));
    if (interval === '5 minutes') {
      currentDate = addMinutes(currentDate, 5);
    } else if (interval === '1 days') {
      currentDate = addDays(currentDate, 1);
    } else if (interval === '1 hour') {
      currentDate = addHours(currentDate, 1);
    } else {
      currentDate = addDays(currentDate, 1);
    }
  }
  return dateArray;
}

const formatGroupKey = (date: Date | string | number, interval: string) => {
  if (interval === '5 minutes' || interval === '1 hour') {
    return new Date(date).toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });
  } else if (interval === '1 days') {
    return new Date(date).toLocaleDateString([], {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  }

  return date;
};

const formatDate = (date: Date | string | number, interval: string) => {
  if (interval === '5 minutes' || interval === '1 hour') {
    return new Date(date).toLocaleTimeString([], {
      hour: '2-digit',
      minute: '2-digit',
    });
  } else if (interval === '1 days') {
    return new Date(date).toLocaleDateString();
  }

  return date;
};

export const calculateChartData = (data: EventData[], dateRange: DateRange) => {
  const interval = dateRange.interval;
  const calculated = getXAxis(
    dateRange.startDate,
    dateRange.endDate,
    interval,
  ).map((date) => ({
    name: formatDate(date, interval),
    time: date.getTime(),
    groupKey: formatGroupKey(date, interval),
    count: 0,
  }));

  if (!data) {
    return calculated;
  }

  const counts = data.map((date) => {
    if (!date.date) {
      return date;
    }
    return {
      name: formatDate(date.date, interval),
      time: new Date(date.date).getTime(),
      groupKey: formatGroupKey(date.date, interval),
      count: +date.count,
    };
  });

  const mappedDates = calculated.map((date) => {
    const count = counts.find(
      (countDate) => countDate.groupKey === date.groupKey,
    );
    if (count) {
      return count;
    }

    return date;
  });

  return mappedDates;
};
