import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormLabel,
  FormItem,
  FormMessage,
} from '@/components/ui/form';
import { QueriesService } from '@/api';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { nanoid } from 'nanoid';

const saveSchema = z.object({
  name: z
    .string({ required_error: 'Please enter a name for your saved query.' })
    .min(2, {
      message:
        'Please enter a name for your saved query that is at least two characters.',
    }),
});

export const SaveQueryModal = ({
  queries,
  setQueries,
  projectId,
  getValues,
}) => {
  const saveForm = useForm<z.infer<typeof saveSchema>>({
    resolver: zodResolver(saveSchema),
    defaultValues: {
      name: '',
    },
  });

  const onSaveSubmit = (values: z.infer<typeof saveSchema>) => {
    const name = values.name;
    const newQueries = [
      ...queries,
      { id: nanoid(), name, filters: getValues() },
    ];
    setQueries(newQueries);
    QueriesService.saveQueries({ projectId, queries: newQueries }).catch(
      console.error,
    );
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline">Save Query</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Save Query</DialogTitle>
          <DialogDescription>
            Save this query for easy access later.
          </DialogDescription>
        </DialogHeader>
        <Form {...saveForm}>
          <form
            className="space-y-5"
            onSubmit={saveForm.handleSubmit(onSaveSubmit)}
          >
            <FormField
              control={saveForm.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Report Name</FormLabel>
                  <FormControl>
                    <Input id="name" {...field} placeholder="Enter a Name..." />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <DialogFooter>
              <DialogClose asChild>
                <Button
                  type="reset"
                  variant="outline"
                  onClick={() => saveForm.reset()}
                >
                  Cancel
                </Button>
              </DialogClose>
              <DialogClose asChild>
                <Button type="submit">Save Query</Button>
              </DialogClose>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
