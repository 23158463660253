import React from 'react';

import { cn } from '@/utils/cn';

const Divider = React.forwardRef<
  HTMLDivElement,
  React.ComponentPropsWithoutRef<'div'>
>(({ className, children, ...props }, forwardedRef) => (
  <div
    ref={forwardedRef}
    className={cn(
      // base
      'mx-auto my-6 flex w-full items-center justify-between gap-3 text-sm',
      // text color
      'text-gray-500 dark:text-gray-500',
      className,
    )}
    {...props}
  >
    {children ? (
      <>
        <div
          className={cn(
            // base
            'h-[1px] w-full',
            // background color
            'bg-border',
          )}
        />
        <div className="whitespace-nowrap text-inherit">{children}</div>
        <div
          className={cn(
            // base
            'h-[1px] w-full',
            // background color
            'bg-border',
          )}
        />
      </>
    ) : (
      <div
        className={cn(
          // base
          'h-[1px] w-full',
          // background color
          'bg-border',
        )}
      />
    )}
  </div>
));

Divider.displayName = 'Divider';

export { Divider };
