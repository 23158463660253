import { ListDashes, MapTrifold } from '@phosphor-icons/react';
import { useFilters } from '@/hooks/use-filters';
import { getCountry } from '@/utils/countries';
import { getFlagEmoji } from '@/utils/get-flag-emoji';
import { isoMap } from '@/components/map/country-mapping';
import { WorldMap } from '@/components/map/world-map';
import { AggregationWidget } from './aggregation-widget';
import { Widget as WidgetProps } from '@/types';

export const CountryWidget = ({
  internal,
  dateRange,
  onDelete,
}: WidgetProps) => {
  const { addFilter } = useFilters();

  return (
    <AggregationWidget
      dateRange={dateRange}
      internal={internal}
      params={{
        eventName: 'Pageview',
        title: 'Countries',
        property: '_location_country',
      }}
      renderProperty={(country) => (
        <div className="relative z-10 flex items-center gap-2 pl-2 font-semibold">
          <span>{getFlagEmoji(country)}</span>
          <span>{getCountry(country)}</span>
        </div>
      )}
      renderToggle={(toggled) => (
        <span className="flex cursor-pointer  select-none items-center gap-1 rounded border p-1 hover:border-primary hover:text-primary">
          <span> {!toggled ? <MapTrifold /> : <ListDashes />}</span>
        </span>
      )}
      renderToggledContent={(data) => {
        const mapData =
          data?.map((c) => ({
            // @ts-ignore
            id: isoMap[c.property as keyof typeof isoMap],
            // @ts-ignore
            iso2: c.property,
            // @ts-ignore
            count: parseInt(c.count),
          })) ?? [];

        return (
          <WorldMap
            data={mapData}
            onSelect={(country: string) =>
              addFilter({
                eventName: 'Pageview',
                field: 'location_country',
                value: country,
                operator: '=',
              })
            }
          />
        );
      }}
      onDelete={onDelete}
    />
  );
};
