import { uniq } from 'lodash';
import { useForm } from 'react-hook-form';
import { useEventNames } from '@/hooks/use-event-names';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormLabel,
  FormItem,
  FormMessage,
} from '@/components/ui/form';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { useSteps } from '@/hooks/use-steps';
import { Plus } from '@phosphor-icons/react';

const stepSchema = z.object({
  type: z.enum(['Pageview', 'Event'], {
    required_error: 'Please select a step type.',
  }),
  value: z.string({ required_error: 'Please provide a step value.' }).min(1, {
    message: 'Please provide a step value.',
  }),
});

export const StepModal = () => {
  const { addStep } = useSteps();
  const names: string[] = useEventNames();

  const form = useForm<z.infer<typeof stepSchema>>({
    resolver: zodResolver(stepSchema),
    defaultValues: {
      type: 'Pageview',
      value: '',
    },
  });

  const onSubmit = (values: z.infer<typeof stepSchema>) => {
    addStep({
      id: `${values.type}-${values.value}`,
      type: values.type,
      value: values.value,
    });
    form.reset();
  };

  const type = form.watch('type');

  const events: string[] = uniq(names).filter((n) => n !== 'Pageview');

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          className="h-full"
          iconLeft={<Plus />}
          size="lg"
          variant="outline"
        >
          Add Step
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add Step</DialogTitle>
          <DialogDescription>
            Add a step to your report. Steps are used to create funnels and can
            be events or pageviews.
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form className="space-y-5" onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
              control={form.control}
              name="type"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Step Type</FormLabel>
                  <Select
                    defaultValue={field.value}
                    onValueChange={field.onChange}
                  >
                    <FormControl>
                      <SelectTrigger>
                        <SelectValue placeholder="Select Step Type" />
                      </SelectTrigger>
                    </FormControl>
                    <SelectContent>
                      {['Pageview', 'Event']?.map((event: string) => (
                        <SelectItem key={event} value={event}>
                          {event}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <FormMessage />
                </FormItem>
              )}
            />
            {type === 'Pageview' && (
              <FormField
                control={form.control}
                name="value"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Page URL</FormLabel>
                    <FormControl>
                      <Input id="value" {...field} placeholder="/blog" />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}
            {type === 'Event' && (
              <FormField
                control={form.control}
                name="value"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Event</FormLabel>
                    <Select
                      defaultValue={field.value}
                      onValueChange={field.onChange}
                    >
                      <FormControl>
                        <SelectTrigger>
                          <SelectValue placeholder="Select Step" />
                        </SelectTrigger>
                      </FormControl>
                      <SelectContent>
                        {events?.map((event: string) => (
                          <SelectItem key={event} value={event}>
                            {event}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                    <FormMessage />
                  </FormItem>
                )}
              />
            )}
            <DialogFooter>
              <DialogClose asChild>
                <Button
                  type="reset"
                  variant="outline"
                  onClick={() => form.reset()}
                >
                  Cancel
                </Button>
              </DialogClose>
              <DialogClose asChild>
                <Button type="submit">Add Step</Button>
              </DialogClose>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
};
