import { createFileRoute, Navigate, Outlet } from '@tanstack/react-router';
import { useAccountDetails } from '@/hooks/use-account-details';
import { isDemo } from '@/api';
import { Topbar } from '@/components/nav/topbar';
import { NavMenu } from '@/components/nav/nav-menu';
import { navLinks } from '@/components/nav/nav-menu/nav-links';
import { useProjects } from '@/hooks/use-projects';
import { useTailwindBreakpoint } from '@/hooks/use-tailwind-breakpoint';
import {
  allEventsOptions,
  allEventsPrevOptions,
  dashboardConfigOptions,
  eventNamesOptions,
  infiniteEventsOptions,
  usersOptions,
} from '@/hooks/query-options';
import { validatedDefaultDateRange } from '@/state/atoms';

export const Route = createFileRoute('/_auth/_side-layout')({
  component: Layout,
  loader: async ({ context }) => {
    const projectId = context.projectId;
    const channelId = context.channelId;
    const filterGroups = [{ filters: [] }];

    const dateRange = validatedDefaultDateRange;
    const selectedEvent = null;
    if (!projectId) {
      return;
    }

    context.queryClient.prefetchQuery(
      allEventsOptions({ projectId, dateRange, filterGroups, channelId }),
    );
    context.queryClient.prefetchQuery(
      allEventsPrevOptions({
        projectId,
        dateRange,
        filterGroups,
        channelId,
      }),
    );
    context.queryClient.prefetchQuery(dashboardConfigOptions({ projectId }));
    context.queryClient.prefetchInfiniteQuery(
      infiniteEventsOptions({
        projectId,
        channelId,
        filterGroups,
        selectedEvent,
      }),
    );
    context.queryClient.prefetchQuery(
      usersOptions({ projectId, page: 1, channelId }),
    );
    context.queryClient.prefetchQuery(
      eventNamesOptions({ projectId, channelId }),
    );
  },
});

function Layout() {
  const { hasCreatedAnyProject, hasExceededPlan, account, isOnFreePlan } =
    useAccountDetails();
  const { projectId } = useProjects();
  const isDesktop = useTailwindBreakpoint('lg');
  const fullMenu = projectId ? true : false;

  if (hasExceededPlan) {
    return <Navigate to="/account" />;
  }

  if (!isDemo() && (!hasCreatedAnyProject || !projectId)) {
    return <Navigate to="/onboarding" />;
  }

  return (
    <div className="flex h-dvh text-clip">
      <div
        className={`sticky left-0 top-0 z-50 ${isDesktop ? 'min-w-60' : 'min-w-[66px]'} hidden h-screen flex-col gap-4 overflow-auto border-r px-2 sm:flex lg:px-3`}
      >
        <NavMenu
          isCollapsed={!isDesktop}
          isDemo={isDemo()}
          isOnFreePlan={isOnFreePlan}
          links={navLinks}
          organizations={[
            {
              label: account?.email || 'Your Organization',
              email: account?.email || 'Your Organization',
            },
          ]}
        />
      </div>
      <div
        className="flex size-full flex-col justify-between text-clip"
        id="content-container"
      >
        <div className="min-h-16 shrink-0 border-b">
          <Topbar fullMenu={fullMenu} />
        </div>
        <div className="min-h-0 grow overflow-auto p-6">
          <Outlet />
        </div>
        <div className="flex h-9 shrink-0 grow-0 items-center justify-center border-t p-4 text-xs">
          © {new Date().getFullYear()} Logspot | All Rights Reserved
        </div>
      </div>
    </div>
  );
}
