import { Copy } from '@phosphor-icons/react';
import { useEffect } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from '@/components/ui/use-toast';
import Prism from 'prismjs';
import { cn } from '@/utils/cn';

type CodeProps = {
  code: string;
  showBar?: boolean;
  className?: string;
  clipboard?: boolean;
};

export const Code = ({
  code,
  showBar = false,
  clipboard = true,
  className,
}: CodeProps) => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      Prism.highlightAll();
    }
  }, []);
  return (
    <div
      className={cn(
        'bg-code text-code-foreground gap-3 text-clip rounded border px-2 py-4',
        className,
      )}
    >
      {showBar && (
        <div className="flex flex-row gap-1">
          <div className="size-4 rounded-full bg-red-400"></div>
          <div className="size-4 rounded-full bg-yellow-400"></div>
          <div className="size-4 rounded-full bg-green-400"></div>
        </div>
      )}
      <div className="flex flex-col gap-5 px-2">
        {clipboard && (
          <CopyToClipboard
            text={code}
            onCopy={() => toast({ title: 'Code Copied to Clipboard' })}
          >
            <div className="flex w-min cursor-pointer items-center justify-end gap-1 rounded bg-gray-700 px-3 py-2 text-xs font-semibold text-white hover:bg-gray-600">
              <span>Copy</span>
              <Copy className="transition-colors" />
            </div>
          </CopyToClipboard>
        )}
        <pre className="language-javascript py-2 text-sm font-black leading-5 md:text-base">
          <code className="text-wrap break-all">{code}</code>
        </pre>
      </div>
    </div>
  );
};
