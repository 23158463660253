import React from 'react';
import ReactDOM from 'react-dom/client';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import {
  ErrorComponentProps,
  RouterProvider,
  createRouter,
} from '@tanstack/react-router';
import { routeTree } from './routeTree.gen';
import './styles/globals.css';
import 'react-tooltip/dist/react-tooltip.css';
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/600.css';
import '@fontsource/inter/700.css';
import { AuthService as auth } from '@/api';
import { Loader } from '@/components/ui/loader';
import ErrorPage from '@/components/error-page';
import NotFoundPage from '@/components/not-found-page';
import { useAtom, useAtomValue } from 'jotai';
import { channelIdAtom, projectIdAtom } from './state/atoms';

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // Set a default stale time of 10 seconds
      staleTime: 10000,
    },
  },
});

const router = createRouter({
  routeTree,
  context: {
    isAuthenticated: undefined!,
    projectId: undefined!,
    setProjectId: undefined!,
    channelId: undefined!,
    queryClient,
  },
  defaultPendingComponent: () => (
    <div className="grid size-auto min-h-40">
      <Loader />
    </div>
  ),
  defaultNotFoundComponent: () => <NotFoundPage />,
  defaultErrorComponent: ({ error, reset }: ErrorComponentProps) => (
    <ErrorPage error={error} reset={reset} />
  ),
  defaultPreload: 'intent',
  // Since we're using Tanstack Query, we don't want loader calls to ever be stale
  // This will ensure that Tanstack Query is always called when the route is preloaded or visited.
  defaultPreloadStaleTime: 0,
});

declare module '@tanstack/react-router' {
  interface Register {
    router: typeof router;
  }
}

const App = () => {
  const [projectId, setProjectId] = useAtom(projectIdAtom);
  const channelId = useAtomValue(channelIdAtom);
  const isAuthenticated = auth.isAuthenticated;

  return (
    <RouterProvider
      context={{
        queryClient,
        isAuthenticated,
        projectId,
        setProjectId,
        channelId,
      }}
      router={router}
    />
  );
};

const rootElement = document.getElementById('root')!;
if (!rootElement) {
  throw new Error('Could not find root element');
}
if (!rootElement.innerHTML) {
  const root = ReactDOM.createRoot(rootElement);
  root.render(
    <React.StrictMode>
      <QueryClientProvider client={queryClient}>
        <App />
      </QueryClientProvider>
    </React.StrictMode>,
  );
}
