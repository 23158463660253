import { useState, useEffect } from 'react';
import { formatDistance } from 'date-fns';
import 'react-datepicker/dist/react-datepicker.css';
import { Link } from '@tanstack/react-router';
import { EmojiAvatar } from '@/components/emoji-avatar';
import { MetadataPill } from './metadata-pill';
import { DisplayText } from './display-text';
import { EventIcon } from './event-icon';
import { PAGEVIEW } from './event-types';
import { MagnifyingGlass } from '@phosphor-icons/react';

type EventProps = {
  showUserAvatar: boolean;
  open: boolean;
  name: string;
  message: string;
  metadata: {
    href?: string;
    text?: string;
    tag?: string;
    className?: string;
    _message?: string;
  };
  createdAt: string;
  userId: string;
  location_country?: string;
  hostname?: string;
  url?: string;
};

function getFlagEmoji(countryCode: string) {
  if (!countryCode) {
    return null;
  }
  const codePoints = countryCode
    .toUpperCase()
    .split('')
    .map((char) => 127397 + char.charCodeAt(0));
  return String.fromCodePoint(...codePoints);
}

export const Event = ({
  showUserAvatar,
  open,
  name,
  message,
  metadata,
  createdAt,
  userId,
  location_country,
  hostname,
  url,
  ...rest
}: EventProps) => {
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    setOpened(open);
  }, [open]);

  const msg = message ?? metadata?._message;

  const [now, setNow] = useState(new Date());

  useEffect(() => {
    setInterval(() => setNow(new Date()), 30000);
  }, []);

  return (
    <div
      className="flex cursor-pointer flex-col rounded border border-border px-5 py-3 transition-all hover:border-primary hover:shadow-sm"
      onClick={() => {
        setOpened(!opened);
      }}
    >
      <div className="flex flex-col justify-between sm:flex-row sm:items-center">
        <div className="flex items-center gap-2">
          {showUserAvatar && (
            <Link className="group relative mr-3" to={`/users/${userId}`}>
              <div className="pointer-events-none absolute flex size-full items-center justify-center rounded border border-primary bg-border opacity-0 transition-all group-hover:opacity-75">
                <MagnifyingGlass
                  className="size-5 text-primary"
                  weight="bold"
                />
              </div>
              <div className="border transition-all">
                <EmojiAvatar id={userId} />
              </div>
              {location_country && (
                <span className="absolute right-[-10px] top-[-10px] flex size-5 items-center justify-center rounded border bg-background shadow-xl">
                  <span>{getFlagEmoji(location_country)}</span>
                </span>
              )}
            </Link>
          )}
          <div className="flex items-center gap-2 truncate rounded border bg-primary/10 px-4 py-2 md:text-base dark:bg-code">
            <div
              className={`rounded border bg-background p-1 ${name === PAGEVIEW ? 'border-border' : 'border-primary'}`}
            >
              <EventIcon {...{ name, metadata }} />
            </div>
            <DisplayText {...{ name, metadata }} />
            {name === PAGEVIEW && (
              <a
                className="max-w-[100px] truncate hover:underline md:max-w-lg"
                href={`https://${hostname}${url}`}
                rel="noreferrer"
                target="_blank"
              >
                {url}
              </a>
            )}
          </div>
        </div>
        <span className="text-gray-500">
          {formatDistance(new Date(createdAt), now, { addSuffix: true })}
        </span>
      </div>

      {msg && (
        <pre className="mt-3 w-full overflow-x-auto whitespace-nowrap rounded border bg-code p-3 text-code-foreground">
          {msg}
        </pre>
      )}
      {opened && (
        <div className="mt-5 flex flex-col">
          <span>Metadata</span>
          <pre className="mt-3 w-4/5 overflow-x-auto whitespace-nowrap rounded border bg-code p-3 text-code-foreground">
            {JSON.stringify(metadata, null, 2)}
          </pre>
          <div className="mt-5 flex flex-wrap gap-5">
            {userId && (
              <MetadataPill label={'user_id'} p={'user_id'} value={userId} />
            )}
            {[
              'location_country',
              'location_region',
              'location_city',
              'hostname',
              'os',
              'device',
              'browser',
              'language',
              'referrer',
            ]
              .filter((p) => (rest as Record<string, string>)[p])
              .map((p, index) => (
                <MetadataPill
                  key={index}
                  p={p}
                  value={(rest as Record<string, string>)[p]}
                />
              ))}
          </div>
        </div>
      )}
    </div>
  );
};
