import { useState } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { Input } from '@/components/ui/input';
import { useProjects } from '@/hooks/use-projects';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '@/components/ui/form';

const AGGREGATION_FIELDS = [
  '_location_country',
  '_location_city',
  '_location_region',
  '_device',
  '_os',
  '_browser',
  '_screen',
  '_language',
  '_referrer',
  '_url',
  '_hostname',
];
import { useForm } from 'react-hook-form';
import { toTitleCase } from '@/utils/to-title-case';
import { eventNamesOptions } from '@/hooks/query-options';

type FormValues = {
  widgetType: string;
  title: string;
  eventName: string;
  property: string;
};

export const AggregationForm = ({
  form,
}: {
  form: ReturnType<typeof useForm<FormValues>>;
}) => {
  const { projectId } = useProjects();
  if (!projectId) {
    return null;
  }

  const { data } = useSuspenseQuery(eventNamesOptions({ projectId }));

  return (
    <div className="space-y-5">
      <FormField
        control={form.control}
        name="title"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Title</FormLabel>
            <FormControl>
              <Input id="title" {...field} placeholder="Enter a Title..." />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="eventName"
        render={({ field }) => (
          <FormItem>
            <FormLabel>Event</FormLabel>
            <Select defaultValue={field.value} onValueChange={field.onChange}>
              <FormControl>
                <SelectTrigger>
                  <SelectValue placeholder="Select an Event" />
                </SelectTrigger>
              </FormControl>
              <SelectContent>
                {data?.map((event: { name: string }) => (
                  <SelectItem key={event.name} value={event.name}>
                    {event.name}
                  </SelectItem>
                ))}
              </SelectContent>
            </Select>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="property"
        render={({ field }) => {
          const [isCustom, setIsCustom] = useState(false);
          const [customValue, setCustomValue] = useState('');

          const handleSelectChange = (value: string) => {
            setIsCustom(value === 'custom');
            if (value !== 'custom') {
              field.onChange(value);
            }
          };

          const handleCustomInputChange = (
            event: React.ChangeEvent<HTMLInputElement>,
          ) => {
            setCustomValue(event.target.value);
            field.onChange(event.target.value);
          };

          return (
            <FormItem>
              <FormLabel>Property</FormLabel>
              <Select
                defaultValue={isCustom ? 'custom' : field.value}
                onValueChange={handleSelectChange}
              >
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select an Event" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {AGGREGATION_FIELDS.map((value) => (
                    <SelectItem key={value} value={value}>
                      {toTitleCase(value)}
                    </SelectItem>
                  ))}
                  <SelectItem value="custom">Custom</SelectItem>
                </SelectContent>
              </Select>
              {isCustom && (
                <Input
                  id="customProperty"
                  value={customValue}
                  onChange={handleCustomInputChange}
                />
              )}
              <FormMessage />
            </FormItem>
          );
        }}
      />
    </div>
  );
};
