import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu';
import { Dialog, DialogContent } from '@/components/ui/dialog';
import * as htmlToImage from 'html-to-image';
import { DotsThreeVertical } from '@phosphor-icons/react';
import { createRef, RefObject, useState } from 'react';
import LogoBlack from '@/images/logspot-black-black.svg?react';
import LogoWhite from '@/images/logspot-white-white.svg?react';
import { toast } from '@/components/ui/use-toast';
import { ReactNode } from 'react';
import { Skeleton } from '@/components/ui/skeleton';
import { Button } from '../ui/button';
import { Card } from '../ui/card';

type Action = {
  label: string;
  action: () => void;
};

type WidgetProps = {
  backgroundColor?: string;
  Header?: () => JSX.Element;
  SubHeader?: () => JSX.Element;
  children?: ReactNode;
  onDelete?: () => void | Promise<unknown> | undefined;
  onHide?: () => void;
  hideMenu?: boolean;
  isScreenshot?: boolean;
  loading?: boolean;
};

type ScreenshotModalProps = {
  Header?: () => JSX.Element;
  children: ReactNode;
};

type DropdownProps = {
  actions: Action[];
};

const takeScreenShot = async (node: HTMLElement) => {
  const dataURI = await htmlToImage.toJpeg(node, { skipFonts: true });
  return dataURI;
};

const download = (image: string, { name = 'img', extension = 'jpg' } = {}) => {
  const a = document.createElement('a');
  a.href = image;
  a.download = `${name}.${extension}`;
  a.click();
};

function createImage(options: { src?: string }) {
  options = options || {};
  const img = Image ? new Image() : document.createElement('img');
  if (options.src) {
    img.src = options.src;
  }
  return img;
}

function convertToPng(imgBlob: Blob) {
  const imageUrl = window.URL.createObjectURL(imgBlob);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');
  const imageEl = createImage({ src: imageUrl });
  imageEl.onload = (e) => {
    if (ctx && e.target) {
      const img = e.target as HTMLImageElement;
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0, img.width, img.height);
      canvas.toBlob(
        (blob) => {
          if (blob) {
            copyToClipboard(blob);
          }
        },
        'image/png',
        1,
      );
    }
  };
}

async function copyImg(src: string) {
  const img = await fetch(src);
  const imgBlob = await img.blob();

  convertToPng(imgBlob);
}

async function copyToClipboard(pngBlob: Blob) {
  try {
    await navigator.clipboard.write([
      new window.ClipboardItem({
        [pngBlob.type]: pngBlob,
      }),
    ]);
  } catch (error) {
    console.error(error);
  }
}

const downloadScreenshot = (ref: RefObject<HTMLElement>) => {
  if (ref?.current) {
    takeScreenShot(ref.current)
      .then(download)
      .then(() => toast({ title: 'Screenshot Downloaded' }));
  }
};

const copyScreenshot = (ref: RefObject<HTMLElement>) => {
  if (ref?.current) {
    takeScreenShot(ref.current)
      .then(copyImg)
      .then(() => toast({ title: 'Screenshot Copied' }));
  }
};

const colors = [
  {
    bg: 'bg-primary',
    fg: 'text-white',
    logo: <LogoWhite className="h-7 w-auto" />,
  },
  {
    bg: 'bg-gradient-to-bl from-gray-700 via-gray-900 to-black',
    fg: 'text-white',
    logo: <LogoWhite className="h-7 w-auto" />,
  },
  {
    bg: 'bg-gradient-to-r from-indigo-200 via-red-200 to-yellow-100',
    fg: 'text-black',
    logo: <LogoBlack className="h-7 w-auto" />,
  },
  {
    bg: 'bg-gradient-to-r from-orange-400 to-rose-400',
    fg: 'text-white',
    logo: <LogoWhite className="h-7 w-auto" />,
  },
  {
    bg: 'bg-gradient-to-r from-gray-100 to-gray-300',
    fg: 'text-black',
    logo: <LogoBlack className="h-7 w-auto" />,
  },
];

const Dropdown = ({ actions }: DropdownProps) => {
  return (
    <DropdownMenu>
      <DropdownMenuTrigger>
        <DotsThreeVertical
          className="align-text-top"
          size={24}
          weight="light"
        />
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        {actions.map((action, idx) => (
          <DropdownMenuItem key={action.label + idx} onClick={action.action}>
            {action.label}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

const ScreenshotModal = ({ Header, children }: ScreenshotModalProps) => {
  const ref = createRef<HTMLDivElement>();
  const [bg, setBg] = useState(0);
  return (
    <DialogContent className="p-10">
      <div
        ref={ref}
        className={`relative flex max-w-full origin-top items-center justify-center overflow-hidden ${colors[bg].bg}`}
      >
        <div className="mx-5 mb-14 mt-5 w-full shadow-lg md:mx-8 md:mb-14 md:mt-10 md:max-h-[800px]">
          <Widget Header={Header}>{children}</Widget>
        </div>
        <div className="absolute bottom-3 right-8 flex items-center gap-2">
          <span className={`text-xs font-bold ${colors[bg].fg}`}>
            Powered by
          </span>
          {colors[bg].logo}
        </div>
      </div>
      <div className="mt-5 flex flex-col items-center justify-between gap-5 md:flex-row">
        <div className="flex items-center gap-3">
          <Button variant="outline" onClick={() => downloadScreenshot(ref)}>
            Download
          </Button>
          <Button variant="outline" onClick={() => copyScreenshot(ref)}>
            Copy
          </Button>
        </div>
        <div className="flex items-center gap-1">
          {colors.map((c, index) => (
            <span
              key={index}
              className={`${c.bg} size-5 cursor-pointer rounded-full`}
              onClick={() => setBg(index)}
            ></span>
          ))}
        </div>
      </div>
    </DialogContent>
  );
};

export const Widget = ({
  backgroundColor,
  Header,
  SubHeader,
  children,
  onDelete,
  onHide,
  hideMenu,
  isScreenshot,
  loading,
}: WidgetProps) => {
  const [screenshotOpen, setScreenshotOpen] = useState(false);

  const actions: Action[] = [];

  if (onDelete) {
    actions.push({ label: 'Delete', action: onDelete });
  }

  if (onHide) {
    actions.push({ label: 'Hide', action: onHide });
  }

  actions.push({ label: 'Screenshot', action: () => setScreenshotOpen(true) });

  return (
    <>
      <Card
        className="light flex min-h-[320px] flex-col"
        style={{ background: backgroundColor }}
      >
        {!hideMenu && (
          <div className="flex flex-col">
            <div className={`flex items-center ${isScreenshot ? '' : 'px-2'}`}>
              <div className="flex-1 px-2 py-5">
                <div className="flex-1">{Header && <Header />}</div>
              </div>
              <Dialog
                open={screenshotOpen}
                onOpenChange={() => setScreenshotOpen(!screenshotOpen)}
              >
                <Dropdown actions={actions} />
                <ScreenshotModal Header={Header}>{children}</ScreenshotModal>
              </Dialog>
            </div>
            {SubHeader && <SubHeader />}
          </div>
        )}
        {loading ? (
          <div className="m-5 flex h-full flex-col gap-3">
            <div className="flex h-1/6 grow justify-between">
              <div className="flex w-full flex-col gap-2">
                <Skeleton className="h-6 w-3/6" />
                <Skeleton className="h-5 w-2/6" />
              </div>
              <Skeleton className="size-11 w-2/6 rounded" />
            </div>
            <div className="flex h-5/6 flex-col gap-2">
              <Skeleton className="h-1/3 rounded" />
              <Skeleton className="h-2/3 rounded" />
            </div>
          </div>
        ) : (
          <div className="mt-2 flex h-full min-h-[150px] flex-1 flex-col justify-end">
            {children}
          </div>
        )}
      </Card>
    </>
  );
};
