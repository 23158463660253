import { useAtom } from 'jotai';
import { stepsAtom } from '@/state/atoms';
import { ReportStep } from '@/types';

export function useSteps() {
  const [steps, setSteps] = useAtom(stepsAtom);

  const addStep = (step: ReportStep) => {
    setSteps((prevSteps) => [...prevSteps, step]);
  };

  const removeStep = (step: ReportStep) => {
    setSteps((prevSteps) => prevSteps.filter((s) => s.id !== step.id));
  };

  const clearSteps = () => {
    setSteps([]);
  };

  return { steps, addStep, removeStep, clearSteps };
}
