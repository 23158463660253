import { createFileRoute, useLocation } from '@tanstack/react-router';
import { useEffect } from 'react';
import { AuthService } from '@/api';
import { logspot } from '@logspot/web';

export const Route = createFileRoute('/auth/callback')({
  component: AuthCallback,
});

function AuthCallback() {
  const location = useLocation();
  // grab token from url query string
  const accessToken = new URLSearchParams(location.search).get('token');

  useEffect(() => {
    // call api to login
    AuthService.loginSocial({ accessToken }).then((data) => {
      if (data.token) {
        logspot.register({ authed: true, userId: data.account });
        logspot.unregister('demo');
        window.location.href = '/';
      }
    });
  }, [accessToken]);

  return <div>Logging in...</div>;
}
