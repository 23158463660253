import { useCallback } from 'react';
import _ from 'lodash';
import Logspot from '@logspot/web';
import { Filter } from '@/types';
import { filtersAtom } from '@/state/atoms';
import { useAtom } from 'jotai';

export const useFilters = () => {
  const [filters, setFilters] = useAtom(filtersAtom);

  const removeFilter = useCallback(
    (index: number) => {
      const updatedFilters = [...filters];
      updatedFilters.splice(index, 1);
      setFilters(updatedFilters);
    },
    [filters, setFilters],
  );

  const removeAllFilters = useCallback(() => {
    setFilters([]);
  }, [setFilters]);

  const isActiveFilter = useCallback(
    (filter: Filter) => {
      return _.find(
        filters,
        (f) =>
          f.field === filter.field &&
          f.operator === filter.operator &&
          f.value === filter.value,
      );
    },
    [filters],
  );

  const addFilter = useCallback(
    (filter: Filter) => {
      Logspot.track({ event: 'Search', metadata: filter });
      setFilters([...filters, filter]);
    },
    [filters, setFilters],
  );

  // Currently expected on the API side, but never set to more than one group
  const filterGroups = filters.length
    ? [
        {
          filters: filters.map((f) => ({
            fieldName: f.field,
            operator: f.operator,
            value: f.value,
          })),
        },
      ]
    : [{ filters: [] }];

  return {
    filters,
    filterGroups,
    removeFilter,
    removeAllFilters,
    isActiveFilter,
    addFilter,
  };
};
