import { useForm } from 'react-hook-form';
import { useSuspenseQuery } from '@tanstack/react-query';
import { EmptyState } from '@/components/empty-state';
import { ProjectsService } from '@/api';
import { createFileRoute } from '@tanstack/react-router';
import { projectsOptions } from '@/hooks/query-options';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormLabel,
  FormItem,
  FormMessage,
} from '@/components/ui/form';
import { SectionHeader } from '@/components/section-header';
import { Label } from '@/components/ui/label';
import { Code } from '@/components/code';

const projectSchema = z.object({
  name: z
    .string({ required_error: 'Please enter a name for your funnel.' })
    .min(2, {
      message:
        'Please enter a name for your funnel that is at least two characters.',
    }),
});

export const Route = createFileRoute('/_auth/_side-layout/projects_/details')({
  component: ProjectsDetails,
});

function ProjectsDetails() {
  const { data } = useSuspenseQuery(projectsOptions());

  const form = useForm<z.infer<typeof projectSchema>>({
    resolver: zodResolver(projectSchema),
    defaultValues: {
      name: '',
    },
  });

  const onSubmit = async ({ name }: { name: string }) => {
    await ProjectsService.createProject({ name });
  };

  return (
    <>
      <Dialog>
        <DialogTrigger asChild>
          <Button>Add Project</Button>
        </DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Add New Project</DialogTitle>
          </DialogHeader>
          <Form {...form}>
            <form className="space-y-5" onSubmit={form.handleSubmit(onSubmit)}>
              <FormField
                control={form.control}
                name="name"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>Project Name</FormLabel>
                    <FormControl>
                      <Input
                        id="name"
                        {...field}
                        placeholder="Enter a Name..."
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <DialogFooter>
                <DialogClose asChild>
                  <Button type="button" variant="outline">
                    Close
                  </Button>
                </DialogClose>
                <DialogClose asChild>
                  <Button type="submit">Add Project</Button>
                </DialogClose>
              </DialogFooter>
            </form>
          </Form>
        </DialogContent>
      </Dialog>

      <div className="flex w-full flex-col">
        {data.length === 0 && (
          <EmptyState
            launchState
            headText="You Haven't Added Any Projects Yet"
            subText="Let's create your first project"
          />
        )}

        {data.map(
          ({ name, shortId, publicKey, secretKey, webhookSecretKey }) => (
            <div key={shortId} className="mt-5 flex flex-col">
              <SectionHeader
                separator
                className="text-2xl sm:text-3xl"
                title={name}
              />
              <div className="flex flex-col gap-5">
                <div className="flex flex-col gap-3">
                  <Label>Webhook Url</Label>
                  <Code
                    className="p-1"
                    clipboard={false}
                    code={`https://api.logspot.io/hooks/${shortId}/${webhookSecretKey}`}
                  />
                </div>
                <div className="flex flex-col gap-3">
                  <Label>Public Key</Label>
                  <Code
                    className="p-1"
                    clipboard={false}
                    code={`pk_${publicKey}`}
                  />
                </div>
                <div className="flex flex-col gap-3">
                  <Label>Secret Key</Label>
                  <Code
                    className="p-1"
                    clipboard={false}
                    code={`sk_${secretKey}`}
                  />
                </div>
              </div>
            </div>
          ),
        )}
      </div>
    </>
  );
}
