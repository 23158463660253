import { atom } from 'jotai';
import { atomWithStorage } from 'jotai/utils';
import {
  Theme,
  DateRangeSchema,
  DateRange,
  Filter,
  FilterDialog,
  ReportStep,
} from '@/types';
import { ranges } from '@/utils/date-ranges';
import Logspot from '@logspot/web';

export const projectIdAtom = atomWithStorage<string | null>(
  'ls-project-id',
  null,
  undefined,
  {
    getOnInit: true,
  },
);

export const channelIdAtom = atom<string | null>(null);

export const themeAtom = atomWithStorage<Theme>('ls-theme', 'dark', undefined, {
  getOnInit: true,
});

export const filtersAtom = atom<Filter[]>([]);

export const stepsAtom = atom<ReportStep[]>([]);

const defaultDateRange = {
  label: ranges[2].label,
  period: ranges[2].period,
  interval: ranges[2].interval,
  startDate: ranges[2].range().startDate,
  endDate: ranges[2].range().endDate,
  key: 'selection',
};
export const validatedDefaultDateRange =
  DateRangeSchema.parse(defaultDateRange);

export const baseDateRangeAtom = atom<DateRange>(validatedDefaultDateRange);
export const dateRangeAtom = atom<DateRange, [DateRange], void>(
  (get) => get(baseDateRangeAtom),
  (get, set, newRange) => {
    try {
      const validatedNewRange = DateRangeSchema.parse(newRange);
      const currentRange = get(baseDateRangeAtom);
      // Only update if the new range is different from the current range
      if (JSON.stringify(validatedNewRange) !== JSON.stringify(currentRange)) {
        set(baseDateRangeAtom, validatedNewRange);
        Logspot.track({
          event: 'Date Range Chosen',
          metadata: newRange,
        });
      }
    } catch (e) {
      console.error(e);
      if (
        JSON.stringify(get(baseDateRangeAtom)) !==
        JSON.stringify(validatedDefaultDateRange)
      ) {
        set(baseDateRangeAtom, validatedDefaultDateRange);
      }
    }
  },
);

const defaultFilterDialog = {
  search: '',
  searchValue: '',
  filteredItems: [],
  fieldsData: [],
  selectedField: null,
  selectedOperator: null,
  selectedValue: null,
  currentTab: 'fields',
};

export const filterDialogAtom = atom<FilterDialog>(defaultFilterDialog);
