import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import { CircleNotch } from '@phosphor-icons/react';
import { cn } from '@/utils/cn';
import { focusGlow } from '@/utils/focus';

const buttonVariants = cva(
  [
    // base
    'inline-flex items-center justify-center whitespace-nowrap rounded bg-transparent text-base font-medium transition-colors',
    // disabled
    'disabled:pointer-events-none disabled:opacity-50',
    // focus
    focusGlow,
  ],
  {
    variants: {
      variant: {
        primary:
          'border border-primary bg-primary font-semibold text-white shadow-sm hover:bg-primary/90',
        primaryOutline:
          'border border-primary font-semibold text-primary shadow-sm hover:text-blue-400',
        primaryTag:
          'border border-blue-600 bg-blue-100/90 font-semibold text-blue-600 shadow-sm hover:text-blue-500 dark:border-blue-500 dark:bg-blue-800/90 dark:text-blue-500 dark:hover:text-blue-400',
        GreenTag:
          'border border-green-600 bg-green-100/90 font-semibold text-green-600 shadow-sm hover:text-green-500 dark:border-green-500 dark:bg-green-800/90 dark:text-green-500 dark:hover:text-green-400',
        outline:
          'border border-border font-semibold text-black shadow-sm hover:border-primary hover:text-primary dark:text-white',
        link: 'text-primary hover:scale-110 hover:text-primary/90',
        text: 'hover:scale-110 hover:text-primary',
        nav: 'hover:border hover:border-primary hover:text-primary',
        ghost:
          'hover:bg-gray-100 hover:text-gray-900 dark:hover:bg-gray-800 dark:hover:text-gray-50',
        destructive:
          'bg-destructive font-semibold text-destructive-foreground shadow-sm hover:bg-destructive/90',
        select:
          'flex h-9 w-full items-center justify-between whitespace-nowrap border border-input bg-transparent px-3 py-2 shadow-sm ring-offset-background placeholder:text-muted-foreground disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1',
      },
      size: {
        md: 'h-9 px-4 py-2',
        sm: 'h-8 px-3 py-2',
        lg: 'h-10 px-5 py-3',
        icon: 'size-9',
      },
    },
    defaultVariants: {
      variant: 'primary',
      size: 'md',
    },
  },
);

const iconVariants = cva('', {
  variants: {
    size: {
      md: 'size-4',
      sm: 'size-4',
      lg: 'size-5',
      icon: 'size-5',
    },
    position: {
      left: 'mr-1.5',
      right: 'ml-1.5',
    },
  },
  defaultVariants: {
    size: 'md',
  },
});

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
  iconLeft?: React.ReactElement<{ className?: string }>;
  iconRight?: React.ReactElement<{ className?: string }>;
  testId?: string;
}
const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      className,
      variant,
      size,
      disabled,
      loading,
      iconLeft,
      iconRight,
      // tooltip,
      asChild = false,
      children,
      testId = 'concord-button',
      onClick,
      ...props
    },
    ref,
  ) => {
    const isDisabled = disabled || loading;
    const Comp = asChild ? Slot : 'button';

    return (
      <Comp
        ref={ref}
        className={cn(buttonVariants({ variant, size, className }))}
        data-testid={testId}
        disabled={isDisabled}
        onClick={onClick}
        {...props}
      >
        {!loading &&
          iconLeft &&
          React.isValidElement(iconLeft) &&
          React.cloneElement(iconLeft, {
            className: cn(
              iconVariants({
                size,
                position: 'left',
                class: iconLeft.props.className,
              }),
            ),
          })}
        {children}
        {!loading &&
          iconRight &&
          React.isValidElement(iconRight) &&
          React.cloneElement(iconRight, {
            className: cn(
              iconVariants({
                size,
                position: 'right',
                class: iconRight.props.className,
              }),
            ),
          })}
        {loading ? (
          <CircleNotch className={cn('animate-spin ml-1.5 size-5')} />
        ) : null}
      </Comp>
    );
  },
);
Button.displayName = 'Concord Button';

export { Button, buttonVariants };
