import 'react-datepicker/dist/react-datepicker.css';
import { EventFeed } from '@/components/event-feed';
import { useProjects } from '@/hooks/use-projects';
// import { FilterDialogButton } from '@/components/filter-dialog-button';
import { useFilters } from '@/hooks/use-filters';
import { createFileRoute } from '@tanstack/react-router';
import { infiniteEventsOptions } from '@/hooks/query-options';
import { PageHeader } from '@/components/page-header';

export const Route = createFileRoute('/_auth/_side-layout/events')({
  component: Events,
  loader: ({ context }) => {
    const projectId = context.projectId;
    const channelId = context.channelId;
    const filterGroups = [{ filters: [] }];
    const selectedEvent = null;
    if (!projectId) {
      return;
    }
    context.queryClient.prefetchInfiniteQuery(
      infiniteEventsOptions({
        projectId,
        channelId,
        filterGroups,
        selectedEvent,
      }),
    );
  },
});

function Events() {
  const { projectId } = useProjects();
  if (!projectId) {
    return null;
  }
  const { filterGroups } = useFilters();

  return (
    <div className="mx-auto flex flex-col">
      <PageHeader separator={false} title="Feed" />
      <EventFeed {...{ projectId, filterGroups }} />
    </div>
  );
}
