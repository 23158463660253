import { useState } from 'react';
// import { getFlagEmoji } from "@/utils/get-flag-emoji";
import { AggregationWidget } from './aggregation-widget';
import { Widget } from '@/types';

// function getCountry(countryCode) {
//   const regionNames = new Intl.DisplayNames(["en"], { type: "region" });

//   return regionNames.of(countryCode);
// }

const campaigns = [
  ['utm_source', 'Source'],
  ['utm_medium', 'Medium'],
  ['utm_campaign', 'Campaign'],
  ['utm_content', 'Content'],
  ['utm_term', 'Term'],
];

export const CampaignsWidget = ({
  internal,
  params,
  dateRange,
  onDelete,
}: Widget) => {
  const [currentProperty, setProperty] = useState('utm_source');
  const title = params.title;

  return (
    <AggregationWidget
      dateRange={dateRange}
      internal={internal}
      params={{
        title,
        property: currentProperty,
        eventName: 'Pageview',
      }}
      renderOptions={() => (
        <div className="flex flex-wrap gap-2 text-xs">
          {campaigns.map(([property, label]) => (
            <span
              key={property}
              className={`cursor-pointer rounded px-2 py-1 transition-all ${
                currentProperty === property
                  ? 'bg-primary text-white'
                  : 'border border-border hover:border-primary hover:text-primary'
              }`}
              onClick={() => setProperty(property)}
            >
              {label}
            </span>
          ))}
        </div>
      )}
      onDelete={onDelete}
    />
  );
};
