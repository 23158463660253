import { differenceInDays } from 'date-fns';
import { Calendar } from '@phosphor-icons/react';
import { useState } from 'react';
import { useAccountDetails } from '@/hooks/use-account-details';
import { Link } from '@tanstack/react-router';
import { Button } from '@/components/ui/button';
import { ALL_TIME, ranges } from '@/utils/date-ranges';
import { useAtom } from 'jotai';
import { dateRangeAtom } from '@/state/atoms';
import { DateRangePicker } from './ui/date-range-picker';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from './ui/dropdown-menu';

export const DatePicker = () => {
  const [currentRange, setCurrentRange] = useAtom(dateRangeAtom);
  const { isOnFreePlan, createdAt } = useAccountDetails();
  const [showCustomRange, setShowCustomRange] = useState(false);
  const [openCustomRange, setOpenCustomRange] = useState(false);

  if (!currentRange || !createdAt) {
    return null;
  }

  if (isOnFreePlan) {
    const range = ranges[1].range() as { startDate: Date; endDate: Date };
    setCurrentRange({
      label: ranges[1].label,
      period: ranges[1].period,
      interval: ranges[1].interval,
      startDate: range.startDate,
      endDate: range.endDate,
      key: 'selection',
    });
  }

  return !showCustomRange ? (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button
          className="font-semibold"
          iconLeft={<Calendar />}
          variant="outline"
        >
          <span>{currentRange.label}</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuLabel>Date Ranges</DropdownMenuLabel>
        <DropdownMenuSeparator />
        {ranges.map((r) => (
          <DropdownMenuItem
            key={r.label}
            className={`w-full rounded px-3 py-2 transition-all hover:text-primary ${
              isOnFreePlan && !r.isOnFree
                ? 'cursor-not-allowed text-gray-300'
                : 'cursor-pointer'
            }`}
            onClick={() => {
              if (isOnFreePlan && !r.isOnFree) {
                return null;
              }
              if (r.label === 'Custom Range') {
                setShowCustomRange(true);
                setOpenCustomRange(true);
              } else {
                setCurrentRange({
                  label: r.label,
                  interval: r.interval,
                  period: r.period,
                  startDate:
                    r.label === ALL_TIME
                      ? new Date(createdAt)
                      : r.range().startDate,
                  endDate: r.range().endDate,
                  key: 'selection',
                });
              }
            }}
          >
            {r.label}
          </DropdownMenuItem>
        ))}
        {isOnFreePlan && (
          <DropdownMenuItem>
            <Link
              className="mt-5 w-full rounded bg-gray-900 px-3 py-2 text-center font-semibold text-white"
              to={'/account'}
            >
              🔓 Unlock All
            </Link>
          </DropdownMenuItem>
        )}
      </DropdownMenuContent>
    </DropdownMenu>
  ) : (
    <DateRangePicker
      align="start"
      initialDateFrom={currentRange.startDate.toISOString()}
      initialDateTo={currentRange.endDate.toISOString()}
      locale="en-us"
      open={openCustomRange}
      showCompare={false}
      onUpdate={(values) => {
        if (values.range.from && values.range.to) {
          if (values.range.from === values.range.to) {
            setCurrentRange({
              label: 'Custom Range',
              interval: '1 hour',
              period: { hours: 24 },
              startDate: new Date(values.range.from),
              endDate: new Date(values.range.to),
              key: 'selection',
            });
          } else {
            setCurrentRange({
              label: 'Custom Range',
              interval: '1 days',
              period: {
                days: differenceInDays(
                  new Date(values.range.to),
                  new Date(values.range.from),
                ),
              },
              startDate: new Date(values.range.from),
              endDate: new Date(values.range.to),
              key: 'selection',
            });
          }
        }
      }}
    />
  );
};
