import { axiosClient } from './axios-client';
import { GetUsers, UsersData } from '@/types';

export const UsersService = {
  getUsers: async ({
    projectId,
    page,
    channelId,
  }: GetUsers): Promise<UsersData> => {
    const queryParams = new URLSearchParams();
    if (page) {
      queryParams.append('page', page.toString());
    }
    if (channelId) {
      queryParams.append('channel', channelId);
    }

    const {
      data: { data },
    } = await axiosClient.get<{ data: UsersData }>(
      `/projects/${projectId}/users?${queryParams.toString()}`,
    );

    return data;
  },
};
