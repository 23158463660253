import { useForm } from 'react-hook-form';
// import {
//   Select,
//   SelectContent,
//   SelectItem,
//   SelectTrigger,
//   SelectValue,
// } from '@/components/ui/select';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { Label } from '@/components/ui/label';
import { Filters } from '@/components/search/filters';
import { Table } from '@/components/search/table';
import { sub } from 'date-fns';
import { forwardRef, useCallback, useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useProjects } from '@/hooks/use-projects';
import { EventsService, QueriesService } from '@/api';
import { createFileRoute } from '@tanstack/react-router';
import { eventNamesOptions } from '@/hooks/query-options';
import { SavedQueries } from '@/components/search/saved-queries';
import { SaveQueryModal } from '@/components/search/save-query-modal';

export const Route = createFileRoute('/_auth/_side-layout/search')({
  component: Search,
});

const QueryHeader = ({ queryName }: { queryName: string }) => {
  if (!queryName) {
    return null;
  }

  return (
    <div className="flex gap-3">
      <h2 className="text-xl font-bold md:text-4xl">
        {queryName ?? 'New query'}
      </h2>
    </div>
  );
};

const CustomDateInput = forwardRef(({ onClick, value }, ref) => (
  <Input ref={ref} value={value} onChange={onClick} onClick={onClick} />
));

function cleanUpFields(filters) {
  return filters?.fields
    ?.split(',')
    ?.map((x) => x.trim())
    .map((field) => {
      if (field[0] === '_') {
        const f = field.slice(1, field.length);
        if (f === 'metadata') {
          return { id: f, label: f, stringify: true };
        }
        return { id: f, label: f };
      }

      return { id: `metadata.${field}`, label: field };
    });
}

CustomDateInput.displayName = 'CustomDateInput';

export const defaultValues = {
  fields: '_id, _createdAt, _name, _userId, _metadata',
  groups: [
    {
      filters: [{}],
    },
  ],
};

const FIELDS = [
  '_id',
  '_name',
  '_project',
  '_userId',
  '_createdAt',
  '_location_country',
  '_location_city',
  '_location_region',
  '_location_lon',
  '_location_lat',
  '_device',
  '_os',
  '_browser',
  '_screen',
  '_language',
  '_referrer',
  '_url',
  '_hostname',
];

function Search() {
  const { projectId } = useProjects();
  if (!projectId) {
    return null;
  }
  const [startDate, setStartDate] = useState(sub(new Date(), { months: 1 }));
  const [endDate, setEndDate] = useState(null);
  const [cursors, setCursors] = useState([null]);
  const [cursor, setCursor] = useState(0);
  const [events, setEvents] = useState([]);
  const [filters, setFilters] = useState(null);
  const [selectedQuery, setSelectedQuery] = useState(null);

  const {
    control,
    register,
    handleSubmit,
    getValues,
    errors,
    watch,
    reset,
    setValue,
  } = useForm({
    defaultValues,
  });
  const onSubmit = (data) => {
    setFilters(data);
    setCursor(0);
    setCursors([null]);
  };

  useEffect(() => {
    setCursor(0);
    setCursors([null]);
    setEvents([]);
  }, [projectId]);

  useEffect(() => {
    EventsService.searchEvents({
      cursor: cursors[cursor],
      projectId: projectId,
      startDate: startDate,
      endDate: endDate,
      ...filters,
    }).then((data) => {
      if (data.cursor && !cursors.includes(data.cursor)) {
        const newCursors = [...cursors, data.cursor];
        setCursors(newCursors);
      }
      setEvents(data.events);
    });
  }, [cursors, cursor, startDate, endDate, projectId, filters]);

  const onPrev = useCallback(() => {
    setCursor(cursor - 1);
  }, [cursor, setCursor]);

  const onNext = useCallback(() => {
    setCursor(cursor + 1);
  }, [setCursor, cursor]);

  const { data } = useSuspenseQuery(eventNamesOptions({ projectId }));

  // const { data: queriesData, isLoading: loadingQueries } = useQuery(
  const { data: queriesData } = useSuspenseQuery({
    queryKey: ['queries', projectId],
    queryFn: async () => QueriesService.getQueries({ projectId }),
  });

  const [queries, setQueries] = useState([]);

  useEffect(() => {
    setQueries(queriesData?.queries ?? []);
  }, [queriesData]);

  return (
    <>
      <div className="flex flex-col gap-3 md:flex-row">
        <div className="flex flex-col md:min-h-screen md:w-1/5">
          <SavedQueries
            queries={queries}
            onDelete={(query) => {
              const updatedQueries = queries.filter((q) => q.id !== query.id);
              setQueries(updatedQueries);

              if (selectedQuery.id === query.id) {
                setSelectedQuery(updatedQueries[0] ?? null);
                reset(updatedQueries[0]?.filters ?? defaultValues);
              }

              QueriesService.saveQueries({
                projectId,
                queries: updatedQueries,
              }).catch(console.error);
            }}
            onSelect={(query) => {
              reset(query.filters);
              setSelectedQuery(query);
            }}
          />
        </div>
        <div className="flex flex-1 flex-col gap-3 md:w-3/4">
          <QueryHeader queryName={selectedQuery?.name} />
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-4">
              {/* <div className="bg-background rounded-lg p-3 flex flex-col gap-3 p-4"> */}
              <div className="flex flex-col gap-3 rounded-lg p-4">
                <div className="grid grid-cols-2 gap-3 md:grid-cols-4">
                  <div>
                    <Label htmlFor="user_id">User ID</Label>
                    <Input
                      id="user_id"
                      placeholder="user@email.com"
                      required=""
                      type="text"
                      {...register('userId')}
                    />
                  </div>
                  <div>
                    <Label htmlFor="event_name">Event</Label>
                    <select
                      id="event_name"
                      {...register('eventName')}
                      className="flex h-9 w-full items-center justify-between whitespace-nowrap rounded border border-input bg-transparent px-3 py-2 shadow-sm ring-offset-background placeholder:text-muted-foreground hover:border-primary hover:text-primary disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1"
                    >
                      <option value={''}>Show all</option>
                      {data?.map((event) => (
                        <option key={event.name} value={event.name}>
                          {event.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <div>
                    <Label htmlFor="start_date">Start Date</Label>
                    <DatePicker
                      customInput={<CustomDateInput />}
                      maxDate={endDate}
                      selected={startDate}
                      value={startDate}
                      onChange={(date) => setStartDate(date)}
                    />
                  </div>
                  <div>
                    <Label htmlFor="end_date">End Date</Label>
                    <DatePicker
                      customInput={<CustomDateInput />}
                      minDate={startDate}
                      selected={endDate}
                      value={endDate}
                      onChange={(date) => setEndDate(date)}
                    />
                  </div>
                </div>
                <div>
                  <Label htmlFor="fields">Fields</Label>
                  <Input
                    id="fields"
                    placeholder="orderId, amount, purchasedAt"
                    required=""
                    type="text"
                    {...register('fields')}
                  />
                  <p className="mt-2 text-xs text-gray-500">
                    Use internal fields {FIELDS.join(', ')} or list fields from
                    metadata e.g. orderId. You can specify path to nested field
                    e.g. order.summary.total.
                  </p>
                </div>
                <Filters
                  {...{
                    watch,
                    control,
                    register,
                    defaultValues,
                    getValues,
                    setValue,
                    errors,
                    reset,
                  }}
                />
                <div className="flex gap-3 border-t py-3">
                  <Button>Run Query</Button>
                  <SaveQueryModal
                    getValues={getValues}
                    projectId={projectId}
                    queries={queries}
                    setQueries={setQueries}
                  />
                </div>
              </div>
            </div>
          </form>
          <Table
            data={events}
            keys={cleanUpFields(filters) ?? []}
            next={cursor + 1 < cursors.length}
            prev={cursor > 0}
            onNext={onNext}
            onPrev={onPrev}
          />
        </div>
      </div>
    </>
  );
}
