import { createFileRoute, Link } from '@tanstack/react-router';

export const Route = createFileRoute('/_auth/_side-layout/payments/cancel')({
  component: PaymentsCancel,
});

function PaymentsCancel() {
  return (
    <>
      <h2 className="text-2xl font-bold">Payment Cancelled 😔</h2>

      <Link to="/">Go Back Home</Link>
    </>
  );
}
