import { createFileRoute, Outlet, redirect } from '@tanstack/react-router';
import {
  accountDetailsOptions,
  projectsOptions,
  channelsOptions,
} from '@/hooks/query-options';

export const Route = createFileRoute('/_auth')({
  // Before loading, authenticate the user via our auth context
  // This will also happen during prefetching (e.g. hovering over links, etc)
  beforeLoad: async ({ context, location }) => {
    if (!context.isAuthenticated()) {
      throw redirect({
        to: '/sign-in',
        search: {
          // Use the current location to power a redirect after login
          // (Do not use `router.state.resolvedLocation` as it can
          // potentially lag behind the actual current location)
          redirect: location.href,
        },
      });
    }
    context.queryClient.prefetchQuery(accountDetailsOptions());
    const projects =
      await context.queryClient.ensureQueryData(projectsOptions());

    if (projects.length) {
      const validProjectId = projects.find(
        (p) => p.projectId === context.projectId,
      )
        ? context.projectId
        : projects[0]?.projectId || null;
      if (validProjectId !== context.projectId) {
        context.setProjectId(validProjectId);
        context.projectId = validProjectId;
      }
    }

    if (!context.projectId) {
      return;
    }
    context.queryClient.prefetchQuery(
      channelsOptions({ projectId: context.projectId }),
    );
  },
  component: Auth,
});

function Auth() {
  return <Outlet />;
}
