import { differenceInHours, formatDistanceToNow } from 'date-fns';
import { Gear, Robot as RobotIcon } from '@phosphor-icons/react';
import { useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Button } from '@/components/ui/button';
import { AutomationsService } from '@/api';
import type { Robot as RobotProps } from '@/types';
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTrigger,
  DialogClose,
} from '@/components/ui/dialog';
import { Card, CardContent } from '../ui/card';

const getRobotColor = (robotType: string) => {
  switch (robotType) {
    case 'Slack':
      return 'bg-rose-600';
    case 'Telegram':
      return 'bg-blue-400';
    case 'Webhook':
      return 'bg-indigo-400';
    default:
      return 'bg-gray-400';
  }
};

const getBeepColor = (date: Date | null) => {
  if (date) {
    if (differenceInHours(new Date(), date) < 12) {
      return 'bg-green-400';
    } else if (
      differenceInHours(new Date(), date) >= 12 &&
      differenceInHours(new Date(), date) <= 3 * 24
    ) {
      return 'bg-orange-400';
    }
  }

  return 'bg-gray-400';
};

export const Robot = ({
  id,
  robot,
  Description,
  RobotDetails,
  robotType,
}: RobotProps) => {
  const { automationId, lastActivity, projectId } = robot;
  const queryClient = useQueryClient();
  const onAutomationDelete = useCallback(
    async (automationId: string) => {
      await AutomationsService.deleteAutomation({ automationId });
      queryClient.invalidateQueries({
        queryKey: ['automations', projectId],
      });
    },
    [projectId, queryClient],
  );
  const lastBeep = lastActivity ? new Date(lastActivity) : null;
  return (
    <Card>
      <CardContent className="flex flex-col justify-between gap-3 p-5">
        <Dialog>
          <>
            <div className="flex items-center justify-between">
              <div className="flex items-center gap-3">
                <RobotIcon className="text-foreground" size={45} />
                <div className="flex flex-col gap-1">
                  <span className="font-bold">Robot No. {id}</span>
                  <div className="flex">
                    <span
                      className={`rounded px-2 text-sm font-bold uppercase leading-5 text-white ${getRobotColor(robotType)}`}
                    >
                      {robotType}
                    </span>
                  </div>
                </div>
              </div>

              <div className="flex items-center gap-2 ">
                <DialogTrigger>
                  <Gear
                    className="cursor-pointer text-foreground hover:text-primary"
                    size={20}
                  />
                </DialogTrigger>
              </div>
            </div>

            <div className="flex flex-1 items-start">
              <Description />
            </div>

            <div className="flex items-center gap-2">
              <div className={`size-4 rounded-md ${getBeepColor(lastBeep)}`} />
              <span className="text-black">
                Last beep:{' '}
                {lastBeep
                  ? formatDistanceToNow(lastBeep, { addSuffix: true })
                  : 'never'}
              </span>
            </div>
          </>
          <DialogContent className="max-w-xl">
            <DialogHeader>
              <div className="flex items-center gap-3">
                <RobotIcon className="text-primary" size={45} />
                <div className="flex flex-col gap-1">
                  <span className="font-bold">Robot No. {id}</span>
                  <div className="flex">
                    <span
                      className={`rounded px-2 text-sm font-bold uppercase leading-5 text-white ${getRobotColor(robotType)}`}
                    >
                      {robotType}
                    </span>
                  </div>
                </div>
              </div>
            </DialogHeader>
            <div>{<RobotDetails />}</div>
            <DialogFooter className="flex flex-col gap-3 sm:flex-row sm:gap-1">
              <DialogClose asChild>
                <Button type="button" variant="outline">
                  Close
                </Button>
              </DialogClose>
              <DialogClose asChild>
                <Button
                  variant="destructive"
                  onClick={() => {
                    return onAutomationDelete(automationId);
                  }}
                >
                  Delete Robot
                </Button>
              </DialogClose>
            </DialogFooter>
          </DialogContent>
        </Dialog>
      </CardContent>
    </Card>
  );
};
