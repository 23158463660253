import { useState } from 'react';
import { Code } from '@/components/code';
import { useProjects } from '@/hooks/use-projects';
import { Playground } from '@/routes/_auth/_side-layout/playground';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '@/components/ui/select';
import { toTitleCase } from '@/utils/to-title-case';

const REACT_CODE = (publicKey: string) => `import Logspot from "@logspot/web";

Logspot.init({ publicKey: "pk_${publicKey}" });

export default function App() {
  return (
    <div>
      <h1>My App</h1>
      <button onClick={() => Logspot.track("button-click")}>Click me</button>
    </div>
  )

`;

const NEXT_JS_CODE = (publicKey: string) => `import Logspot from "@logspot/web";

function MyApp() {
  useEffect(() => {
    Logspot.init({ publicKey: "pk_${publicKey}" });
  }, []);

  return (...);
}
`;

// translate Next.js code to Vue code
const VUE_CODE = (publicKey: string) => `import Logspot from "@logspot/web";

export default {
  mounted() {
    Logspot.init({ publicKey: "pk_${publicKey}" });
  },
};
`;

// translate Next.js code to Angular code
const ANGULAR_CODE = (publicKey: string) => `import Logspot from "@logspot/web";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.css"],
})
export class AppComponent implements OnInit {
  ngOnInit() {
    Logspot.init({ publicKey: "pk_${publicKey}" });
  }
}
`;

const NEXT_JS_EVENT_CODE = (
  publicKey: string,
) => `import Logspot from "@logspot/web";

Logspot.init({ publicKey: "pk_${publicKey}" });

Logspot.track({
  event: "UserSubscribed",
  metadata: { additionalData: "123" },
});`;

const NODE_CODE = (secretKey: string) => `import Logspot from "@logspot/node";

Logspot.init({ secretKey: "sk_${secretKey}" });

Logspot.track({
  event: "UserSubscribed",
  userId: "john@doe.com",
  metadata: { additionalData: "123" },
});`;

const HTTP_CODE = (secretKey: string) => `POST https://api.logspot.io/track

// HEADERS
{
  "x-logspot-sk": "sk_${secretKey}"
}

// BODY
{
  name: "UserSubscribed",
  user_id: "john@doe.com",
  metadata: { additionalData: "123" },
}`;

// translate HTTP_CODE to Python
const PYTHON_CODE = (secretKey: string) => `import requests

url = "https://api.logspot.io/track"

payload = {
  "name": "UserSubscribed",
  "user_id": "john@doe.com",
  "metadata": { "additionalData": "123" },
}

headers = {
  'x-logspot-sk': 'sk_${secretKey}',
}
    
response = requests.request("POST", url, headers=headers, data=payload)
`;

// translate HTTP_CODE to Go
const GO_CODE = (secretKey: string) => `package main

import (
	"bytes"
	"encoding/json"
	"net/http"
)

func main() {
	url := "https://api.logspot.io/track"

	data := map[string]interface{}{
		"name": "UserSubscribed",
		"user_id": "john@doe.com",
		"metadata": map[string]interface{}{
			"additionalData": "123",
		},
	}

	jsonData, _ := json.Marshal(data)

	req, _ := http.NewRequest("POST", url, bytes.NewBuffer(jsonData))
	req.Header.Set("x-logspot-sk", "sk_${secretKey}")
	req.Header.Set("Content-Type", "application/json")

	client := &http.Client{}
	resp, _ := client.Do(req)
	defer resp.Body.Close()
}
`;

const PHP_CODE = (secretKey: string) => `<?php

$url = "https://api.logspot.io/track";

$headers = array(
    "x-logspot-sk: sk_${secretKey}",
    "Content-Type: application/json"
);

$data = array(
    "name" => "UserSubscribed",
    "user_id" => "john@doe.com",
    "metadata" => array(
        "additionalData" => "123"
    )
);

$SelectItems = array(
    "http" => array(
        "header"  => $headers,
        "method"  => "POST",
        "content" => json_encode($data)
    )
);

$context  = stream_context_create($SelectItems);
$result = file_get_contents($url, false, $context);

?>
`;

// translate HTTP_CODE to Ruby - clean simple code, use json gem
const RUBY_CODE = (secretKey: string) => `require 'net/http'
require 'uri'
require 'json'

uri = URI.parse("https://api.logspot.io/track")

request = Net::HTTP::Post.new(uri)
request["x-logspot-sk"] = "sk_${secretKey}"
request["Content-Type"] = "application/json"
request.body = {
  name: "UserSubscribed",
  user_id: "john@doe.com",
  metadata: { additionalData: "123" },
}.to_json

response = Net::HTTP.start(uri.hostname, uri.port, use_ssl: true) do |http|
  http.request(request)
end
`;

const WEB_CODE = (publicKey: string) =>
  `<script async data-logspot-pk="pk_${publicKey}" src="https://cdn.logspot.io/lg.js"></script>`;

export function TrackingOptions() {
  const { project } = useProjects();
  const [type, setType] = useState('web');
  if (!project) return null;

  return (
    <div className="flex flex-col">
      <div>
        <Select value={type} onValueChange={(e) => setType(e)}>
          <SelectTrigger>
            <SelectValue placeholder={type || 'Select an Option'}>
              {toTitleCase(type)}
            </SelectValue>
          </SelectTrigger>
          <SelectContent>
            <SelectItem value="web">Web</SelectItem>
            <SelectItem value="react">React</SelectItem>
            <SelectItem value="next.js">Next.js</SelectItem>
            <SelectItem value="vue">Vue</SelectItem>
            <SelectItem value="angular">Angular</SelectItem>
            <SelectItem value="node">Node.js</SelectItem>
            <SelectItem value="python">Python</SelectItem>
            <SelectItem value="go">Go</SelectItem>
            <SelectItem value="ruby">Ruby</SelectItem>
            <SelectItem value="php">PHP</SelectItem>
            <SelectItem value="http">REST API</SelectItem>
            <SelectItem value="playground">Playground</SelectItem>
          </SelectContent>
        </Select>
      </div>
      <div className="mt-6">
        {type === 'playground' && <Playground />}
        <div className={type === 'web' ? 'block' : 'hidden'}>
          <p className="my-3">
            Add the following code to the{' '}
            <span className="rounded bg-muted px-3 py-1 text-sm font-bold text-primary">
              {'<head>'}
            </span>{' '}
            of your page
          </p>
          <Code code={WEB_CODE(project.publicKey)} />
        </div>
        <div className={type === 'react' ? 'block' : 'hidden'}>
          <Code code={REACT_CODE(project.publicKey)} />
        </div>
        <div
          className={`flex flex-col gap-5 ${
            type === 'next.js' ? 'block' : 'hidden'
          }`}
        >
          <p>
            For Logspot initialization on the client side, add the following
            snippet to your _app.js file:
          </p>
          <Code code={NEXT_JS_CODE(project.secretKey)} />
          <p>Event tracking code:</p>
          <Code code={NEXT_JS_EVENT_CODE(project.publicKey)} />
        </div>
        <div className={type === 'node' ? 'block' : 'hidden'}>
          <Code code={NODE_CODE(project.secretKey)} />
        </div>
        <div className={type === 'vue' ? 'block' : 'hidden'}>
          <Code code={VUE_CODE(project.publicKey)} />
        </div>
        <div className={type === 'angular' ? 'block' : 'hidden'}>
          <Code code={ANGULAR_CODE(project.publicKey)} />
        </div>
        <div className={type === 'python' ? 'block' : 'hidden'}>
          <Code code={PYTHON_CODE(project.secretKey)} />
        </div>
        <div className={type === 'go' ? 'block' : 'hidden'}>
          <Code code={GO_CODE(project.secretKey)} />
        </div>
        <div className={type === 'ruby' ? 'block' : 'hidden'}>
          <Code code={RUBY_CODE(project.secretKey)} />
        </div>
        <div className={type === 'php' ? 'block' : 'hidden'}>
          <Code code={PHP_CODE(project.secretKey)} />
        </div>
        <div className={type === 'http' ? 'block' : 'hidden'}>
          <Code code={HTTP_CODE(project.publicKey)} />
        </div>
      </div>
    </div>
  );
}
