import { SearchEvents } from '@/types';
import { axiosClient, optionalQuery } from './axios-client';
import {
  DayMetrics,
  Event,
  EventKeyValues,
  UserEvents,
  AllEventCounts,
} from '@/types';

export const EventsService = {
  getEventProperties: async ({ projectId, channelId }: Event) => {
    // build query params
    const queryParams = new URLSearchParams();
    queryParams.append('projectId', projectId);
    if (channelId) {
      queryParams.append('channel', channelId);
    }

    const resp = await axiosClient.get(
      `/events/properties?${queryParams.toString()}`,
    );

    return resp.data.data;
  },

  getEventKeyValues: async ({ projectId, key, channelId }: EventKeyValues) => {
    // build query params
    const queryParams = new URLSearchParams();
    queryParams.append('projectId', projectId);
    if (key) {
      queryParams.append('key', key);
    }
    if (channelId) {
      queryParams.append('channel', channelId);
    }

    const resp = await axiosClient.get(
      `/events/key-values?${queryParams.toString()}`,
    );

    return resp.data.data;
  },

  getEventDetails: async ({ id }: { id: string }) => {
    const resp = await axiosClient.get(`/events/${id}`);
    return resp.data.data;
  },

  getEventNames: async ({ projectId, channelId }: Event) => {
    const resp = await axiosClient.get(
      `/events/names?projectId=${projectId}${
        channelId ? `&channel=${channelId}` : ''
      }`,
    );
    return resp.data.data;
  },

  getUserEvents: async ({ projectId, userId }: UserEvents) => {
    const resp = await axiosClient.get(
      `/events?projectId=${projectId}&userId=${userId}`,
    );
    return resp.data.data.events;
  },

  getEvents: async ({ slug }: { slug: string }) => {
    const resp = await axiosClient.get(`/events?slug=${slug}`);
    return resp.data.data.events;
  },

  searchEvents: async ({
    projectId,
    cursor,
    userId = null,
    eventName,
    startDate,
    endDate,
    filterGroups,
    channelId,
  }: SearchEvents) => {
    const getNonEmpty = (val: string | undefined | null) =>
      val?.length ? val : undefined;
    const {
      data: {
        data: { cursor: nextCursor, events },
      },
    } = await axiosClient.post(`/events/search`, {
      event_name: getNonEmpty(eventName),
      user_id: getNonEmpty(userId),
      cursor,
      project_id: projectId,
      start_date: startDate,
      end_date: endDate,
      filter_groups: filterGroups,
      channelId,
    });
    return { cursor: nextCursor, events };
  },

  getEventCounts: async ({ slug }: { slug: string | undefined }) => {
    const resp = await axiosClient.get(`/events/count?slug=${slug}`);
    return resp.data.data;
  },

  getAllEventCounts: async ({
    projectId,
    userId,
    interval,
    startDate,
    filterGroups,
    endDate,
    channelId,
  }: AllEventCounts) => {
    const {
      data: { data },
    } = await axiosClient.post(
      `/events/counts?${[
        optionalQuery('project', projectId),
        optionalQuery('userId', userId),
        optionalQuery('startDate', startDate),
        optionalQuery('endDate', endDate),
        optionalQuery('interval', encodeURI(interval)),
        optionalQuery('channel', channelId),
      ].join('&')}`,
      { filter_groups: filterGroups },
    );
    const reduced = data.reduce(
      (acc: Record<string, DayMetrics[]>, entry: DayMetrics) => {
        const dayMetrics = {
          name: entry.name,
          date: entry.date,
          count: entry.count,
        };

        if (acc[entry.name]) {
          acc[entry.name] = [...acc[entry.name], dayMetrics];
        } else {
          acc[entry.name] = [dayMetrics];
        }

        return acc;
      },
      {},
    );
    return reduced;
  },
};
