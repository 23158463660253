import { useState } from 'react';
import { ChannelDropdown } from './channel-dropdown';
import { ProjectDropdown } from './project-dropdown';
import { List, X } from '@phosphor-icons/react';
import { NavMenu } from '@/components/nav/nav-menu';
import { navLinks, basicNavLinks } from './nav-menu/nav-links';
import { useAccountDetails } from '@/hooks/use-account-details';
import { isDemo } from '@/api';
import { Divider } from '@/components/ui/divider';

export const MobileMenu = ({ fullMenu = true }) => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const { account, isOnFreePlan } = useAccountDetails();
  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };
  const mobileLinks = fullMenu ? navLinks : basicNavLinks;
  const mobileNavLinks = mobileLinks.map((link) => ({
    ...link,
    onClick: toggleMobileMenu,
  }));

  return (
    <div className="relative w-full">
      <div
        className="w-full text-gray-700 focus:outline-none"
        onClick={toggleMobileMenu}
      >
        <List size={20} />
      </div>
      {mobileMenuOpen && (
        <div className="fixed left-0 top-0 z-50 size-full overflow-auto bg-background py-4">
          <div className="flex w-full justify-end px-4">
            <div
              className="text-gray-700 focus:outline-none"
              onClick={toggleMobileMenu}
            >
              <X size={20} />
            </div>
          </div>
          <NavMenu
            isCollapsed={false}
            isDemo={isDemo()}
            isOnFreePlan={isOnFreePlan}
            links={mobileNavLinks}
            organizations={[
              {
                label: account?.email || 'Your Organization',
                email: account?.email || 'Your Organization',
              },
            ]}
            onClick={toggleMobileMenu}
          />
          {fullMenu && (
            <>
              <Divider />
              <div className="flex flex-col gap-6 px-4">
                <div>
                  <h3 className="mb-3 font-medium">Projects</h3>
                  <ProjectDropdown type="button" />
                </div>
                <div>
                  <h3 className="mb-3 font-medium">Channels</h3>
                  <ChannelDropdown type="button" />
                </div>
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
};
