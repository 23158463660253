import { channelIdAtom } from '@/state/atoms';
import { useAtom } from 'jotai';
import { useSuspenseQuery } from '@tanstack/react-query';
import { channelsOptions } from './query-options';
import { useMemo } from 'react';
import { useProjects } from './use-projects';
import { Channel } from '@/types';
import _ from 'lodash';

/**
 * @param channelId
 * @param channels
 * @param projectId
 * @returns String | null
 *
 *   This function returns the channelId if it exists in the channels array and
 *   the projectId matches. Other wise it returns null.
 */
function getChannelId(
  channelId: string | null,
  channels: Channel[] | null,
  projectId: string | null,
) {
  if (!channelId || !channels?.length || !projectId) return null;
  // Find a channel matching both channelId and projectId
  const match = _.find(channels, { channelId, projectId });
  // Return channelId if a matching channel is found, null otherwise
  return match ? channelId : null;
}

export const useChannels = () => {
  const { projectId } = useProjects();
  const [localChannelId, setChannelId] = useAtom(channelIdAtom);

  const { data: channels } = useSuspenseQuery({
    ...channelsOptions({ projectId: projectId! }),
  });

  const channelId = getChannelId(localChannelId, channels, projectId);
  const channelMatch = _.find(
    channels,
    (c: Channel) => c?.channelId === channelId,
  );
  const channel = channelMatch || {
    name: 'All',
    channelId: null,
    projectId: null,
  };

  return useMemo(
    () => ({
      channel,
      channels,
      channelId,
      setChannelId,
    }),
    [channelId, channels, channel],
  );
};
