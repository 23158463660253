import _ from 'lodash';
import logspot from '@logspot/web';
import {
  Select,
  SelectTrigger,
  SelectContent,
  SelectItem,
  SelectValue,
} from '@/components/ui/select';
import { useProjects } from '@/hooks/use-projects';

export const ProjectSelect = () => {
  const { project, projects, setProjectId } = useProjects();

  const sorted = _.sortBy(projects, (p) => p.name);

  return (
    <>
      {sorted.length ? (
        <Select
          onValueChange={(val: string) => {
            logspot.register({ projectId: val });
            return setProjectId(val);
          }}
        >
          <SelectTrigger>
            <SelectValue placeholder={project?.name || 'Select a Project'}>
              {project?.name}
            </SelectValue>
          </SelectTrigger>
          <SelectContent>
            {sorted.map((p) => (
              <SelectItem key={p.projectId} value={p.projectId}>
                {p.name}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      ) : (
        <></>
      )}
    </>
  );
};
