import { useProjects } from '@/hooks/use-projects';
import { HiddenKey } from '@/components/hidden-key';
import { SectionHeader } from '@/components/section-header';
import { Label } from '@/components/ui/label';
import { Code } from './code';

export const ApiKeys = () => {
  const { projectId, project } = useProjects();
  if (!projectId || !project) {
    return null;
  }

  return (
    <div className="mt-6 space-y-6">
      <SectionHeader
        subTitle="API keys are used to authenticate your requests to Logspot. Keep your secret key secure."
        title="API Keys"
      />
      <div className="space-y-3">
        <Label>Public Key</Label>
        <Code
          className="p-1"
          clipboard={false}
          code={`pk_${project.publicKey}`}
        />
      </div>
      <div className="space-y-3">
        <Label>Secret Key</Label>
        <HiddenKey code={`sk_${project.secretKey}`} />
      </div>
    </div>
  );
};
