import { useEffect, useMemo, useState } from 'react';
import {
  FIELDS_DATA as DEFAULT_EVENT_PROPERTIES,
  useFilterDialog,
} from '@/hooks/use-filter-dialog';
import Fuse from 'fuse.js';
import { useFilters } from '@/hooks/use-filters';
import { useQuery } from '@tanstack/react-query';
import { EventsService } from '@/api';
import { useProjects } from '@/hooks/use-projects';
import { useChannels } from '@/hooks/use-channels';
import { Button } from '@/components/ui/button';
import { Field } from '@/types';
import { toTitleCase } from '@/utils/to-title-case';
import { Input } from '@/components/ui/input';
import { Loader } from './ui/loader';

type FieldProps = {
  search: string;
  setSearch: (value: string) => void;
  handleFieldClick: (field: Field) => void;
  filteredItems: Field[];
};

export const Operator = {
  EQ: '=',
  GTE: '>=',
  LTE: '<=',
  LT: '<',
  GT: '>',
  NEQ: '<>',
  NULL: 'IS NULL',
  NOT_NULL: 'IS NOT NULL',
};

const Fields = ({
  search,
  setSearch,
  handleFieldClick,
  filteredItems,
}: FieldProps) => (
  <>
    <div className="px-4">
      <Input
        className="rounded-b-none"
        placeholder="Search"
        type="text"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
      />
    </div>
    <ul className=" max-h-96 overflow-y-auto">
      {filteredItems.map((item, idx) => (
        <li
          key={item.field + idx}
          className="cursor-pointer border-b border-border px-4 py-2"
          onClick={() => handleFieldClick(item)}
        >
          <div className="group">
            <h4 className="font-bold group-hover:text-primary">{item.label}</h4>
            <p className="text-muted-foreground group-hover:text-primary">
              {item.description}
            </p>
          </div>
        </li>
      ))}
    </ul>
  </>
);

const Operators = ({
  handleOperatorClick,
}: {
  handleOperatorClick: (operator: string) => void;
}) => (
  <>
    <ul className="max-h-96 overflow-y-auto">
      {Object.values(Operator).map((operator) => (
        <li
          key={operator}
          className="cursor-pointer border-b border-border px-4 py-2 hover:text-primary"
          onClick={() => handleOperatorClick(operator)}
        >
          {operator}
        </li>
      ))}
    </ul>
  </>
);

const Values = ({
  handleValueClick,
}: {
  handleValueClick: (value: string) => void;
}) => {
  const { projectId } = useProjects();
  if (!projectId) {
    return null;
  }
  const { channelId } = useChannels();
  const { selectedField, searchValue, setSearchValue } = useFilterDialog();
  const fieldKey = selectedField?.field || null;
  const { isLoading, data } = useQuery({
    queryKey: ['key-values', selectedField],
    queryFn: () =>
      EventsService.getEventKeyValues({
        projectId,
        key: fieldKey,
        channelId,
      }),
  });

  const mapped = useMemo(() => {
    if (!data) return [];

    return data
      .filter((val: string) => {
        if (selectedField?.field === 'referrer') {
          // check if val is full domain without subpath
          try {
            const url = new URL(val);
            return url.pathname === '/';
          } catch (err) {
            console.error(err);
            return false;
          }
        } else return true;
      })
      .filter((val: string) => val !== null)
      .map((item: Field) => ({
        label: item,
        value: item,
      }));
  }, [data]);

  const fuse = new Fuse(mapped, {
    keys: ['label'],
    threshold: 0.3,
  });
  const results = fuse.search(searchValue);
  const filteredItems = searchValue.length
    ? results.map((res) => res.item)
    : mapped;
  return (
    <>
      <div className="px-4">
        <Input
          placeholder="Search"
          type="text"
          value={searchValue}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </div>
      <ul className="max-h-96 overflow-y-auto">
        {isLoading && <Loader />}
        {filteredItems?.map(
          ({ value, idx }: { value: string; idx: number }) => (
            <li
              key={value + idx}
              className="cursor-pointer border-b border-border px-4 py-2 text-xs hover:text-primary"
              onClick={() => handleValueClick(value)}
            >
              {value === null ? 'null' : value}
            </li>
          ),
        )}
      </ul>
    </>
  );
};

export const FilterDialogButton = () => {
  const { projectId } = useProjects();
  if (!projectId) {
    return null;
  }
  const { channelId } = useChannels();

  const [isOpen, setIsOpen] = useState(false);
  const {
    search,
    searchValue,
    setSearch,
    filteredItems,
    setFilteredItems,
    selectedField,
    setSelectedField,
    selectedOperator,
    setSelectedOperator,
    setSelectedValue,
    selectedValue,
    reset,
    currentTab,
    setCurrentTab,
  } = useFilterDialog();
  const { addFilter } = useFilters();

  const fieldsQuery = useQuery({
    queryKey: ['fields', projectId, channelId],
    queryFn: () =>
      EventsService.getEventProperties({
        projectId,
        channelId,
      }),
  });

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (fieldsQuery.data) {
      const customFields =
        fieldsQuery.data?.map((key: string) => ({
          field: key,
          label: toTitleCase(key),
          description: key,
          custom: true,
        })) ?? [];

      const fields = [...DEFAULT_EVENT_PROPERTIES, ...customFields];

      const fuseOptions = {
        keys: ['label', 'description'],
        threshold: 0.3,
      };

      const fuse = new Fuse(fields, fuseOptions);

      const result = search?.length
        ? fuse.search(search).map((res) => res.item)
        : fields;

      setFilteredItems(result);
    }
  }, [fieldsQuery.data, search, setFilteredItems]);

  useEffect(() => {
    setSearch('');
  }, [isOpen, currentTab]);

  const handleFieldClick = (field: Field) => {
    setSelectedField(field);
    setCurrentTab('operators');
  };

  const handleOperatorClick = (operator: string) => {
    setSelectedOperator(operator);
    if (operator === Operator.NULL || operator === Operator.NOT_NULL) {
      return;
    }
    setCurrentTab('values');
  };

  const handleValueClick = (value: string) => {
    setSelectedValue(value);
    setSearch('');
  };

  const handleApply = () => {
    const nonValueOperator =
      selectedOperator === Operator.NULL ||
      selectedOperator === Operator.NOT_NULL;

    const value = nonValueOperator ? undefined : selectedValue ?? searchValue;

    addFilter({
      field: selectedField?.field,
      operator: selectedOperator,
      value: value,
    });
    reset();
    toggleModal();
  };

  const canApply =
    selectedField &&
    selectedField.field &&
    selectedOperator &&
    (selectedValue ||
      searchValue ||
      selectedOperator === Operator.NULL ||
      selectedOperator === Operator.NOT_NULL);

  return (
    <div className="relative">
      <Button variant="outline" onClick={toggleModal}>
        Filter
      </Button>
      {isOpen && (
        <div className="absolute left-0 z-50 mt-2 min-w-[300px] max-w-[400px] rounded border bg-background shadow-2xl">
          <div className="flex gap-2 px-4 py-2">
            {[
              {
                label: 'Field',
                value: selectedField?.label,
                tab: 'fields',
              },
              {
                label: 'Operator',
                value: selectedOperator,
                tab: 'operators',
              },
              {
                label: 'Value',
                value: selectedValue,
                tab: 'values',
              },
              // ].map(({ label, value, tab }) =>
            ].map(({ value, tab }) =>
              value ? (
                <Button
                  key={tab}
                  size="sm"
                  variant="outline"
                  onClick={() => setCurrentTab(tab)}
                >
                  {toTitleCase(value)}
                </Button>
              ) : null,
            )}
          </div>
          <div>
            {currentTab === 'fields' && (
              <Fields
                {...{
                  search,
                  setSearch,
                  handleFieldClick,
                  filteredItems,
                }}
              />
            )}

            {currentTab === 'operators' && (
              <Operators
                {...{
                  handleOperatorClick,
                }}
              />
            )}

            {currentTab === 'values' && (
              <Values
                {...{
                  search,
                  setSearch,
                  handleValueClick,
                }}
              />
            )}
          </div>
          <Button
            className="block w-full rounded-t-none px-4 py-2"
            disabled={!canApply}
            type="button"
            onClick={handleApply}
          >
            Apply
          </Button>
        </div>
      )}
    </div>
  );
};
