import { Separator } from '@/components/ui/separator';
import { cn } from '@/utils/cn';
import type { ClassValue } from 'clsx';

export function SectionHeader({
  title,
  subTitle,
  separator = false,
  className,
}: {
  title: string;
  subTitle?: string;
  separator?: boolean;
  className?: ClassValue;
}) {
  return (
    <>
      <div className="space-y-0.5">
        <h2
          className={cn(
            'text-lg font-bold tracking-tight sm:text-xl',
            className,
          )}
        >
          {title}
        </h2>
        {subTitle && <p className="text-muted-foreground">{subTitle}</p>}
      </div>
      {separator && <Separator className="my-6" />}
    </>
  );
}
