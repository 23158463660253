import { useCallback, useState } from 'react';
import { PaymentService } from '@/api';
import { Badge } from './ui/badge';
import { Button } from './ui/button';

type PlanProps = {
  displayName: string;
  name: string;
  active: boolean;
  isCurrent: boolean;
  price: number;
  limit: number;
};

export const Plan = ({
  displayName,
  name,
  active,
  price,
  limit,
}: PlanProps) => {
  const [inProgress, setInProgress] = useState(false);
  const nextPlan = name === 'FREE' ? 'BASIC' : 'PRO';
  const showUpgrade = name !== nextPlan;
  const upgrade = useCallback(async (plan: string) => {
    setInProgress(true);
    const url = await PaymentService.getCheckoutUrl({
      plan: plan.toUpperCase(),
    });
    window.location.href = url;
  }, []);

  return (
    <div className={'flex items-center justify-between rounded'}>
      <div className="flex flex-col gap-2 md:flex-row md:items-center">
        <Badge className="px-4 py-2 font-extrabold uppercase" variant="neutral">
          <div className="flex items-center gap-2">
            <span>{displayName} </span>
            {!showUpgrade && (
              <Badge variant={active ? 'success' : 'error'}>
                {active ? 'Active' : 'Expired'}
              </Badge>
            )}
          </div>
        </Badge>
        <div className="">
          <span className="font-bold">{limit}</span> events / month
        </div>
      </div>
      <div className="flex items-center gap-2">
        <span className="text-xl font-bold">${price}/mo</span>
        {showUpgrade && (
          <Button
            loading={inProgress}
            size="lg"
            variant="outline"
            onClick={() => upgrade(name)}
          >
            {inProgress ? 'Upgrading...' : 'Choose Plan'}
          </Button>
        )}
      </div>
    </div>
  );
};
