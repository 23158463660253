import { axiosClient } from './axios-client';
import { Step, FilterGroup } from '@/types';

type CreateFunnel = {
  projectId: string;
  name: string;
  steps: Step[];
};

type DeleteFunnel = {
  projectId: string;
  id: string;
};

type CalculateFunnels = {
  projectId: string;
  steps: Step[];
  startDate: Date;
  endDate: Date;
  filterGroups: FilterGroup[];
  channelId?: string | null;
};

export const FunnelsService = {
  createNewFunnel: async ({ projectId, name, steps }: CreateFunnel) => {
    await axiosClient.post(`/funnels`, {
      name,
      steps: steps.filter((step) => step && step.step.length),
      projectId,
    });
  },

  deleteFunnel: async ({ projectId, id }: DeleteFunnel) => {
    await axiosClient.delete(`/funnels/project/${projectId}/${id}`);
  },

  calculateFunnels: async ({
    projectId,
    steps,
    startDate,
    endDate,
    filterGroups,
    channelId,
  }: CalculateFunnels) => {
    const {
      data: { data },
    } = await axiosClient.post(`/analytics/funnels`, {
      projectId,
      steps: steps.filter((s) => !!s),
      startDate,
      endDate,
      filterGroups,
      channel: channelId,
    });

    return data;
  },
};
