import { useProjects } from '@/hooks/use-projects';
import { AreaChart } from '@/components/charts/area-chart';
import { useQuery } from '@tanstack/react-query';
import { DailyActiveUsersService } from '@/api';
import {
  endOfDay,
  endOfHour,
  // intervalToDuration,
  // startOfDay,
  // startOfHour,
  sub,
} from 'date-fns';
import { ArrowDown, ArrowUp, Minus, Users } from '@phosphor-icons/react';
import { useFilters } from '@/hooks/use-filters';
import { Widget } from './widget';
import { useChannels } from '@/hooks/use-channels';
import { DateRange } from '@/types';
import { numberWithCommas } from '@/utils/number-with-commas';
import { Badge } from '../ui/badge';

function getVariant(diff: number) {
  if (diff < 0) {
    return 'error';
  } else if (diff > 0) {
    return 'success';
  } else {
    return 'neutral';
  }
}

export const DauWidget = ({ dateRange }: { dateRange: DateRange }) => {
  const { channelId } = useChannels();
  const { projectId } = useProjects();
  if (!projectId) {
    return null;
  }
  const { filterGroups } = useFilters();
  const prevDateRange = {
    interval: dateRange.interval,
    startDate: sub(dateRange.startDate, dateRange.period || { days: 1 }),
    endDate: dateRange.interval.includes('days')
      ? endOfDay(dateRange.startDate)
      : endOfHour(dateRange.startDate),
  };

  // const { isLoading, error, data } = useQuery(
  const { isLoading, data } = useQuery({
    queryKey: [`dau`, projectId, dateRange, filterGroups, channelId],
    queryFn: () =>
      DailyActiveUsersService.getDau({
        projectId,
        interval: dateRange.interval,
        startDate: dateRange.startDate,
        endDate: dateRange.endDate,
        filterGroups,
        channelId,
      }),
  });
  const { data: prevData } = useQuery({
    queryKey: [`prev-dau`, projectId, dateRange, filterGroups, channelId],
    queryFn: () =>
      DailyActiveUsersService.getDau({
        projectId,
        interval: prevDateRange.interval,
        startDate: prevDateRange.startDate,
        endDate: prevDateRange.endDate,
        filterGroups,
        channelId,
      }),
  });

  const currentTotal = parseInt(data?.total) || 0;
  const currentLive = parseInt(data?.live) || 0;
  const prevTotal = parseInt(prevData?.total) || 0;

  const diff =
    prevTotal === 0 ? 100 : ((currentTotal - prevTotal) * 100) / prevTotal;
  const formattedDiff = Math.abs(diff).toFixed(0);
  return (
    <Widget
      Header={() => (
        <>
          <div className="flex items-center justify-between">
            <span className="flex items-center gap-2 text-xl font-medium">
              <Users className="text-primary" />
              <span className="font-semibold">Unique Users</span>
            </span>
            <span className="flex items-center gap-2 ">
              {!!data && (
                <div className="space-x-3">
                  <span className="text-3xl font-black leading-none">
                    {numberWithCommas(currentTotal)}
                  </span>
                  <Badge variant={getVariant(diff)}>
                    {diff < 0 && <ArrowDown weight="bold" />}
                    {diff === 0 && <Minus weight="bold" />}
                    {diff > 0 && <ArrowUp weight="bold" />}
                    <span className="text-muted-foreground">
                      {formattedDiff}%
                    </span>
                  </Badge>
                </div>
              )}
            </span>
          </div>
        </>
      )}
      SubHeader={() => (
        <div className="-mt-3 ml-5 flex items-center gap-1">
          <div className="space-x-2">
            <div className="inline-block size-3 animate-pulse rounded-full bg-green-500"></div>
            <span className="text-xl font-bold">{currentLive} Online</span>
          </div>
        </div>
      )}
    >
      {!isLoading && (
        <AreaChart
          data={data?.perDay}
          dateRange={dateRange}
          prevData={prevData?.perDay}
          prevDateRange={prevDateRange}
        />
      )}
    </Widget>
  );
};
