import { useState } from 'react';
import { useSuspenseQuery } from '@tanstack/react-query';
import { AnalyticsService } from '@/api';
import { Button } from '@/components/ui/button';
import { useProjects } from '@/hooks/use-projects';
import _ from 'lodash';
import {
  Browsers,
  ChartBarHorizontal,
  City,
  Crosshair,
  Devices,
  Eye,
  Flag,
  FrameCorners,
  Funnel,
  PaperPlaneRight,
} from '@phosphor-icons/react';
import { useFilters } from '@/hooks/use-filters';
import { Widget } from './widget';
import { useChannels } from '@/hooks/use-channels';
import { AggregationWidget as AggregationWidgetProps } from '@/types';
import { EmptyState } from '../empty-state';
import { AppWindow } from '@phosphor-icons/react/dist/ssr';

const getIcon = (property: string | undefined) => {
  if (property === '_url') {
    return <Eye className="text-primary" />;
  }
  if (property === '_referrer') {
    return <PaperPlaneRight className="text-primary" />;
  }
  if (property === '_location_country') {
    return <Flag className="text-primary" />;
  }
  if (property === '_location_city') {
    return <City className="text-primary" />;
  }
  if (property === '_screen') {
    return <FrameCorners className="text-primary" />;
  }
  if (property === '_device') {
    return <Devices className="text-primary" />;
  }
  if (property === '_browser') {
    return <Browsers className="text-primary" />;
  }
  if (property === '_os') {
    return <AppWindow className="text-primary" />;
  }
  if (property === 'utm_source') {
    return <Crosshair className="text-primary" />;
  }
  return <ChartBarHorizontal className="text-primary" />;
};

const PAGE_SIZE = 6;
export const AggregationWidget = ({
  renderProperty,
  renderOptions,
  renderToggle,
  renderToggledContent,
  filterData,
  // internal,
  dateRange,
  params,
  onDelete,
}: AggregationWidgetProps) => {
  const { channelId } = useChannels();
  const [toggled, setToggled] = useState(false);
  const { filterGroups, addFilter, isActiveFilter } = useFilters();
  const { projectId } = useProjects();
  if (!projectId) {
    return null;
  }
  const [page, setPage] = useState(0);
  const isField = params?.property?.[0] === '_';
  const property = params?.property;
  const title = params?.title;
  const eventName = params?.eventName;
  const searchField = isField ? property?.slice(1, property?.length) : property;

  // const { isLoading, error, data } = useQuery(
  const { data } = useSuspenseQuery({
    queryKey: [
      `aggregation_per_property`,
      projectId,
      dateRange,
      params?.eventName,
      params?.property,
      filterGroups,
      channelId,
    ],
    queryFn: () => {
      return AnalyticsService.getAggregationByProperty({
        projectId,
        eventName,
        ...(isField && {
          field: property?.slice(1, property?.length),
        }),
        ...(!isField && { metadataField: property }),
        startDate: dateRange?.startDate,
        endDate: dateRange?.endDate,
        filterGroups,
        channelId,
      });
    },
  });

  let cleanedUp = [];

  if (filterData) {
    const result = filterData(data);
    if (Array.isArray(result)) {
      cleanedUp = result;
    } else {
      console.error('filterData did not return an array');
    }
  } else {
    cleanedUp = _.filter(
      data,
      (d) => !_.isNil(d.property) && !_.isEmpty(d.property),
    );
  }
  const max = cleanedUp.length > 0 ? parseInt(cleanedUp[0]?.count) : null;

  return (
    <Widget
      Header={() => (
        <>
          <div className="flex items-center justify-between ">
            <span className="flex items-center gap-2 text-xl font-medium">
              {getIcon(property)}
              <span className="font-semibold">{title}</span>
              {renderToggle && (
                <span
                  className="font-semibold"
                  onClick={() => setToggled(!toggled)}
                >
                  {renderToggle(toggled)}
                </span>
              )}
            </span>
            {(!renderToggle || !toggled) && (
              <div className="flex gap-2">
                <Button
                  disabled={page === 0}
                  size="sm"
                  variant="outline"
                  onClick={() => {
                    if (page > 0) {
                      setPage(page - 1);
                    }
                  }}
                >
                  Prev
                </Button>
                <Button
                  disabled={page * PAGE_SIZE + PAGE_SIZE > cleanedUp?.length}
                  size="sm"
                  variant="outline"
                  onClick={() => {
                    if (page * PAGE_SIZE + PAGE_SIZE < cleanedUp?.length) {
                      setPage(page + 1);
                    }
                  }}
                >
                  Next
                </Button>
              </div>
            )}
          </div>
          {renderOptions && (
            <div className="mt-3 flex items-center justify-between">
              {renderOptions()}
            </div>
          )}
        </>
      )}
      onDelete={onDelete}
    >
      <div className="mt-2 flex h-full min-h-[150px] flex-col">
        {!cleanedUp?.length && <EmptyState headText="No Results Found" />}
        {renderToggle &&
          toggled &&
          renderToggledContent &&
          renderToggledContent(cleanedUp)}
        {(!renderToggle || !toggled) &&
          cleanedUp
            ?.slice(page * PAGE_SIZE, page * PAGE_SIZE + PAGE_SIZE)
            ?.map(({ property, count }) => {
              const filter = {
                eventName,
                field: searchField,
                value: property === '(None)' ? null : property,
                operator: '=',
              };
              const active = isActiveFilter(filter);
              return (
                <div key={property} className={`group flex items-center px-3`}>
                  {renderProperty ? (
                    <div className="relative mb-2 w-4/5 truncate py-1">
                      <div
                        className="absolute top-0 z-0 mr-3 h-full rounded bg-primary/30 align-middle"
                        style={{
                          width: max
                            ? `${(parseInt(count) * 100) / max}%`
                            : '0%',
                        }}
                      ></div>
                      {renderProperty(property)}
                    </div>
                  ) : (
                    <div className="relative mb-2 w-4/5 truncate py-1">
                      <div
                        className="absolute top-0 z-0 mr-3 size-full rounded bg-primary/30 align-middle"
                        style={{
                          width: max
                            ? `${(parseInt(count) * 100) / max}%`
                            : '0%',
                        }}
                      ></div>
                      <span className="relative z-10 pl-2 font-semibold ">
                        {property ? property : 'No Value'}
                      </span>
                    </div>
                  )}
                  <span className="w-1/5 text-right">{parseInt(count)}</span>
                  {active && (
                    <div className="mx-2 flex items-center gap-1 rounded bg-primary px-2 py-1 font-semibold text-white transition-all">
                      <Funnel /> <span>Active</span>
                    </div>
                  )}
                  {!active && (
                    <div
                      className="mx-2 hidden cursor-pointer items-center gap-1 rounded bg-primary px-2 py-1 font-semibold text-white transition-all hover:bg-primary/90 group-hover:flex"
                      onClick={() => addFilter(filter)}
                    >
                      <Funnel /> <span>Filter</span>
                    </div>
                  )}
                </div>
              );
            })}
      </div>
    </Widget>
  );
};
