import { axiosClient, isDemo } from './axios-client';
import { toast } from '@/components/ui/use-toast';
import { PROJECTS } from './demo-data';
import { Project, DomainWhitelist } from '@/types';

type CreateProjectProps = {
  name: string;
  silent?: boolean;
};

type UpdateProjectProps = {
  projectId: string;
  params: { domainWhitelist: DomainWhitelist };
};

export const ProjectsService = {
  getProject: async ({ projectId }: { projectId: string }) => {
    if (isDemo()) {
      return PROJECTS[0] as Project;
    }
    const {
      data: { data: project },
    } = await axiosClient.get<{ data: Project }>(`/projects/${projectId}`);
    return project;
  },

  getProjects: async () => {
    if (isDemo()) {
      return PROJECTS as Project[];
    }
    const {
      data: {
        data: { projects },
      },
    } = await axiosClient.get<{ data: { projects: Project[] } }>(`/projects`);

    return projects;
  },

  createProject: async ({ name, silent }: CreateProjectProps) => {
    try {
      const {
        data: { data },
      } = await axiosClient.post<{ data: Project }>(`/projects`, {
        name,
      });
      if (!silent) {
        toast({ title: 'Project Created' });
      }

      return data;
    } catch (err) {
      toast({
        title: 'We Encountered An Error',
        description: 'Please try again.',
        variant: 'destructive',
      });
      console.error(err);
    }
  },

  updateProject: async ({ projectId, params }: UpdateProjectProps) => {
    try {
      await axiosClient.put(`/projects/${projectId}`, params);
      toast({ title: 'Updated' });
    } catch (err) {
      toast({
        title: 'Could Not Update Project',
        description: 'Please try again.',
        variant: 'destructive',
      });
      console.error(err);
    }
  },

  deleteProject: async ({ projectId }: { projectId: string }) => {
    try {
      await axiosClient.delete(`/projects/${projectId}`);
      toast({ title: 'Project Deleted' });
    } catch (err) {
      toast({
        title: 'Could Not Delete Project',
        description: 'Please try again.',
        variant: 'destructive',
      });
      console.error(err);
    }
  },
};
