import { axiosClient } from './axios-client';
import { nanoid } from 'nanoid';
import { toast } from '@/components/ui/use-toast';
import { Query, FilterGroup } from '@/types';

type SaveQueriesProps = {
  projectId: string;
  queries: Query[];
};

type NewQueryProps = {
  name: string;
  eventName: string;
  lastXdays: number;
  fields: string[];
  filterGroups: FilterGroup[];
};

type UpdateQueryProps = {
  queries: Query[];
  query: Query;
};

type DeleteQueryProps = {
  queries: Query[];
  id: string;
};

export const QueriesService = {
  getQueries: async ({ projectId }: { projectId: string }) => {
    const resp = await axiosClient.get(`/queries/${projectId}`);
    return resp.data.data;
  },

  saveQueries: async ({ projectId, queries }: SaveQueriesProps) => {
    try {
      await axiosClient.put(`/queries/${projectId}`, { queries });
    } catch (err) {
      toast({
        title: 'Could Not Save Queries',
        description: 'Please try again.',
        variant: 'destructive',
      });
      console.error(err);
    }
  },

  createNewQuery: ({
    name,
    eventName,
    lastXdays,
    fields,
    filterGroups,
  }: NewQueryProps) => {
    return { id: nanoid(), name, eventName, lastXdays, fields, filterGroups };
  },

  updateQuery: ({ queries, query }: UpdateQueryProps) => {
    const index = queries.findIndex((val) => val.id === query.id);

    if (index !== -1) {
      queries[index] = query;
    }
  },

  deleteQuery: ({ queries, id }: DeleteQueryProps) => {
    const index = queries.findIndex((val) => val.id === id);

    if (index !== -1) {
      delete queries[index];
    }
  },
};
