// import React, { useState } from "react";
import { Button } from '@/components/ui/button';
import { useFieldArray } from 'react-hook-form';

export const FilterGroup = ({
  nestIndex,
  onRemove,
  control,
  watch,
  register,
}) => {
  const { fields, remove, append } = useFieldArray({
    control,
    name: `filterGroups[${nestIndex}].filters`,
  });
  return (
    <div className="flex w-full flex-col items-center">
      {nestIndex > 0 && (
        <span className="px-3 py-2 text-xs text-gray-400">OR</span>
      )}
      <div className="flex w-full flex-col gap-3 rounded-lg border border-primary p-3">
        {fields.map((filter, k) => (
          <Filter
            key={k}
            control={control}
            filter={filter}
            namePrefix={`filterGroups[${nestIndex}].filters[${k}]`}
            register={register}
            watch={watch}
            onRemove={() => remove(k)}
          />
        ))}
        <div className="flex gap-2">
          <Button type="button" onClick={() => append({})}>
            Add Filter
          </Button>
          <Button type="button" onClick={onRemove}>
            Delete Group
          </Button>
        </div>
      </div>
    </div>
  );
};
