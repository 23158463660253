import { useCallback } from 'react';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
  DropdownMenuSeparator,
} from '@/components/ui/dropdown-menu';
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  Form,
  FormControl,
  FormField,
  FormLabel,
  FormItem,
  FormMessage,
} from '@/components/ui/form';
import { Button } from '@/components/ui/button';
import { Input } from '@/components/ui/input';
import { CaretDown, CirclesFour, Plus } from '@phosphor-icons/react';
import { useProjects } from '@/hooks/use-projects';
import { Link } from '@tanstack/react-router';
import { Project } from '@/types';
import { useForm } from 'react-hook-form';
import { useQueryClient } from '@tanstack/react-query';
import { ProjectsService } from '@/api';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { toast } from '@/components/ui/use-toast';

const projectSchema = z.object({
  name: z
    .string({ required_error: 'Project name is required.' })
    .min(1, 'Project name is required.'),
});

export function ProjectDropdown({ type = 'text' }) {
  const { sortedProjects, projectName, projectId, setProjectId } =
    useProjects();

  if (!projectId) {
    return null;
  }

  const queryClient = useQueryClient();

  const form = useForm<z.infer<typeof projectSchema>>({
    resolver: zodResolver(projectSchema),
    defaultValues: {
      name: '',
    },
  });

  const onSubmit = useCallback(
    async (values: z.infer<typeof projectSchema>) => {
      try {
        const newProject = await ProjectsService.createProject({
          name: values?.name,
        });

        if (newProject) {
          const { projectId } = newProject;

          queryClient.invalidateQueries({
            queryKey: ['projects'],
          });
          setProjectId(projectId);
          form.reset();
        }
      } catch {
        toast({
          title: 'Could Not Add Project',
          description: 'Please try again.',
          variant: 'destructive',
        });
      }
    },
    [projectId],
  );

  return (
    <Dialog>
      <DropdownMenu>
        {type === 'button' ? (
          <DropdownMenuTrigger
            asChild
            className="flex items-center gap-1 [&[data-state=open]>svg]:rotate-180"
          >
            <Button
              iconRight={
                <CaretDown className="transition-transform duration-200" />
              }
              variant="outline"
            >
              {[projectName]}
            </Button>
          </DropdownMenuTrigger>
        ) : (
          <DropdownMenuTrigger className="flex items-center gap-1 hover:text-primary [&[data-state=open]>svg]:rotate-180">
            {projectName}
            {<CaretDown className="transition-transform duration-200" />}
          </DropdownMenuTrigger>
        )}
        <DropdownMenuContent align="start">
          <DropdownMenuLabel>Projects</DropdownMenuLabel>
          <DropdownMenuSeparator />
          {sortedProjects?.map((project: Project) => (
            <DropdownMenuItem key={project.projectId}>
              <div
                onClick={() => {
                  setProjectId(project.projectId);
                }}
              >
                {project.name}
              </div>
            </DropdownMenuItem>
          ))}
          <DropdownMenuItem key="view-all-projects">
            <Link to="/projects">
              <CirclesFour className="mr-2 inline-block" />
              View All Projects
            </Link>
          </DropdownMenuItem>
          <DropdownMenuItem key="create-project">
            <DialogTrigger asChild>
              <div>
                <Plus className="mr-2 inline-block" />
                Add New Project
              </div>
            </DialogTrigger>
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
      <DialogContent className="sm:max-w-[480px]">
        <DialogHeader>
          <DialogTitle>Add New Project</DialogTitle>
          <DialogDescription>
            Add your new project here. You typically want one project per domain
            or application.
          </DialogDescription>
        </DialogHeader>
        <Form {...form}>
          <form className="space-y-5" onSubmit={form.handleSubmit(onSubmit)}>
            <FormField
              control={form.control}
              name="name"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>Project Name</FormLabel>
                  <FormControl>
                    <Input id="name" {...field} placeholder="Enter a Name..." />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <DialogFooter>
              <DialogClose asChild>
                <Button type="submit">Add Project</Button>
              </DialogClose>
            </DialogFooter>
          </form>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
