import { axiosClient } from './axios-client';
import { FilterGroup } from '@/types';

type DauParams = {
  projectId: string;
  interval: string;
  startDate: Date;
  endDate: Date;
  filterGroups?: FilterGroup[];
  channelId?: string | null;
};

export const DailyActiveUsersService = {
  getDau: async ({
    projectId,
    interval,
    startDate,
    endDate,
    filterGroups,
    channelId,
  }: DauParams) => {
    const {
      data: { data },
    } = await axiosClient.post(`/analytics/dau`, {
      projectId,
      interval,
      startDate,
      endDate,
      filterGroups,
      channel: channelId,
    });

    return data;
  },
};
