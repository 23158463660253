import { Plan } from './plan';
import { Plans, PlanType } from '@/types';
import { SectionHeader } from './section-header';
import { PageHeader } from './page-header';
import { Button } from './ui/button';
import { Alert } from './ui/alert';
import { Warning } from '@phosphor-icons/react';

type BillingProps = {
  usage: number;
  // isOnTrial: boolean;
  usedEvents: number;
  hasActiveSubscription: boolean;
  hasSubscription: boolean;
  totalUsedEvents: number;
  isOnFreePlan: boolean;
  usageLimit: number;
  currentPlan: PlanType;
  subExpiresAt: Date | null;
  nextBillingAt: Date | null;
  email: string;
  hasExceededPlan: boolean;
};

const PLANS: Plans = {
  FREE: { name: 'Free', price: 0, limit: 10000 },
  BETA: { name: 'Beta', price: 0, limit: 100000 },
  BASIC: { name: 'Startup', price: 19, limit: 100000 },
  PRO: { name: 'Pro', price: 99, limit: 1000000 },
};

export const Billing = ({
  usage,
  // isOnTrial,
  usedEvents,
  hasActiveSubscription,
  hasSubscription,
  totalUsedEvents,
  isOnFreePlan,
  usageLimit,
  currentPlan,
  subExpiresAt,
  nextBillingAt,
  email,
  hasExceededPlan,
}: BillingProps) => {
  return (
    <div className="flex flex-col gap-12">
      <div>
        <PageHeader
          separator
          subTitle="Your usage for the current billing period"
          title="Usage"
        />
        <SectionHeader title="Sent Events" />
        <div className="mt-2 flex justify-between">
          <progress
            // className="progress progress-primary "
            max="100"
            value={usage}
          ></progress>
          <span>
            {usedEvents} of {usageLimit} (total: {totalUsedEvents})
          </span>
        </div>
        {currentPlan === 'BETA' && (
          <p>
            <span className="font-bold">
              Subscription Expires On:{' '}
              {(subExpiresAt
                ? new Date(subExpiresAt)
                : new Date()
              ).toLocaleDateString()}{' '}
            </span>
          </p>
        )}
        {hasSubscription && currentPlan !== 'BETA' && (
          <p>
            <span className="font-bold">Next Billing Date: </span>
            {(nextBillingAt
              ? new Date(nextBillingAt)
              : new Date()
            ).toLocaleDateString()}{' '}
          </p>
        )}
        {hasExceededPlan && (
          <Alert
            className="mt-5"
            icon={<Warning size={18} weight="fill" />}
            subTitle="You've exceeded your usage limit. Please upgrade."
            title="Usage Exceeded"
            variant="error"
          />
        )}
      </div>
      <div>
        <PageHeader subTitle="Details about your current plan" title="Plan" />
        <SectionHeader title="Current Plan" />
        <div className="mt-2">
          <Plan
            active={hasActiveSubscription || isOnFreePlan}
            displayName={isOnFreePlan ? 'Free' : PLANS[currentPlan].name}
            isCurrent={true}
            limit={usageLimit}
            name={currentPlan}
            price={PLANS[currentPlan].price}
          />
          {/* {!isOnFreePlan && (
            <div className="flex flex-col p-3">
              <p>What you get when you upgrade to a paid plan:</p>
              <span>- unlimited projects</span>
              <span>- viewing data without any time limits</span>
            </div>
          )} */}
        </div>
        {!isOnFreePlan && (
          <a href="https://billing.stripe.com/p/login/4gweXl8t0f8Cf4I4gg">
            <Button className="mt-5" size="lg">
              Manage Subscription
            </Button>
          </a>
        )}
        <div className="mt-6">
          <SectionHeader
            subTitle="Other plan options that you can choose"
            title="Available Plans"
          />
          <div className="mt-5 flex flex-col gap-3">
            {(['BASIC', 'PRO'] as PlanType[])
              .filter((p) => p !== currentPlan)
              .map((p, index) => (
                <Plan
                  key={index}
                  active={false}
                  displayName={PLANS[p].name}
                  isCurrent={false}
                  limit={PLANS[p].limit}
                  name={p}
                  price={PLANS[p].price}
                />
              ))}
          </div>
        </div>
      </div>
      <div>
        <PageHeader subTitle="Details about your account" title="Account" />
        <div className="space-y-2">
          <SectionHeader title="Account" />
          <p>
            <span className="font-bold">Email: </span>
            {email}
          </p>
        </div>
      </div>
    </div>
  );
};
