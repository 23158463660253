import { formatDistance } from 'date-fns';
import { Lightning } from '@phosphor-icons/react';
import { useEffect, useState } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import { EmojiAvatar } from '@/components/emoji-avatar';
import { getFlagEmoji } from '@/utils/get-flag-emoji';
import { User } from '@/types';
import { Link } from '@tanstack/react-router';

export const UserItem = ({ userId, country, lastActivity, count }: User) => {
  const [now, setNow] = useState(new Date());

  useEffect(() => {
    setInterval(() => setNow(new Date()), 30000);
  }, []);

  return (
    <Link to={`/users/${userId}`}>
      <div
        className={`flex cursor-pointer flex-col rounded border px-5 py-3 transition-shadow hover:border-primary hover:shadow-sm`}
      >
        <div className="flex flex-col justify-between gap-3 md:flex-row">
          <div className="flex items-center gap-2">
            <div className="relative mr-3">
              {<EmojiAvatar id={userId} />}
              {country && (
                <span className="absolute right-[-10px] top-[-10px] flex size-5 items-center justify-center rounded border bg-background shadow-xl">
                  <span>{getFlagEmoji(country)}</span>
                </span>
              )}
            </div>
            <span className="font-bold">{userId}</span>
          </div>
          <div className="flex flex-col items-start gap-3 md:flex-row md:items-center">
            <span className="flex items-center gap-1 rounded border border-border px-3 py-2 font-bold">
              <Lightning className="text-primary" /> <span>{count}</span>
            </span>
            <span className="text-gray-600">
              {formatDistance(new Date(lastActivity), now, {
                addSuffix: true,
              })}
            </span>
          </div>
        </div>
      </div>
    </Link>
  );
};
