import { axiosClient } from './axios-client';
import { GetAggregationByProperty, GetFunnels, GetRetention } from '@/types';

export const AnalyticsService = {
  getFunnels: async ({
    projectId,
    startDate,
    endDate,
    steps,
    channelId,
  }: GetFunnels) => {
    const {
      data: { data },
    } = await axiosClient.post(`/analytics/v2/funnels`, {
      projectId,
      startDate,
      endDate,
      steps,
      channel: channelId,
    });

    return data;
  },

  getAggregationByProperty: async ({
    projectId,
    eventName,
    metadataField,
    field,
    startDate,
    endDate,
    filterGroups,
    channelId,
  }: GetAggregationByProperty) => {
    const {
      data: { data },
    } = await axiosClient.post(`/analytics/aggregate-by-property`, {
      projectId,
      eventName,
      metadataField,
      field,
      startDate,
      endDate,
      filterGroups,
      channel: channelId,
    });

    return data;
  },

  getRetention: async ({
    projectId,
    startDate,
    endDate,
    filterGroups,
    channelId,
  }: GetRetention) => {
    const {
      data: { data },
    } = await axiosClient.post(`/analytics/retention`, {
      projectId,
      startDate,
      endDate,
      filterGroups,
      channel: channelId,
    });

    return data;
  },
};
