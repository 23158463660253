import {
  addDays,
  addMonths,
  endOfDay,
  endOfMonth,
  endOfWeek,
  startOfDay,
  startOfHour,
  startOfMonth,
  startOfWeek,
  subDays,
  subHours,
  subMonths,
  subYears,
} from 'date-fns';

export const ALL_TIME = 'All time';

const defineds = {
  startOfWeek: startOfWeek(new Date()),
  endOfWeek: endOfWeek(new Date()),
  startOfLastWeek: startOfWeek(addDays(new Date(), -7)),
  endOfLastWeek: endOfWeek(addDays(new Date(), -7)),
  startOfToday: startOfDay(new Date()),
  endOfToday: endOfDay(new Date()),
  startOfYesterday: startOfDay(addDays(new Date(), -1)),
  endOfYesterday: endOfDay(addDays(new Date(), -1)),
  startOfMonth: startOfMonth(new Date()),
  endOfMonth: endOfMonth(new Date()),
  startOfLastMonth: subYears(new Date(), 100),
  endOfLastMonth: endOfMonth(addMonths(new Date(), -1)),
};

export const ranges = [
  // {
  //   label: "Last hour",
  //   interval: "5 minutes",
  //   range: () => {
  //     const now = roundToNearestMinutes(new Date(), { nearestTo: 5 });
  //     return {
  //       startDate: subHours(now, 1),
  //       endDate: now,
  //     };
  //   },
  // },
  {
    label: 'Last 24 Hours',
    interval: '1 hour',
    period: { hours: 24 },
    isOnFree: true,
    range: () => {
      const now = startOfHour(new Date());
      return {
        startDate: startOfHour(subHours(now, 24)),
        endDate: now,
      };
    },
  },
  {
    label: 'Last 7 Days',
    interval: '1 days',
    period: { days: 7 },
    isOnFree: true,
    range: () => {
      const now = endOfDay(new Date());
      return {
        startDate: startOfDay(subDays(now, 7)),
        endDate: now,
      };
    },
  },
  {
    label: 'Last 30 Days',
    interval: '1 days',
    period: { days: 30 },
    range: () => {
      const now = endOfDay(new Date());
      return {
        startDate: startOfDay(subDays(now, 30)),
        endDate: now,
      };
    },
  },
  {
    label: 'Last 3 Months',
    interval: '1 days',
    period: { months: 3 },
    range: () => {
      const now = endOfDay(new Date());
      return {
        startDate: startOfDay(subMonths(now, 3)),
        endDate: now,
      };
    },
  },
  {
    label: 'Last 6 Months',
    interval: '1 days',
    period: { months: 6 },
    range: () => {
      const now = endOfDay(new Date());
      return {
        startDate: startOfDay(subMonths(now, 6)),
        endDate: now,
      };
    },
  },
  {
    label: 'Last 12 Months',
    interval: '1 days',
    period: { months: 12 },
    range: () => {
      const now = endOfDay(new Date());
      return {
        startDate: startOfDay(subMonths(now, 12)),
        endDate: now,
      };
    },
  },
  {
    label: ALL_TIME,
    interval: '1 days',
    period: { years: 2 },
    range: () => {
      const now = endOfDay(new Date());
      return {
        // Default to 30 days. Will be updated to createdAt date in the date picker
        startDate: startOfDay(subDays(now, 30)),
        endDate: now,
      };
    },
  },
  {
    label: 'Custom Range',
    interval: '1 days',
    range: () => ({
      startDate: defineds.startOfWeek,
      endDate: defineds.endOfWeek,
    }),
  },
];

// const staticRangeHandler = {
//   range: {},
//   isSelected(range) {
//     const definedRange = this.range();
//     return (
//       isSameDay(range.startDate, definedRange.startDate) &&
//       isSameDay(range.endDate, definedRange.endDate)
//     );
//   },
// };

// function roundToNearestMinutes(date, interval) {
//   var roundedMinutes = Math.floor(getMinutes(date) / interval) * interval;
//   return setMinutes(startOfMinute(date), roundedMinutes);
// }

// export function createStaticRanges(ranges) {
//   return ranges.map((range) => ({ ...staticRangeHandler, ...range }));
// }
