import {
  Area,
  ComposedChart,
  Line,
  ResponsiveContainer,
  Tooltip,
} from 'recharts';
import { CustomTooltip } from './custom-tooltip';
import { LineChartData } from '@/types';

type LineChartProps = {
  data: LineChartData[];
  renderTooltip?: (data: LineChartData) => JSX.Element;
  labelLeft: string;
  labelRight: string;
};

export const LineChart = ({
  data,
  renderTooltip,
  labelLeft,
  labelRight,
}: LineChartProps) => {
  if (!data) {
    return null;
  }
  const primaryColor = '#057BFA';
  return (
    <div className="flex w-full flex-col">
      <ResponsiveContainer aspect={3.5} className="pt-5">
        <ComposedChart
          data={data}
          margin={{
            right: 0,
            left: 0,
          }}
        >
          <Line
            dataKey="y"
            dot={false}
            legendType="none"
            stroke={primaryColor}
            strokeWidth={1.5}
            type="monotone"
          />
          <Area
            dataKey="y"
            fill={primaryColor}
            fillOpacity={0.08}
            stroke={primaryColor}
            strokeWidth={1.5}
            type="monotone"
          />
          <Tooltip content={<CustomTooltip renderTooltip={renderTooltip} />} />
        </ComposedChart>
      </ResponsiveContainer>
      <div className="flex items-center justify-between p-2 text-gray-400">
        <span>{labelLeft}</span>
        <span>{labelRight}</span>
      </div>
    </div>
  );
};
