import 'react-datepicker/dist/react-datepicker.css';
import { Button } from '@/components/ui/button';

type PaginationProps = {
  page: number;
  count: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
};

export const Pagination = ({ page, count, setPage }: PaginationProps) => {
  const hasPrevious = page > 1;
  const hasNext = count > page * 50;
  return (
    <div className="flex w-full items-center justify-between">
      <Button
        disabled={!hasPrevious}
        size="sm"
        variant="outline"
        onClick={() => {
          if (hasPrevious) {
            setPage((old: number) => old - 1);
          }
        }}
      >
        Previous Page
      </Button>{' '}
      <span className="rounded border border-border px-3 py-2 text-xs">
        Current Page: {page} / {Math.ceil(count / 50)}
      </span>
      <Button
        disabled={!hasNext} // Disable the Next Page button until we know a next page is available
        size="sm"
        variant="outline"
        onClick={() => {
          if (hasNext) {
            setPage((old: number) => old + 1);
          }
        }}
      >
        Next Page
      </Button>
    </div>
  );
};
