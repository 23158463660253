import { axiosClient } from './axios-client';

export const PaymentService = {
  getCheckoutUrl: async ({ plan }: { plan: string }) => {
    const {
      data: {
        data: { url },
      },
    } = await axiosClient.post(`/payments/checkout`, { plan });

    return url;
  },
};
