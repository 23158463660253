import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormLabel,
  FormItem,
  FormMessage,
  FormDescription,
} from '@/components/ui/form';
import { Textarea } from '@/components/ui/textarea';
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from '@/components/ui/select';
import { DialogClose, DialogFooter } from '@/components/ui/dialog';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { AutomationsService } from '@/api';
import { removeEmptyFields } from '@/utils/remove-empty-fields';
import { useQueryClient } from '@tanstack/react-query';

export const slackSchema = z.object({
  automationType: z.string({ required_error: 'Type is required' }),
  eventName: z
    .string({ required_error: 'Event name is required' })
    .min(1, 'Event name is required'),
  slackWebhook: z
    .string({ required_error: 'Webhook is required' })
    .min(1, 'Webhook is required'),
  slackMessage: z
    .string({ required_error: 'Message is required' })
    .min(1, 'Message is required'),
});

type SlackFormProps = {
  projectId: string;
  events: { name: string }[];
};

export const SlackForm = ({ projectId, events }: SlackFormProps) => {
  const queryClient = useQueryClient();

  const form = useForm<z.infer<typeof slackSchema>>({
    resolver: zodResolver(slackSchema),
    defaultValues: {
      automationType: 'slack',
      eventName: '',
      slackWebhook: '',
      slackMessage: '',
    },
  });

  const onSubmit = async (data: z.infer<typeof slackSchema>) => {
    removeEmptyFields(data);
    try {
      const { eventName, slackWebhook, slackMessage } = data;
      await AutomationsService.createSlackAutomation({
        projectId,
        eventName,
        slackWebhook,
        slackMessage,
      });
      queryClient.invalidateQueries({
        queryKey: ['automations', projectId],
      });
      form.reset();
    } catch (err) {
      console.error(err);
      form.reset();
    }
  };

  return (
    <Form {...form}>
      <form className="space-y-5" onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="eventName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Event Name</FormLabel>
              <Select defaultValue={field.value} onValueChange={field.onChange}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select an Event" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {events?.map((event: { name: string }) => (
                    <SelectItem key={event.name} value={event.name}>
                      {event.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormDescription>
                We will send a slack notification on each event.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="slackWebhook"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Slack Webhook</FormLabel>
              <FormControl>
                <Input
                  id="slackWebhook"
                  placeholder="https://hooks.slack.com/services/TREABCDEF/A02G25B12UX/KOLBOC34TAOIWWtk7Ko0eNN2"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="slackMessage"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Slack Message</FormLabel>
              <FormControl>
                <Textarea
                  id="slackMessage"
                  {...field}
                  className="w-full"
                  placeholder="User did X."
                  value={field.value}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <DialogFooter>
          <DialogClose asChild>
            <Button type="submit">Add</Button>
          </DialogClose>
        </DialogFooter>
      </form>
    </Form>
  );
};
