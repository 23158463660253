import * as React from 'react';
import { cn } from '@/utils/cn';
import { focusGlow } from '@/utils/focus';
import { Eye, EyeSlash } from '@phosphor-icons/react';

const Input = React.forwardRef<
  HTMLInputElement,
  React.InputHTMLAttributes<HTMLInputElement>
>(({ className, type, ...props }, ref) => {
  const isPassword = type === 'password';
  const [typeState, setTypeState] = React.useState(type);

  return (
    <div className="relative w-full">
      <input
        ref={ref}
        className={cn(
          // base
          'flex h-9 w-full rounded border border-input bg-transparent px-3 py-1 shadow-sm transition-colors relative',
          // file
          'file:border-0 file:bg-transparent file:text-base file:font-medium',
          // placeholder
          'placeholder:text-muted-foreground',
          // disabled
          'disabled:cursor-not-allowed disabled:opacity-50',
          // focus
          focusGlow,
          className,
          isPassword && 'pr-10',
        )}
        type={isPassword ? typeState : type}
        {...props}
      />
      {isPassword && (
        <div
          className={cn(
            'absolute bottom-0 right-0 flex h-full items-center justify-center px-3',
          )}
        >
          <button
            aria-label="Change password visibility"
            className={cn(
              // base
              'h-fit w-fit rounded-sm outline-none transition-all',
              // text
              'text-muted-foreground',
              // hover
              'hover:text-primary',
              focusGlow,
            )}
            type="button"
            onClick={() => {
              setTypeState(typeState === 'password' ? 'text' : 'password');
            }}
          >
            <span className="sr-only">
              {typeState === 'password' ? 'Show password' : 'Hide password'}
            </span>
            {typeState === 'password' ? (
              <Eye aria-hidden="true" className="size-5 shrink-0" />
            ) : (
              <EyeSlash aria-hidden="true" className="size-5 shrink-0" />
            )}
          </button>
        </div>
      )}
    </div>
  );
});
Input.displayName = 'Input';

export { Input };
