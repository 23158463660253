import { useProjects } from '@/hooks/use-projects';
import { SectionHeader } from './section-header';
import { TrackingOptions } from './tracking-options';

export const Integrations = () => {
  const { projectId, project } = useProjects();
  if (!projectId || !project) {
    return null;
  }

  return (
    <div className="mt-6 space-y-6">
      <SectionHeader
        subTitle="We provide multiple integration options to make it easy to send event data to Logspot. Just pick your favorite option(s) below."
        title="Integrations"
      />
      <TrackingOptions />
    </div>
  );
};
