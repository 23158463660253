import { X } from '@phosphor-icons/react';
import { useState } from 'react';
import { useFilters } from '@/hooks/use-filters';
import { Filter } from '@/types';
import { Button } from './ui/button';

type FiltersComponentProps = {
  filters: Filter[];
  removeFilter: (index: number) => void;
  removeAllFilters: () => void;
};

function getDisplayedUrl(url: string) {
  return url
    ? url
        .replace(/(^\w+:|^)\/\//, '')
        .replaceAll('www.', '')
        .replace(/\/+$/, '')
    : '(None)';
}

function capitalizeFirstLetter(string: string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

const renderName = (name: string) =>
  capitalizeFirstLetter(name.replace('_', ' '));

const FiltersComponent = ({
  filters,
  removeFilter,
  removeAllFilters,
}: FiltersComponentProps) => (
  <div className="flex flex-wrap items-center gap-2">
    {filters.map((f: Filter, index: number) => (
      <div
        key={index}
        className="flex cursor-pointer items-center gap-4 rounded border border-primary bg-primary/5 px-3 py-2 transition-all hover:bg-primary/10"
        onClick={() => removeFilter(index)}
      >
        <span className="flex flex-row items-center gap-2">
          <span className="rounded-md bg-primary p-1 px-2 text-xs font-semibold uppercase text-white">
            {f.field ? renderName(f.field) : 'Custom Field'}
          </span>
          <span className="rounded-md bg-primary/20 p-1 px-2 text-xs font-semibold uppercase text-primary">
            {f.operator}
          </span>
          {typeof f.value !== 'undefined' && f.value !== null && (
            <span className="truncate rounded-md bg-primary p-1 px-2 text-xs font-semibold text-white">
              {f.field === 'referrer' ? getDisplayedUrl(f.value) : f.value}
            </span>
          )}
        </span>
        <X />
      </div>
    ))}
    {filters.length > 1 && (
      <Button
        className="h-full"
        iconRight={<X />}
        onClick={() => removeAllFilters()}
      >
        Clear All
      </Button>
    )}
  </div>
);

export const Filters = () => {
  /* Only returning a single filter group right now, so we can destructure the first element */
  const { filters, removeFilter, removeAllFilters } = useFilters();
  const [showFilters, setShowFilters] = useState(false);

  return (
    <>
      <div className="flex flex-col gap-3 md:hidden">
        {!!filters.length && (
          <button
            className={`w-full rounded border py-2 font-semibold ${
              showFilters ? 'border-transparent' : 'border-primary bg-primary/5'
            }  `}
            onClick={() => setShowFilters(!showFilters)}
          >
            {showFilters ? 'Hide Filters' : `Active ${filters.length} Filters`}
          </button>
        )}
        {showFilters && (
          <FiltersComponent {...{ filters, removeFilter, removeAllFilters }} />
        )}
      </div>
      <div className="hidden md:flex">
        <FiltersComponent {...{ filters, removeFilter, removeAllFilters }} />
      </div>
    </>
  );
};
