import { capitalize } from './capitalize';

export function toTitleCase(s: string) {
  if (!s) {
    return '';
  }
  return (
    s
      // Insert space before uppercase letters in camelCase
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/[_-]/g, ' ')
      .split(' ')
      .map((word) => capitalize(word.toLowerCase()))
      .join(' ')
  );
}
