import { useMemo } from 'react';
import { createAvatar } from '@dicebear/core';
import { identicon } from '@dicebear/collection';

export const EmojiAvatar = ({ id }: { id: string }) => {
  const avatar = useMemo(() => {
    const currentAvatar = createAvatar(identicon, {
      seed: id,
      size: 40,
      radius: 8,
      backgroundColor: [
        'FFF38C',
        'F7ECDE',
        '54BAB9',
        'F65A83',
        'FFE898',
        'B2A4FF',
        'FFB4B4',
        '94B49F',
        'CEE5D0',
        'ECB390',
        '6E85B7',
        'D9F8C4',
        'F6C6EA',
        '354259',
        '00FFAB',
        'FFD24C',
        'F9F3EE',
        'B4FF9F',
        'FFEEEE',
        'FD5D5D',
        '42C2FF',
        'AD8B73',
        'CEAB93',
        'E3CAA5',
        'FFFBE9',
      ],
    });
    return currentAvatar.toDataUri();
  }, [id]);

  if (!avatar) {
    return null;
  }

  return <img alt={`avatar-${id}`} src={avatar} />;
};
