import { axiosClient } from './axios-client';
import { Widget } from '@/types';

type Dashboard = {
  name: string;
  visible: boolean;
  description: string;
  whitelist: string[];
  primaryColor: string;
  textColor: string;
};

type UpdateOpenDashboardProps = {
  projectId: string;
  dashboard: Dashboard;
};

type UpdateWidgetProps = {
  projectId: string;
  widgets: Widget[];
};

export const DashboardService = {
  updateOpenDashboard: async ({
    projectId,
    dashboard: {
      name,
      visible,
      description,
      whitelist,
      primaryColor,
      textColor,
    },
  }: UpdateOpenDashboardProps) => {
    await axiosClient.put(`/dashboards/${projectId}/open`, {
      name,
      visible,
      description,
      whitelist,
      primaryColor,
      textColor,
    });
  },

  updateWidgets: async ({ projectId, widgets }: UpdateWidgetProps) => {
    await axiosClient.put(`/dashboards/${projectId}/widgets`, {
      widgets,
    });
  },

  generateLink: async ({ projectId }: { projectId: string }) => {
    await axiosClient.post(`/dashboards/${projectId}/open/link`);
  },

  getDashboardConfig: async ({ projectId }: { projectId: string }) => {
    const {
      data: { data },
    } = await axiosClient.get(`/dashboards?projectId=${projectId}`);

    return data;
  },
};
