import { RocketLaunch, Empty } from '@phosphor-icons/react';
import { Link } from '@tanstack/react-router';
import { Button } from './ui/button';

export const EmptyState = ({
  headText,
  subText,
  launchState = false,
}: {
  headText: string;
  subText?: string;
  launchState?: boolean;
}) => {
  return (
    <div className="flex flex-col items-center justify-center space-y-2 p-4">
      <h2 className="text-center text-2xl font-bold">{headText}</h2>
      {subText && <p className="text-center">{subText}</p>}
      {launchState ? (
        <>
          <RocketLaunch className="size-12" />
          <Link to="/start-tracking">
            <Button>Start Tracking</Button>
          </Link>
        </>
      ) : (
        <Empty className="size-12 text-muted-foreground" />
      )}
    </div>
  );
};
