import { createFileRoute, Link } from '@tanstack/react-router';
import { LinkLogo } from '@/components/nav/link-logo';
import { TrackingOptions } from '@/components/tracking-options';
import { Button } from '@/components/ui/button';

export const Route = createFileRoute('/_auth/_basic-layout/start-tracking')({
  component: StartTracking,
});

function StartTracking() {
  return (
    <section className="py-12 sm:py-16 lg:py-20">
      <div className="mx-auto max-w-3xl px-4 sm:px-6 lg:px-8">
        <div className="mx-auto">
          <div className="flex flex-col items-center">
            <LinkLogo />
            <h1 className="mt-12 text-3xl font-bold text-gray-900">
              It&apos;s Time to Send Data
            </h1>
            <p className="mb-10 mt-4 font-medium text-gray-500">
              Use one of following integrations
            </p>
          </div>

          <TrackingOptions />

          <div className="mt-10 flex justify-center">
            <Link to="/">
              <Button type="submit">View Collected Events</Button>
            </Link>
          </div>

          <div className="mt-6 text-center">
            <p className="font-medium text-gray-900">
              Looking for Documentation?{' '}
              <a
                className="font-bold hover:underline"
                href="https://developers.logspot.io"
                rel="noreferrer"
                target="_blank"
                title=""
              >
                {' '}
                Read the Logspot Docs{' '}
              </a>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
