import logspot from '@logspot/web';
import { createFileRoute, Navigate } from '@tanstack/react-router';

export const Route = createFileRoute('/demo')({
  component: Demo,
});

function Demo() {
  localStorage.removeItem('ls-token');
  localStorage.removeItem('ls-project-id');
  localStorage.setItem('ls-demo', 'true');
  logspot.register({ demo: true });

  return <Navigate to="/" />;
}
