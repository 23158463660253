import { useCallback, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useForm } from 'react-hook-form';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { toast } from '@/components/ui/use-toast';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogClose,
} from '@/components/ui/dialog';
import { Button } from '@/components/ui/button';
import { useProjects } from '@/hooks/use-projects';
import { DashboardService } from '@/api';
import { Form } from '@/components/ui/form';
import { removeEmptyFields } from '@/utils/remove-empty-fields';
import { AggregationForm } from './aggregation-form';
import { FunnelForm } from './funnel-form';

const WIDGETS_LIST = [
  {
    type: 'AGGREGATION_BY_PROPERTY',
    label: 'Event by Property',
    description: 'See the breakdown of a specific property for key events.',
  },
  {
    type: 'FUNNEL',
    label: 'Multi-Step Funnel',
    description: 'Track user behavior through a multi-step process.',
  },
  {
    type: 'RETENTION',
    label: 'Retention Chart',
    description: 'View user retention over time.',
  },
];

const aggregationSchema = z.object({
  widgetType: z.string({ required_error: 'Please select a widget type.' }),
  title: z
    .string({
      required_error: 'Please provide a title for your widget.',
    })
    .min(2, 'Please provide a title for your widget.'),
  eventName: z
    .string({ required_error: 'Please select an event.' })
    .min(1, 'Please select an event.'),
  property: z
    .string({ required_error: 'Please select a property.' })
    .min(1, 'Please select a property.'),
});

const funnelSchema = z.object({
  widgetType: z.string({
    required_error: 'Please select a widget type.',
  }),
  title: z
    .string({
      required_error: 'Please provide a title for your widget.',
    })
    .min(2, 'Please provide a title for your widget.'),
  step1: z
    .string({ required_error: 'Please select at least two steps.' })
    .min(1, 'Please select at least two steps.'),
  step2: z
    .string({ required_error: 'Please select at least two steps.' })
    .min(1, 'Please select at least two steps.'),
  step3: z.string(),
});

const retentionSchema = z.object({
  widgetType: z.string({ required_error: 'Please select a widget type.' }),
});

type AddWidgetModalProps = {
  isOpen: boolean;
  onClose: () => void;
};

export const AddWidgetModal = ({ isOpen, onClose }: AddWidgetModalProps) => {
  const queryClient = useQueryClient();
  const { projectId } = useProjects();
  if (!projectId) {
    return null;
  }
  const [widgetType, setWidgetType] = useState<string | null>(null);

  const { data: dashboard, refetch } = useQuery({
    queryKey: [`dashboard-config`, projectId],
    queryFn: () => DashboardService.getDashboardConfig({ projectId }),
  });

  const aggregationForm = useForm<z.infer<typeof aggregationSchema>>({
    resolver: zodResolver(aggregationSchema),
    defaultValues: {
      widgetType: 'AGGREGATION_BY_PROPERTY',
      title: '',
      eventName: '',
      property: '',
    },
  });

  const onAggregationSubmit = useCallback(
    (values: z.infer<typeof aggregationSchema>) => {
      const widgets = dashboard?.widgets ?? [];
      const data = { ...values };
      removeEmptyFields(data);

      DashboardService.updateWidgets({
        projectId,
        widgets: [
          ...widgets,
          { type: 'AGGREGATION_BY_PROPERTY', params: data },
        ],
      })
        .then(() => {
          refetch();
          onClose();
          queryClient.invalidateQueries({
            queryKey: ['dashboard-config'],
          });
          aggregationForm.reset();
        })
        .catch(() =>
          toast({
            title: 'Could Not Add Aggregation Widget',
            description: 'Please try again.',
            variant: 'destructive',
          }),
        );
    },
    [dashboard, projectId],
  );

  const funnelForm = useForm<z.infer<typeof funnelSchema>>({
    resolver: zodResolver(funnelSchema),
    defaultValues: {
      widgetType: 'FUNNEL',
      title: '',
      step1: '',
      step2: '',
      step3: '',
    },
  });

  const onFunnelSubmit = useCallback(
    (values: z.infer<typeof funnelSchema>) => {
      const widgets = dashboard?.widgets ?? [];
      const data = { ...values };
      removeEmptyFields(data);

      DashboardService.updateWidgets({
        projectId,
        widgets: [...widgets, { type: 'FUNNEL', params: data }],
      })
        .then(() => {
          refetch();
          onClose();
          funnelForm.reset();
          queryClient.invalidateQueries({
            queryKey: ['dashboard-config'],
          });
        })
        .catch(() =>
          toast({
            title: 'Could Not Add Funnel Widget',
            description: 'Please try again.',
            variant: 'destructive',
          }),
        );
    },
    [dashboard, projectId],
  );

  const retentionForm = useForm<z.infer<typeof retentionSchema>>({
    resolver: zodResolver(retentionSchema),
    defaultValues: {
      widgetType: 'RETENTION',
    },
  });

  const onRetentionSubmit = useCallback(
    (values: z.infer<typeof retentionSchema>) => {
      const widgets = dashboard?.widgets ?? [];
      const data = { ...values };
      removeEmptyFields(data);

      DashboardService.updateWidgets({
        projectId,
        widgets: [...widgets, { type: 'RETENTION', params: data }],
      })
        .then(() => {
          refetch();
          onClose();
          queryClient.invalidateQueries({
            queryKey: ['dashboard-config'],
          });
          retentionForm.reset();
        })
        .catch(() =>
          toast({
            title: 'Could Not Add Retention Widget',
            description: 'Please try again.',
            variant: 'destructive',
          }),
        );
    },
    [dashboard, projectId],
  );

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add Widget</DialogTitle>
        </DialogHeader>
        <div className="space-y-5">
          {!widgetType && (
            <>
              <DialogDescription>
                Select the type of widget that you would like to add to your
                dashboard. Widgets can be added or removed at any time.
              </DialogDescription>
              <div className="grid w-full gap-5">
                {WIDGETS_LIST.map((w) => (
                  <button
                    key={w.type}
                    className="flex cursor-pointer flex-col gap-2 rounded border border-border p-3 shadow-sm hover:border-primary hover:text-primary"
                    onClick={() => setWidgetType(w.type)}
                  >
                    <span className="text-xl font-bold">{w.label}</span>
                    <p>{w.description}</p>
                  </button>
                ))}
              </div>
            </>
          )}
          {!!widgetType && widgetType === 'AGGREGATION_BY_PROPERTY' && (
            <Form {...aggregationForm}>
              <form
                className="space-y-5"
                onSubmit={aggregationForm.handleSubmit(onAggregationSubmit)}
              >
                <DialogDescription>
                  This widget shows the breakdown of a property for a given
                  event.
                </DialogDescription>
                <AggregationForm form={aggregationForm} />
                <DialogFooter>
                  <DialogClose asChild>
                    <Button
                      type="button"
                      variant="outline"
                      onClick={() => {
                        setWidgetType(null);
                        aggregationForm.reset();
                      }}
                    >
                      Back
                    </Button>
                  </DialogClose>
                  <DialogClose asChild>
                    <Button type="submit">Add</Button>
                  </DialogClose>
                </DialogFooter>
              </form>
            </Form>
          )}
          {widgetType === 'FUNNEL' && (
            <Form {...funnelForm}>
              <form
                className="space-y-5"
                onSubmit={funnelForm.handleSubmit(onFunnelSubmit)}
              >
                <DialogDescription>
                  This widget displays how your users move through a multi-step
                  process.
                </DialogDescription>
                <FunnelForm form={funnelForm} />
                <DialogFooter>
                  <DialogClose asChild>
                    <Button
                      type="button"
                      variant="outline"
                      onClick={() => {
                        setWidgetType(null);
                        funnelForm.reset();
                      }}
                    >
                      Back
                    </Button>
                  </DialogClose>
                  <DialogClose asChild>
                    <Button type="submit">Add</Button>
                  </DialogClose>
                </DialogFooter>
              </form>
            </Form>
          )}
          {widgetType === 'RETENTION' && (
            <Form {...retentionForm}>
              <form
                className="space-y-5"
                onSubmit={retentionForm.handleSubmit(onRetentionSubmit)}
              >
                <DialogDescription>
                  This widget displays user retention during the chosen period.
                </DialogDescription>
                <DialogFooter>
                  <DialogClose asChild>
                    <Button
                      type="button"
                      variant="outline"
                      onClick={() => {
                        setWidgetType(null);
                        retentionForm.reset();
                      }}
                    >
                      Back
                    </Button>
                  </DialogClose>
                  <DialogClose asChild>
                    <Button type="submit">Add</Button>
                  </DialogClose>
                </DialogFooter>
              </form>
            </Form>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
};
