import { useCallback, useEffect } from 'react';
// import { useMutation, useQuery } from "react-query";
import { useQueryClient } from '@tanstack/react-query';
import { useProjects } from '@/hooks/use-projects';
import { ProjectsService } from '@/api';
import { useForm } from 'react-hook-form';
import { Button } from '@/components/ui/button';
import { useNavigate } from '@tanstack/react-router';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import {
  Form,
  FormControl,
  FormField,
  FormLabel,
  FormItem,
  FormMessage,
  FormDescription,
} from '@/components/ui/form';
import { SectionHeader } from '@/components/section-header';
import { Input } from '@/components/ui/input';
import { Separator } from './ui/separator';

const generalSchema = z.object({
  domainWhitelist: z.union([z.string(), z.null()]).optional(),
});

export const General = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { projectId, project } = useProjects();
  if (!projectId || !project) {
    return null;
  }

  const form = useForm<z.infer<typeof generalSchema>>({
    resolver: zodResolver(generalSchema),
    defaultValues: {
      domainWhitelist: project?.domainWhitelist?.join(', ') ?? null,
    },
  });

  useEffect(() => {
    form.reset();
    if (project) {
      form.reset({
        domainWhitelist: project.domainWhitelist?.join(', ') ?? null,
      });
    }
  }, [projectId, project]);

  const updateProject = useCallback(
    (values: z.infer<typeof generalSchema>) => {
      let domainArray: string[] | null = null;
      if (values.domainWhitelist) {
        domainArray = values.domainWhitelist
          .split(',')
          .map((d) => d.trim())
          .filter((d) => d.length);
      }
      ProjectsService.updateProject({
        projectId,
        params: {
          domainWhitelist: domainArray,
        },
      }).then(() =>
        queryClient.invalidateQueries({
          queryKey: ['projects'],
        }),
      );
    },
    [projectId],
  );

  return (
    <div className="mt-6 space-y-6">
      <SectionHeader
        subTitle="You can limit which domains are allowed to send events to your project."
        title="Domains"
      />
      <Form {...form}>
        <form className="space-y-5" onSubmit={form.handleSubmit(updateProject)}>
          <FormField
            control={form.control}
            name="domainWhitelist"
            render={({ field }) => (
              <FormItem>
                <FormLabel>Domain Whitelist</FormLabel>
                <FormControl>
                  <Input
                    id="domainWhitelist"
                    {...field}
                    placeholder="Enter your domains"
                    value={field.value || ''}
                  />
                </FormControl>
                <FormDescription>
                  Separate domains with commas. You can use wildcards e.g.
                  *.example.com. If you leave it empty, we will allow all
                  domains.
                </FormDescription>
                <FormMessage />
              </FormItem>
            )}
          />
          <Button type="submit">Save</Button>
        </form>
      </Form>
      <Separator />
      <div className="w-full space-y-6 rounded border border-destructive bg-destructive/10 p-10 ">
        <SectionHeader
          className="text-destructive"
          subTitle="By deleting your project, you are deleting all events linked to that
          project."
          title="Danger"
        />
        <Button
          variant="destructive"
          onClick={async () => {
            const shouldDelete = window.confirm(
              `I want to delete ${project.name} project. Press OK to delete.`,
            );

            if (shouldDelete) {
              await ProjectsService.deleteProject({ projectId });
              navigate({ to: '/projects' });
            }
          }}
        >
          Delete Project
        </Button>
      </div>
    </div>
  );
};
