import { addDays, sub } from 'date-fns';
import { faker } from '@faker-js/faker';

export const ACCOUNT_DETAILS = {
  accountId: faker.string.uuid(),
  email: faker.internet.email().toLowerCase(),
  eventsCount: 51,
  notificationsCount: 51,
  limits: { monthlyEvents: 100000 },
  subscription: {
    active: true,
    plan: 'PRO',
    lastPayment: '2021-10-28T00:00:00.000Z',
    amount: 1,
  },
};

export const PROJECTS = [
  {
    projectId: faker.string.uuid(),
    shortId: 'supersecret',
    name: 'Logspot',
    ownerId: faker.string.uuid(),
    secretKey: 'superrandomsecret',
    createdAt: '2021-10-25T19:33:21.146Z',
    archived: false,
  },
];

export const EVENT_NAMES = [
  { name: 'ProjectCreated', slug: '' },
  { name: 'AutomationCreated', slug: '' },
  { name: 'EventSent', slug: '' },
  { name: 'SubscriptionPurchased', slug: '' },
  { name: 'AccountUpgraded', slug: '' },
];

function getDates(startDate: Date, stopDate: Date) {
  const dateArray = [];
  let currentDate = startDate;
  while (currentDate <= stopDate) {
    dateArray.push(new Date(currentDate));
    currentDate = addDays(currentDate, 1);
  }
  return dateArray;
}

export const EVENT_COUNTS = EVENT_NAMES.map(function ({ name }) {
  return getDates(sub(new Date(), { days: 90 }), new Date()).map(
    (date, index) => {
      return {
        count: faker.number.int({ min: 20 * index, max: 40 * index }),
        date,
        name,
        slug: name.toLowerCase(),
      };
    },
  );
}).flat();

export const EVENT_DETAILS = {
  id: faker.string.uuid(),
  name: 'AutomationCreated',
  message: null,
  metadata: {
    accountId: 123,
    isPaying: true,
    badgesCount: 213,
  },
  project: 'Logspot',
  createdAt: '2021-10-28T21:32:37.135Z',
};

export const EVENTS = [
  {
    id: faker.string.uuid(),
    name: 'ProjectCreated',
    slug: 'projectcreated',
    userId: faker.string.uuid(),
    message: null,
    project: 'Logspot',
    createdAt: '2021-10-30T06:23:33.672Z',
  },
  {
    id: faker.string.uuid(),
    name: 'ProjectCreated',
    slug: 'projectcreated',
    userId: faker.string.uuid(),
    message: null,
    project: 'Logspot',
    createdAt: '2021-10-28T21:32:37.135Z',
  },
  {
    id: faker.string.uuid(),
    name: 'ProjectCreated',
    slug: 'projectcreated',
    userId: faker.string.uuid(),
    message: null,
    project: 'Logspot',
    createdAt: '2021-10-28T20:40:43.823Z',
  },
  {
    id: faker.string.uuid(),
    name: 'ProjectCreated',
    slug: 'projectcreated',
    userId: faker.string.uuid(),
    message: null,
    project: 'Logspot',
    createdAt: '2021-10-28T19:40:53.803Z',
  },
];

export const AUTOMATIONS = [
  {
    automationId: faker.string.uuid(),
    eventSlug: 'accountregistered',
    metadata: {
      type: 'slack',
      slackMessage: 'New account registered',
      slackWebhook: 'https://hooks.slack.com/services/RANDOM/SLACK/HOOK',
    },
    projectId: faker.string.uuid(),
    type: 'slack',
  },
  {
    automationId: faker.string.uuid(),
    eventSlug: 'projectcreated',
    metadata: {
      type: 'slack',
      slackMessage: 'New projected created',
      slackWebhook: 'https://hooks.slack.com/services/RANDOM/SLACK/HOOK',
    },
    projectId: faker.string.uuid(),
    type: 'slack',
  },
  {
    automationId: faker.string.uuid(),
    eventSlug: 'automationcreated',
    metadata: {
      type: 'slack',
      slackMessage: 'New automation created',
      slackWebhook: 'https://hooks.slack.com/services/RANDOM/SLACK/HOOK',
    },
    projectId: faker.string.uuid(),
    type: 'slack',
  },
];

export const USERS = [
  {
    userId: faker.internet.email().toLowerCase(),
    count: faker.number.int({ min: 10, max: 10000 }),
    lastActivity: '2021-10-28T22:39:19.351Z',
  },
  {
    userId: faker.internet.email().toLowerCase(),
    count: faker.number.int({ min: 10, max: 10000 }),
    lastActivity: '2021-10-28T22:39:59.671Z',
  },
  {
    userId: faker.internet.email().toLowerCase(),
    count: faker.number.int({ min: 10, max: 10000 }),
    lastActivity: '2021-10-28T21:22:58.728Z',
  },
  {
    userId: faker.internet.email().toLowerCase(),
    count: faker.number.int({ min: 10, max: 10000 }),
    lastActivity: '2021-10-28T21:41:37.183Z',
  },
  {
    userId: faker.internet.email().toLowerCase(),
    count: faker.number.int({ min: 10, max: 10000 }),
    lastActivity: '2021-10-31T06:42:53.695Z',
  },
  {
    userId: faker.internet.email().toLowerCase(),
    count: faker.number.int({ min: 10, max: 10000 }),
    lastActivity: '2021-10-31T06:56:39.258Z',
  },
];
