import * as React from 'react';
import { Outlet, createRootRouteWithContext } from '@tanstack/react-router';
import { QueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import Logspot from '@logspot/web';
import { useEffect } from 'react';
import { SetAtom } from '@/types';
import { SetStateAction, useAtomValue } from 'jotai';
import { themeAtom } from '@/state/atoms';
import { Loader } from '@/components/ui/loader';
import { Toaster } from '@/components/ui/toaster';

type RouterContext = {
  isAuthenticated: () => boolean;
  queryClient: QueryClient;
  projectId: string | null;
  setProjectId: SetAtom<[SetStateAction<string | null>], void>;
  channelId: string | null;
};

Logspot.init({
  publicKey: 'pk_zNIoeGFrWZeNpRq56OKU8tJFvcN628LbN-DSnbBw',
  cookieDomain: '.logspot.io',
});

const TanStackRouterDevtools =
  process.env.NODE_ENV === 'production'
    ? () => null // Render nothing in production
    : React.lazy(() =>
        // Lazy load in development
        import('@tanstack/router-devtools').then((res) => ({
          default: res.TanStackRouterDevtools,
        })),
      );

export const Route = createRootRouteWithContext<RouterContext>()({
  component: RootComponent,
});

function RootComponent() {
  const theme = useAtomValue(themeAtom);

  useEffect(() => {
    const root = window.document.documentElement;

    if (theme === 'system') {
      const systemTheme = window.matchMedia('(prefers-color-scheme: dark)')
        .matches
        ? 'dark'
        : 'light';

      const prevTheme = systemTheme === 'dark' ? 'light' : 'dark';
      root.classList.remove(prevTheme);
      root.classList.add(systemTheme);
      return;
    }

    const prevTheme = theme === 'dark' ? 'light' : 'dark';
    root.classList.remove(prevTheme);
    root.classList.add(theme);
  }, [theme]);

  useEffect(() => {
    Logspot.track({
      event: 'App Opened',
    });
  }, []);

  useEffect(() => {
    // @ts-ignore
    window.$crisp = [];
    // @ts-ignore
    window.CRISP_WEBSITE_ID = 'cda388a8-26d8-4726-80c4-81623fb37e17';

    (function () {
      const d = document;
      const s = d.createElement('script');

      s.src = 'https://client.crisp.chat/l.js';
      // @ts-ignore
      s.async = 1;
      d.getElementsByTagName('head')[0].appendChild(s);
    })();
  }, []);

  return (
    <>
      <Outlet />
      <React.Suspense
        fallback={
          <div className="grid size-auto min-h-40">
            <Loader />
          </div>
        }
      >
        <ReactQueryDevtools buttonPosition="bottom-left" />
        <TanStackRouterDevtools position="bottom-right" />
      </React.Suspense>
      <Toaster />
    </>
  );
}
