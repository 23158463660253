import { differenceInDays, add, isAfter } from 'date-fns';
import { useSuspenseQuery } from '@tanstack/react-query';
import { PlanType } from '@/types';
import { accountDetailsOptions, projectsOptions } from '@/hooks/query-options';

type Subscription = {
  active: boolean;
  amount: number;
  expiresAt: Date;
  lastPayment?: Date;
  plan: 'BASIC' | 'PRO' | string;
};

type Account = {
  accountId: string;
  createdAt: string;
  email: string;
  eventsCount: number;
  limits: {
    monthlyEvents: number;
  };
  notificationsCount: number;
  subscription?: Subscription;
  totalEventsCount: number;
};

type HookData = {
  accountId: string;
  account: Account;
  isOnPaidPlan: boolean;
  isOnFreePlan: boolean;
  isOnTrial: boolean;
  hasActiveSubscription: boolean;
  hasExceededPlan: boolean;
  currentPlan: PlanType;
  trialExpiresInDays: number;
  trialExpiresAt: Date;
  subExpiresAt: Date | null;
  nextBillingAt: Date | null;
  usage: number;
  trialUsage: number;
  usedEvents: number;
  totalUsedEvents: number;
  usageLimit: number;
  hasSubscription: boolean;
  hasCreatedAnyProject: boolean;
  canHaveMoreProjects: boolean;
  createdAt: string;
};

export const useAccountDetails = (): HookData => {
  const { data } = useSuspenseQuery(accountDetailsOptions());
  const { data: projectsData } = useSuspenseQuery(projectsOptions());
  const isOnFreePlan = !data?.subscription || !data?.subscription?.active;
  const trialExpiresAt = add(new Date(data?.createdAt), { days: 15 });
  const hasTrialExpired = isAfter(new Date(), trialExpiresAt);
  const expiresInDays = hasTrialExpired
    ? 0
    : differenceInDays(trialExpiresAt, new Date());
  const subExpired = data?.subscription?.active
    ? isAfter(new Date(), new Date(data.subscription.expiresAt))
    : true;
  const hasActiveSubscription = !isOnFreePlan && !subExpired;
  const usageLimit = hasActiveSubscription ? data.limits.monthlyEvents : 10000;
  const usage = (data.eventsCount * 100) / usageLimit;
  const trialUsage = (data.totalEventsCount * 100) / usageLimit;

  const hookData: HookData = {
    accountId: data.accountId,
    account: data,
    isOnPaidPlan:
      ['BASIC', 'PRO'].includes(data?.subscription?.plan || 'FREE') || false,
    isOnFreePlan: !hasActiveSubscription,
    isOnTrial: isOnFreePlan,
    hasActiveSubscription,
    hasExceededPlan: usage > 110,
    currentPlan: isOnFreePlan
      ? 'FREE'
      : ((data?.subscription?.plan || 'FREE') as PlanType),
    trialExpiresInDays: expiresInDays,
    trialExpiresAt,
    subExpiresAt: data?.subscription?.expiresAt || null,
    nextBillingAt: data.subscription?.lastPayment
      ? add(new Date(data.subscription.lastPayment), { months: 1 })
      : null,
    usage,
    trialUsage,
    usedEvents: data?.eventsCount,
    totalUsedEvents: data?.totalEventsCount,
    usageLimit,
    hasSubscription: (data.subscription?.active && !subExpired) || false,
    hasCreatedAnyProject: (projectsData?.length || 0) > 0,
    canHaveMoreProjects: true,
    createdAt: data.createdAt,
  };

  return hookData;
};
