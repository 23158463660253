import React from 'react';
import { z } from 'zod';

const DomainWhitelist = z.union([z.array(z.string().url()), z.null()]);

export type DomainWhitelist = z.infer<typeof DomainWhitelist>;

export type EmailPass = {
  email: string;
  password: string;
};

export type Auth = {
  isAuthenticated: () => boolean;
  oauth: ({ projectId }: { projectId: string }) => Promise<string>;
  loginSocial: ({
    accessToken,
  }: {
    accessToken: string | null;
  }) => Promise<{ token: string; account: string }>;
  login: ({
    email,
    password,
  }: EmailPass) => Promise<{ token: string; accountId: string }>;
  logout: () => void;
  register: ({
    email,
    password,
  }: EmailPass) => Promise<{ token: string; accountId: string } | null>;
};

export type OpenShare = {
  id: string;
  name: string;
  visible: boolean;
  description: string;
  primaryColor: string;
  textColor: string;
  whitelist: string[];
};

export type DashboardConfig = {
  widgets: Widget[];
  open?: OpenShare;
};

export type Project = {
  archived: boolean;
  createdAt: string;
  dashboardConfig: DashboardConfig;
  domainWhitelist: null | DomainWhitelist;
  funnels: Record<string, unknown>;
  name: string;
  ownerId: string;
  projectId: string;
  publicKey: string;
  secretKey: string;
  shortId: string;
  webhookSecretKey: string;
};

export type Channel = {
  channelId: string | null;
  createdAt?: string;
  name: string;
  projectId?: string | null;
};

export type Field = {
  field: string;
  label: string;
  description: string;
  custom?: boolean;
};

export type Filter = {
  field?: string | null;
  fieldName?: string | null;
  eventName?: string | null;
  operator?: string | null;
  value?: string | null;
};

export type FilterGroup = {
  filters: Filter[];
};

export type FilterDialog = {
  search: string;
  searchValue: string;
  fieldsData: Field[];
  filteredItems: Field[];
  selectedField: Field | null;
  selectedOperator: string | null;
  selectedValue: string | null;
  currentTab: string;
};

export type Query = {
  id: string;
  name: string;
  filters: Filter[];
};

export type ReportConfig = {
  type: string;
  steps: ReportStep[];
};

export type User = {
  userId: string;
  count: number;
  lastActivity: string;
  country: string;
  firstEvent: string;
};

export const DateRangeSchema = z.object({
  interval: z.string(),
  startDate: z.date(),
  endDate: z.date(),
  key: z.string().optional(),
  label: z.string().optional(),
  period: z
    .object({
      hours: z.number().optional(),
      days: z.number().optional(),
      months: z.number().optional(),
    })
    .optional(),
});

export type DateRange = z.infer<typeof DateRangeSchema>;

export type EventData = {
  count: string | number;
  date?: string | Date | number;
  name?: string;
  property?: string;
  groupKey?: string;
  time?: string;
};

export type EventDataMap = {
  [name: string]: EventData[];
};

export type PlanType = 'FREE' | 'BETA' | 'BASIC' | 'PRO';

export type PlanDetails = {
  name: string;
  price: number;
  limit: number;
};

export type Plans = {
  [K in PlanType]: PlanDetails;
};

export type Step = {
  step: string;
  count: number;
  drop_off: number | null;
};

export type ReportStep = {
  id: string;
  type: 'Pageview' | 'Event';
  value?: string;
};

export type Theme = 'dark' | 'light' | 'system';

export const WidgetTypes = z.enum([
  'DAU',
  'EVENT',
  'AGGREGATION_BY_PROPERTY',
  'REFERRER',
  'COUNTRY',
  'CAMPAIGNS',
  'RETENTION',
  'FUNNEL',
]);

export type WidgetType = z.infer<typeof WidgetTypes>;

export type Widget = {
  type?: WidgetType;
  channel?: string | null;
  dateRange: DateRange;
  internal?: boolean;
  prevDateRange?: DateRange;
  params: {
    eventName?: string;
    property?: string;
    widgetType?: WidgetType;
    title: string;
  };
  onDelete?: () => Promise<unknown> | undefined | void;
};

export type AggregationWidget = Widget & {
  renderProperty?: (property: string) => JSX.Element;
  renderOptions?: () => JSX.Element;
  renderToggle?: (toggled: boolean) => JSX.Element;
  renderToggledContent?: (
    data: EventData[] | unknown[] | undefined,
  ) => JSX.Element;
  filterData?: (data: EventData[]) => EventData[];
};

export type EventWidget = Omit<Widget, 'params'> & {
  data: EventData[];
  dateRange: DateRange;
  hide?: boolean;
  name?: string;
  prevData?: EventData[];
  iconType?: 'view' | 'event';
  primaryColor?: string;
  secondaryColor?: string;
  backgroundColor?: string;
};

export type FunnelWidget = Widget & {
  params: {
    step1: Step;
    step2: Step;
    step3?: Step;
  };
};

export type LineChartData = {
  x: string | null;
  y: number;
  newUsers: number | null;
  retained: number | null;
};

export type GetUsers = {
  projectId: string;
  page?: number;
  channelId?: string | null;
};

export type UsersData = {
  count: number;
  users: User[];
};

export type GetFunnels = {
  projectId: string;
  startDate?: Date;
  endDate?: Date;
  steps?: ReportStep[];
  channelId?: string | null;
};

export type GetAggregationByProperty = {
  projectId: string;
  eventName?: string;
  metadataField?: string;
  field?: string;
  startDate?: Date;
  endDate?: Date;
  filterGroups: unknown;
  channelId?: string | null;
};

export type GetRetention = {
  projectId: string;
  startDate?: Date;
  endDate?: Date;
  days?: number;
  filterGroups?: unknown;
  channelId?: string | null;
};

export type SearchEvents = {
  projectId: string;
  cursor?: string;
  userId?: string | null;
  eventName?: string | null;
  startDate?: number;
  endDate?: number;
  filterGroups?: FilterGroup[];
  channelId?: string | null;
};

export type GetInfiniteEvents = {
  projectId: string;
  selectedEvent?: string | null;
  filterGroups?: FilterGroup[];
  channelId?: string | null;
  pageParam?: string | null;
};

export type Event = {
  projectId: string;
  channelId?: string | null;
};

export type EventKeyValues = {
  projectId: string;
  key: string | null;
  channelId?: string | null;
};

export type UserEvents = {
  projectId: string;
  userId: string;
};

export type AllEventCounts = {
  projectId: string;
  userId?: string | null;
  interval: string;
  startDate?: number;
  filterGroups?: unknown;
  endDate?: number;
  channelId?: string | null;
};

export type GetAllEventsCount = {
  projectId: string;
  dateRange: DateRange;
  filterGroups: FilterGroup[];
  channelId: string | null;
};

export type DayMetrics = {
  name: string;
  date: string;
  count: number;
};

export type RobotType = 'telegram' | 'slack' | 'webhook';

export type Automation = {
  automationId: string;
  eventName: string;
  eventSlug?: string | null;
  lastActivity: Date;
  metadata: {
    slackWebhook?: string;
    slackMessage?: string;
    webhookUrl?: string;
    webhookBody?: string;
    webhookHeaders?: string;
    triggeredOnClientSide?: boolean;
  };
  name: string | null;
  projectId: string;
  type: RobotType;
};

export type Robot = {
  Description: React.ElementType;
  id: number;
  robot: Automation;
  RobotDetails: React.ElementType;
  robotType: string;
};

export type SetAtom<Args extends unknown[], Result> = (...args: Args) => Result;
