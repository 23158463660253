import { Input } from '@/components/ui/input';
import { Button } from '@/components/ui/button';
import {
  Form,
  FormControl,
  FormField,
  FormLabel,
  FormItem,
  FormMessage,
  FormDescription,
} from '@/components/ui/form';
import { Textarea } from '@/components/ui/textarea';
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from '@/components/ui/select';
import { DialogClose, DialogFooter } from '@/components/ui/dialog';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { AutomationsService } from '@/api';
import { removeEmptyFields } from '@/utils/remove-empty-fields';
import { useQueryClient } from '@tanstack/react-query';

const webhookSchema = z.object({
  automationType: z.string({ required_error: 'Type is required' }),
  eventName: z
    .string({ required_error: 'Event name is required' })
    .min(1, 'Event name is required'),
  webhookUrl: z
    .string({ required_error: 'URL is required' })
    .min(1, 'URL is required'),
  webhookBody: z
    .string({ required_error: 'Body is required' })
    .min(1, 'Body is required'),
  webhookHeaders: z
    .string({ required_error: 'Headers are required' })
    .min(1, 'Headers are required'),
});

type WebhookFormProps = {
  projectId: string;
  events: { name: string }[];
};

export const WebhookForm = ({ projectId, events }: WebhookFormProps) => {
  const queryClient = useQueryClient();

  const form = useForm<z.infer<typeof webhookSchema>>({
    resolver: zodResolver(webhookSchema),
    defaultValues: {
      automationType: 'webhook',
      eventName: '',
      webhookUrl: '',
      webhookBody: '',
      webhookHeaders: '',
    },
  });

  const onSubmit = async (data: z.infer<typeof webhookSchema>) => {
    removeEmptyFields(data);
    try {
      const { eventName, webhookUrl, webhookBody, webhookHeaders } = data;
      await AutomationsService.createWebhookAutomation({
        eventName,
        projectId,
        webhookUrl,
        webhookBody,
        webhookHeaders,
      });
      queryClient.invalidateQueries({
        queryKey: ['automations', projectId],
      });
      form.reset();
    } catch (err) {
      console.error(err);
      form.reset();
    }
  };

  return (
    <Form {...form}>
      <form className="space-y-5" onSubmit={form.handleSubmit(onSubmit)}>
        <FormField
          control={form.control}
          name="eventName"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Event Name</FormLabel>
              <Select defaultValue={field.value} onValueChange={field.onChange}>
                <FormControl>
                  <SelectTrigger>
                    <SelectValue placeholder="Select an Event" />
                  </SelectTrigger>
                </FormControl>
                <SelectContent>
                  {events?.map((event: { name: string }) => (
                    <SelectItem key={event.name} value={event.name}>
                      {event.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <FormDescription>
                We will send a POST webhook notification on each event.
              </FormDescription>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="webhookUrl"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Webhook URL</FormLabel>
              <FormControl>
                <Input
                  id="webhookUrl"
                  placeholder="https://yourapi.com/notify"
                  {...field}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="webhookBody"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Webhook Body</FormLabel>
              <FormControl>
                <Textarea
                  id="webhookBody"
                  {...field}
                  placeholder={JSON.stringify({ userId: 'someUseId' })}
                  value={field.value}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <FormField
          control={form.control}
          name="webhookHeaders"
          render={({ field }) => (
            <FormItem>
              <FormLabel>Webhook Headers</FormLabel>
              <FormControl>
                <Textarea
                  id="webhookHeaders"
                  {...field}
                  placeholder={JSON.stringify({
                    'x-my-app-header': 'fckgwrhqq2',
                  })}
                  value={field.value}
                />
              </FormControl>
              <FormMessage />
            </FormItem>
          )}
        />
        <DialogFooter>
          <DialogClose asChild>
            <Button type="submit">Add</Button>
          </DialogClose>
        </DialogFooter>
      </form>
    </Form>
  );
};
