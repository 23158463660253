import { toast } from '@/components/ui/use-toast';
import { AUTOMATIONS } from './demo-data';

type SlackAutomationProps = {
  projectId: string;
  eventName: string;
  slackWebhook: string;
  slackMessage: string;
};

type WebhookAutomationProps = {
  projectId: string;
  eventName: string;
  webhookUrl: string;
  webhookBody: string;
  webhookHeaders: string;
};

type NotificationAutomationProps = {
  projectId: string;
  eventName?: string;
  triggerOnClientSide: boolean;
  silent?: boolean;
};

type TelegramAutomationProps = {
  projectId: string;
  eventName?: string;
  chatId: number;
  message?: string;
};

import { axiosClient, isDemo } from './axios-client';
// import { Automation } from '@/types';
export const AutomationsService = {
  getAutomations: async ({ projectId }: { projectId: string }) => {
    if (isDemo()) {
      return AUTOMATIONS;
    }
    const {
      data: { data: automations },
    } = await axiosClient.get(`/automations?projectId=${projectId}`);

    return automations;
  },

  deleteAutomation: async ({ automationId }: { automationId: string }) => {
    await axiosClient.delete(`/automations/${automationId}`);
  },

  createSlackAutomation: async ({
    projectId,
    eventName,
    slackWebhook,
    slackMessage,
  }: SlackAutomationProps) => {
    try {
      await axiosClient.post(`/automations/slack`, {
        projectId,
        eventName,
        slackWebhook,
        slackMessage,
      });
      toast({ title: 'Created' });
    } catch (err) {
      toast({
        title: 'We Encountered an Error',
        description: 'Please try again.',
      });
      console.error(err);
    }
  },

  createWebhookAutomation: async ({
    projectId,
    eventName,
    webhookUrl,
    webhookBody,
    webhookHeaders,
  }: WebhookAutomationProps) => {
    try {
      const bodyObj = webhookBody ? JSON.parse(webhookBody) : {};
      const headersObj = webhookHeaders ? JSON.parse(webhookHeaders) : {};
      const data = {
        eventName,
        projectId,
        webhookUrl: webhookUrl,
        webhookBody: JSON.stringify(bodyObj),
        webhookHeaders: JSON.stringify(headersObj),
      };
      await axiosClient.post(`/automations/webhook`, data);
      toast({ title: 'Webhook Robot Created' });
    } catch (err) {
      console.error(err);
      toast({
        title: 'Error Creating Webhook Robot',
        description: 'Please try again.',
      });
    }
  },

  createNotificationAutomation: async ({
    projectId,
    triggerOnClientSide,
    eventName,
    silent,
  }: NotificationAutomationProps) => {
    try {
      const data = {
        eventName,
        triggerOnClientSide: !!triggerOnClientSide,
        projectId,
      };
      await axiosClient.post(`/automations/notification`, data);
      if (!silent) {
        toast({ title: 'Mobile App Robot Created' });
      }
    } catch (err) {
      console.error(err);
      toast({
        title: 'Error Creating Mobile App Robot',
        description: 'Please try again.',
      });
    }
  },

  createTelegramAutomation: async ({
    projectId,
    eventName,
    chatId,
    message,
  }: TelegramAutomationProps) => {
    try {
      await axiosClient.post(`/automations/telegram`, {
        projectId,
        eventName,
        chatId,
        message,
      });
      toast({ title: 'Telegram Robot Created' });
    } catch (err) {
      toast({
        title: 'Error Creating Telegram Robot',
        description: 'Please try again.',
      });
      console.error(err);
    }
  },
};
